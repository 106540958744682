import React, { useState, useEffect } from 'react';
import { ListGroup, Form, Button, Row, Col } from 'react-bootstrap';

interface StatusFilterListProps {
  filters: any[]; // List of filters
  selectedTeam: string[]; // Selected filters from parent
  setFilters: React.Dispatch<React.SetStateAction<string[]>>; // Function to update selected filters in parent
  onClose: () => void; // Close the filter list
}

interface StatusFilterListItemProps {
  item: { uid: string; name: string };
  selectedTeam: Set<string>;
  toggleFilter: (uid: string) => void;
}

const StatusFilterListItem: React.FC<StatusFilterListItemProps> = ({
  item,
  selectedTeam,
  toggleFilter,
}) => {
  return (
    <ListGroup.Item onClick={() => toggleFilter(item.uid)} style={{ cursor: 'pointer' }}>
      <Form.Check
        checked={selectedTeam.has(item.uid)}
        label={item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name}
      />
    </ListGroup.Item>
  );
};

const TeamList: React.FC<StatusFilterListProps> = ({
  filters,
  selectedTeam,
  setFilters,
  onClose,
}) => {
  const [tempSelectedTeam, setTempSelectedTeam] = useState<Set<string>>(new Set(selectedTeam));
  const [filteredItems, setFilteredItems] = useState(filters);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setFilteredItems(
      filters.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, filters]);

  const toggleFilter = (uid: string) => {
    setTempSelectedTeam((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(uid)) {
        newSelected.delete(uid);
      } else {
        newSelected.add(uid);
      }
      return newSelected;
    });
  };

  const applyFilterHandler = () => {
    setFilters(Array.from(tempSelectedTeam)); // Commit the temporary selection to parent state
    onClose(); // Close the modal
  };

  const onCancelHandler = () => {
    onClose(); // Close the modal without updating the parent state
  };

  const clearSelectedMembers = () => {
    setTempSelectedTeam(new Set()); 
    setFilters([]);
    // Clear the temporary selection
  };

  return (
    <ListGroup variant="flush">
      <ListGroup.Item style={{ position: 'sticky', top: '0px', zIndex: 100000 }}>
        <Form.Control
          autoFocus
          className="w-auto mb-2"
          placeholder="Search Team"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          size="sm"
        />
      </ListGroup.Item>

      <Row style={{ position: 'sticky', zIndex: 100000, top: '50px',background:'white' }}>
        <Col>Selected: {tempSelectedTeam.size}</Col>
        <Col>
          {tempSelectedTeam.size > 0 && (
            <Button onClick={clearSelectedMembers} className="p-0 w-100 sendButton">
              Clear
            </Button>
          )}
        </Col>
      </Row>

      {filteredItems.map((item) => (
        <StatusFilterListItem
          key={item.uid}
          item={item}
          selectedTeam={tempSelectedTeam}
          toggleFilter={toggleFilter}
        />
      ))}

      <ListGroup.Item className="d-flex" style={{ position: 'sticky', bottom: '0px' }}>
        <Button size="sm" className="cancelButton w-50 p-1" onClick={onCancelHandler}>
          Cancel
        </Button>
        <Button size="sm" className="applyButton p-1 w-50 ml-2" onClick={applyFilterHandler}
        disabled={tempSelectedTeam?.size === 0}
        >
          Apply
        </Button>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default TeamList;
