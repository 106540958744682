import React, { useEffect, useMemo, useState } from 'react';
import { Form, Offcanvas, Button, FormControl } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import { BsFillQuestionCircleFill, BsTrash } from 'react-icons/bs';
import { MdAdd, MdTitle } from 'react-icons/md';
import * as yup from 'yup';

import { IEditorProps } from './types';
import { useElmentEditor } from './hooks';
import styles from './condition.module.scss';
import { uuidv4 } from '../../../utils/uuid';
import EditorCaption from './editorCaption';
import AddVariable from './addVariable';
import { setConditionIcon, setTagsIcon } from '../../../icons';
import ReactSelect from 'react-select';
import { getBotTags } from '../../../services/contacts';
import { useAppSelector } from '../../../hooks';
import CreatableSelect from 'react-select/creatable';

interface AndOrSwitchProps {
    isOr: boolean;
    onChange: (isOr: boolean) => void;
};

const AndOrSwitch: React.FC<AndOrSwitchProps> = (props) => {
    return (
        <div className={styles.andOrSwitch_Wrapper}>
            <div className={styles.andOrSwitch}>
                <div onClick={() => props.onChange(false)} className={`${styles.option} ${!props.isOr ? styles.selected : ""}`}>
                    AND
                </div>
                <div onClick={() => props.onChange(true)} className={`${styles.option} ${props.isOr ? styles.selected : ""}`}>
                    OR
                </div>
            </div>
        </div>
    )
}

interface TagOption {
    label: string;
    value: string;
}

interface FormData {
    conditionOperation: {
        ruleStatus: string,
        rules: {
            id: string;
            operator: string;
            value: string;
            variable: string;
        }[];
        successTargetId: string;
        failTargetId: string;
    }
};

interface IOption {
    id: string;
    operator: string;
    value: string;
    variable: string;
}

interface OptionsInputProps {
    options: IOption[];
    placeholder?: string;
    addLabel?: string;
    isInvalid: boolean;
    isValid: boolean;
    error: any; // Type: string | string[] | FormikErrors<IOption>[] | undefined
    touched: any;
    andOrSwitch: React.ReactNode;
}

const OptionsInput: React.FC<OptionsInputProps> = ({ options, addLabel, ...otherProps }) => {
    const formik = useFormikContext();
    const businessUid = useAppSelector((state) => state.meta.businessUid);
    const [tagOptions, setTagOptions] = useState<TagOption[]>([]);
    // const variables = useAppSelector((state) => state.graph.variables);
    const handleAddMatch = () => {
        const id = uuidv4();
        const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
        newOptions.push({ id, operator: 'equal', value: '', variable: "" });
        formik.setFieldValue('conditionOperation.rules', newOptions);
    };

    const getTags = async () => {
        if (businessUid) {
            const response = await getBotTags(businessUid);
            const options = response?.data?.tagsDto?.map((tag: any) => ({ label: tag.name, value: tag.name }));
            setTagOptions(options);
        }
    }

    useEffect(() => {
        getTags();
    }, [])

    const handleChange = (index: number, key: 'operator' | 'value' | 'variable', text: string) => {
        const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
        newOptions[index] = { ...newOptions[index], [key]: text?.trim() };
        formik.setFieldValue('conditionOperation.rules', newOptions);
        if (key === "operator" && (text === "equal" || text === "notEqual") &&
            (formik.values as any)?.conditionOperation?.rules[index]?.variable === "{{contact.tags}}"
            && ((formik.values as any)?.conditionOperation?.rules[index]?.operator === "contains" || (formik.values as any)?.conditionOperation?.rules[index]?.operator === "notContain")) {
            formik.setFieldValue(`conditionOperation.rules[${index}].value`, "");
        }
    };

    const handleDelete = (index: number) => {
        const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
        newOptions.splice(index, 1);
        formik.setFieldValue('conditionOperation.rules', newOptions);
    };

    const varTypeHashmap = useMemo(() => {
        const hashMap: { [key: string]: string } = {};
        tagOptions.forEach((item: any) => {
            hashMap[item.label] = "STRING";
        });
        return hashMap;
    }, [tagOptions])

    useEffect(() => {
        if (options[0]?.variable && tagOptions?.length > 0) {
            const tagValues = options
                .filter((option: any) => option?.variable === "{{contact.tags}}")
                .map((option: any) => ({ label: option?.value, value: option?.value }));
            const uniqueTagValues = tagValues.filter(
                (newOption) => !tagOptions.some((existingOption) => existingOption.value === newOption.value)
            );
            setTagOptions((prevTagOptions) => [...prevTagOptions, ...uniqueTagValues]);
        }
    }, [options, tagOptions?.length]); 
    

    return (
        <>
            {options.map((rule, index) => {
                const isTouched = otherProps.touched;
                const getError = (key: string) => {
                    return (otherProps.error && otherProps.error[index] && otherProps.error[index][key]) ? otherProps.error[index][key] : null;
                }

                const operators = [
                    { label: 'Equal', value: 'equal' },
                    { label: 'Not Equal', value: 'notEqual' },
                    { label: 'Empty', value: 'isEmpty' },
                    { label: 'Not Empty', value: 'isNotEmpty' },
                    { label: 'Contains', value: 'contains' },
                    { label: 'Does Not Contains', value: 'notContain' }
                ];

                return (
                    <div key={rule.id}>
                        <div className={styles.ruleContainer}>
                            <div className={styles.conditionHeader}>
                                <span className={styles.header}></span>
                                {options.length > 1 && (
                                    <Button
                                        disabled={index === 0 && options.length <= 1}
                                        onClick={() => handleDelete(index)}
                                        size='sm'
                                        className='deleteSmallButton'
                                    >
                                        <BsTrash />
                                    </Button>
                                )}
                            </div>
                            <div>
                                <div className={`${styles.variableNameInput} ${isTouched && getError("variable") ? styles.invalid : ""} ${isTouched && !getError("variable") ? styles.valid : ""}`}>
                                    <div className={styles.inputIcon}>
                                        <BsFillQuestionCircleFill style={{ color: 'rgba(0, 126, 195, 1)' }} />
                                        <span className={styles.label}>IF</span>
                                    </div>
                                    <div className={styles.addVariableWrapper}>
                                        <FormControl
                                            name={`conditionOperation.rules[${index}].variable`}
                                            value={rule.variable}
                                            placeholder='Enter Variable'
                                            onChange={(e: any) => handleChange(index, 'variable', e?.target?.value)}
                                        />
                                    </div>
                                    {/* <Form.Select
                                    name={`conditionOperation.rules[${index}].variable`}
                                    value={rule.variable}
                                    isInvalid={isTouched && getError("variable")}
                                    isValid={isTouched && !getError("variable")}
                                    className={styles.variableInput}
                                    onChange={(e: any) => handleChange(index, 'variable', e.target.value)}
                                >
                                    {variables.map(variable => {
                                        return (
                                            <option key={variable.name} value={`{{variable.${variable.name}}}`}>{variable.name}</option>
                                        )
                                    })}
                                    <option value='' disabled>Select variable</option>
                                </Form.Select> */}
                                </div>
                                {isTouched && getError("variable") ? (
                                    <div className='invalid-feedback mb-2' style={{ display: 'block' }}>
                                        {getError("variable")}
                                    </div>
                                ) : null}
                                <AddVariable static product fieldName={`conditionOperation.rules[${index}].variable`} fieldValue={rule.variable} handleChange={(value: string) => handleChange(index, 'variable', value)} />
                                <Form.Select
                                    name={`conditionOperation.rules[${index}].operator`}
                                    value={rule.operator}
                                    isInvalid={false}
                                    isValid={isTouched}
                                    className={'mb-2'}
                                    onChange={(e: any) => handleChange(index, 'operator', e.target.value)}
                                >
                                    {operators.map(operator => {
                                        return (
                                            <option key={operator.value} value={operator.value}>{operator.label}</option>
                                        )
                                    })}
                                </Form.Select>
                                {rule.operator !== "isEmpty" && rule.operator !== "isNotEmpty" && (
                                    <>
                                        {(rule.variable === "{{contact.tags}}" && (rule.operator === "contains" || rule.operator === "notContain")) ? (
                                            <>
                                                {/* <ReactSelect
                                                    name={`conditionOperation.rules[${index}].value`}
                                                    value={(formik?.values as any)?.conditionOperation?.rules[index]?.value && 
                                                        tagOptions?.find((tag: any) => tag.value === (formik?.values as any)?.conditionOperation.rules[index].value) || null}
                                                    className={'mb-2'}
                                                    placeholder="Select Tags"
                                                    onChange={(option: any) => handleChange(index, 'value', option?.value)}
                                                    options={tagOptions}
                                                /> */}
                                                <CreatableSelect
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 1100 }),
                                                        menu: base => ({ ...base, width: 370, zIndex: 100 })
                                                    }}
                                                    placeholder="Create or Select Tags"
                                                    value={(formik?.values as any)?.conditionOperation?.rules[index]?.value &&
                                                        tagOptions?.find((tag: any) => tag.value === (formik?.values as any)?.conditionOperation.rules[index].value) || null}
                                                    onChange={(option: any) => handleChange(index, 'value', option?.value)}
                                                    formatOptionLabel={({ value }: any) => (
                                                        <>
                                                            {varTypeHashmap[value] === "STRING" ? <MdTitle /> : '+ Create'}
                                                            <span className="ml-2">{value}</span>
                                                        </>
                                                    )}
                                                    options={tagOptions}
                                                    onCreateOption={(value) => {
                                                        const newOption: { label: string, value: string } = { label: value, value: value };
                                                        const updatedTagOptions = [...tagOptions, newOption]
                                                        setTagOptions(updatedTagOptions);
                                                        handleChange(index, 'value', value);
                                                    }}
                                                />
                                                <AddVariable static product fieldName={`conditionOperation.rules[${index}].value`}
                                                    fieldValue=""
                                                    handleChange={(value: string) => {
                                                        const newOption: { label: string, value: string } = { label: value, value: value };
                                                        const updatedTagOptions = [...tagOptions, newOption];
                                                        setTagOptions(updatedTagOptions);
                                                        handleChange(index, 'value', value);
                                                    }} />
                                            </>
                                        ) : <>
                                            <Form.Control
                                                as='input'
                                                name={`conditionOperation.rules[${index}].value`}
                                                placeholder='Value'
                                                value={rule.value}
                                                isValid={isTouched && !getError("value")}
                                                onChange={(e: any) => handleChange(index, 'value', e.target.value)}
                                            />
                                            <AddVariable static product fieldName={`conditionOperation.rules[${index}].value`} fieldValue={rule.value} handleChange={(value: string) => handleChange(index, 'value', value)} />

                                        </>}</>)}
                            </div>
                            {isTouched && getError("value") ? (
                                <div className='invalid-feedback' style={{ display: 'block' }}>
                                    {getError("value")}
                                </div>
                            ) : null}
                        </div>
                        {options.length - 1 !== index ? otherProps.andOrSwitch : null}
                    </div>
                );
            })}
            <div>
                <Button onClick={handleAddMatch} size='sm' className='addButton'><MdAdd /></Button>
            </div>
            {(otherProps.touched && otherProps.error && typeof otherProps.error === 'string') ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {otherProps.error}
                </div>
            ) : null}
        </>
    );
};

const ConditionEditor: React.FC<IEditorProps> = props => {
    const [formData, setFormData] = useState<FormData>({
        conditionOperation: {
            ruleStatus: '1',
            rules: [{ id: uuidv4(), operator: "equal", value: "", variable: "" }],
            successTargetId: uuidv4(),
            failTargetId: uuidv4()
        }
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'condition',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const ruleSchema = yup.object().shape({
        operator: yup.string().required('Operator is required'),
        value: yup.string().when('operator', {
            is: (operator: string) => operator !== 'isEmpty' && operator !== 'isNotEmpty',
            then: yup.string().required('Value is required'),
            otherwise: yup.string().nullable()
        }),
        variable: yup.string().required('Variable is required'),
    });
    const schema = yup.object().shape({
        conditionOperation: yup.object().shape({
            ruleStatus: yup.number(),
            rules: yup.array().of(ruleSchema).min(1, "Atleast one rule is required"),
            successTargetId: yup.string(),
            failTargetId: yup.string(),
        }),
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, setFieldValue, values, touched, errors, setValues }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Set a condition' icon={<img src={setConditionIcon} />} />
                        <Offcanvas.Body>
                            <Form.Group className="mb-3">
                                <OptionsInput
                                    andOrSwitch={(
                                        <Form.Group className='mb-3'>
                                            {/* <Form.Check
                                                inline
                                                label='AND'
                                                name='conditionOperation.ruleStatus'
                                                type='radio'
                                                value={1}
                                                onChange={handleChange}
                                                checked={values.conditionOperation.ruleStatus === '1'}
                                            />
                                            <Form.Check
                                                inline
                                                label='OR'
                                                name='conditionOperation.ruleStatus'
                                                type='radio'
                                                value={0}
                                                onChange={handleChange}
                                                checked={values.conditionOperation.ruleStatus === '0'}
                                            /> */}
                                            <AndOrSwitch
                                                isOr={values.conditionOperation.ruleStatus === "0"}
                                                onChange={(isOr) => {
                                                    setFieldValue("conditionOperation.ruleStatus", isOr ? "0" : "1");
                                                }}
                                            />
                                        </Form.Group>
                                    )}
                                    options={values.conditionOperation.rules}
                                    error={errors.conditionOperation?.rules}
                                    isInvalid={(touched.conditionOperation?.rules && errors.conditionOperation?.rules) ? true : false}
                                    isValid={(touched.conditionOperation?.rules && !errors.conditionOperation?.rules) ? true : false}
                                    touched={touched.conditionOperation?.rules ? true : false}
                                />
                            </Form.Group>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' disabled={props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default ConditionEditor;