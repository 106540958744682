import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import {
  Field,
  ErrorMessage,
  FieldProps,
  FormikProps,
} from "formik";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import "./template.scss";
import {
  FormGroup,
  Label,
  Button,
  Input,
  Alert,
} from "reactstrap";
import { metaSync, metaGetAll, metaProduct } from "../../services/catalogueService";

import FlagInput from "../../common/flagInput";
import Select, { components } from "react-select";
import { catalogStyles, customStyles } from "../../common/react-select-style";
import { sliceText } from "../../common/sliceText";
import TooltipReference from "../../common/tooltip";
import customData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPlusCircle,
  faTimesCircle, faTrash, faEdit,
  faMinusCircle,
  faIndianRupeeSign,
  faAdd,
  faExternalLinkAlt
} from "@fortawesome/free-solid-svg-icons";
import { faFaceSmile } from "@fortawesome/free-regular-svg-icons";
import { useSelector } from "react-redux";
import { buttonTypes } from '../../common/category';
import Modall from "../../common/modall";
import Carousel from './CarouselModal';
import moment from "moment";
import { FacebookLogin } from "../../common/FBCatalogueBtn";
import { sweetalert } from "../../common/alert";
import phone from "../../assets/img/phonesymbol.png";
import { RxDragHandleDots2 } from "react-icons/rx";
interface TemplateBodyProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  values: any;
  type: string;
  data: any;
  restrictText: (
    event: any,
    setFieldValue: (field: string, value: any) => void,
    resTill: number
  ) => void;
  setcode: any;
  countrycode: any;
  handleCarousel: (media: any, values: any, variable: any, countryCode: any) => void;
}

interface SelectBtnOption {
  value: string;
  label: string;
}

interface ProductItem {
  product_retailer_id: string;
}

interface Section {
  title: string;
  product_items: ProductItem[];
}
interface SelectCatalogBtnOption {
  value: string,
  label: string,
  imageUrl: string,
  id: string,
  price: string
}

interface Product {
  id: string;
  name: string;
  retailerId: string;
  condition: string;
  description: string;
  brand: string;
  price: string;
  inventory: number;
  availability: string;
  imageUrl: string;
  url: string;
  productSets: {
    data: {
      name: string;
      id: string;
    }[];
    paging: {
      next: string | null;
      previous: string | null;
      cursors: {
        before: string;
        after: string;
      };
    };
  };
  createdAt: string;
  updatedAt: string;
  catalogId: string;
  channelId: string | null;
}


const webUrlOptions: SelectBtnOption[] = [
  { value: "staticUrl", label: "Static URL" },
  { value: "dynamicUrl", label: "Dynamic URL" },
];
const webUrlOptions1: SelectBtnOption[] = [
  { value: "staticUrl", label: "Static URL" },
  { value: "dynamicUrl", label: "Dynamic URL" },
];

interface ButtonInfo {
  label: string;
  name: string;
  id: string;
  value: string;
  count: number;
}
interface CatalogInfo {
  id: string,
  name: string,
  vertical: string,
  status: string,
  productCount: number,
  createdAt: string,
  updatedAt: string,
  channelId: string
}

interface ProductItem {
  product_retailer_id: string;

}
interface Item {
  id: number;
  index: number;
  card: any;
  variable: any;
  countryCode: any;
}
interface Section {
  title: string;
  product_items: ProductItem[];
}
interface CatalogOption {
  value: string;
  label: string;
  imageUrl: string;
  price: string;
}
const TemplateBody: React.ForwardRefRenderFunction<
  unknown,
  TemplateBodyProps
> = (
  {
    setFieldValue,
    values,
    handleChange,
    type,
    data,
    restrictText,
    setcode,
    countrycode,
    handleCarousel,
  },
  ref
) => {
    // const [code, setCode] = useState<string>('91');
    const [selectedOptions, setSelectedOptions] = useState<CatalogOption[]>([]);


    const filteredsectionsData: string[] = values.sections.flatMap((section: Section) =>
      section.product_items.map((item: ProductItem) => item.product_retailer_id)
    );
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [mouseSelectedText, setMouseSelectedText] = useState("");
    const [counter, setCounter] = useState(1);
    const [catalogOptions, setCatalogOptions] = useState<SelectCatalogBtnOption[]>([]);



    const filteredCatalogOptions = catalogOptions?.filter((options: any) => {
      return !filteredsectionsData?.includes(options?.value)
    })



    const [popovertarget, setpopovertarget] = useState<null | HTMLButtonElement>(
      null
    );
    const [product, setProduct] = useState<Product[]>([]);
    const buttonsOptions: SelectBtnOption[] = [
      { value: "action", label: "Call to Action" },
      { value: "quickReply", label: "Quick Reply" },
    ];
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [catalogInfo, setCatalogInfo] = useState<CatalogInfo[]>([]);
    const [media, setMedia] = useState<any>([]);
    const [temValues, setValues] = useState<any>([]);
    const [variableArr, setVariable] = useState<any>([]);
    const [countryArr, setcountryArr] = useState<any>([]);
    const [limitCount, setLimitCount] = useState(0);
    const [codeCount, setCodeCount] = useState(0);
    const [UrlCount, setLimitUrl] = useState(0);
    const [sampleCount, setLimitSample] = useState(0);
    const [limitBtnCount, setLimitButton] = useState(0);

    const channel: any = useSelector(
      (state: any) => state.cartreducer.channelUid
    );
    const getAllData = async () => {
      try {
        const response = await metaGetAll(channel?.value);
        const catalogProduct = response?.data
        //  console.log("getall",catalogProduct);

        setCatalogInfo(catalogProduct);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    const fetchData = useCallback(
      async (
        pageIndex: number,
        pageSize: number,
        searchText: string,
        currentPage: number
      ): Promise<{ data: any; totalPages: number }> => {
        const listPayload = {
          channelId: channel?.value,
          page: 0,
          limit: 20,
          search: "",
        };

        const response = await metaProduct(listPayload);
        // const response = await ContactsGetAll(listPayload as any);
        // const response = await  metaGetAll(channel?.value);
        if (response) {
          const { catalogueProduct, count } = response.data;

          setProduct(catalogueProduct)
          const CatlogueImageURl =
            response.data.catalogueProduct[
              response?.data?.catalogueProduct?.length - 1
            ]?.imageUrl;
          setFieldValue("catalogImageUrl", response?.data?.catalogueProduct[response?.data?.catalogueProduct?.length - 1].imageUrl
          );
          console.log(response.data);

          const options: SelectCatalogBtnOption[] = catalogueProduct.map((item: any) => ({
            value: item.retailerId,
            label: item.name,
            price: item.price,
            imageUrl: item.imageUrl,
            id: item.id
          }));
          setCatalogOptions(options);
          console.log("options", options);


          // setProduct(catalogueProduct);
          return { data: catalogueProduct, totalPages: 100 };
        } else {
          return { data: [], totalPages: 0 };
        }
      },
      [channel?.value]
    );

    const [button, setBtnOpen] = useState(true);


    const [selectedOption, setSelectedOption] = useState('catalogue');
    const radioOptions = [
      { value: 'catalogue', label: 'Catalogue Message', description: "Show all products for a complete view" },
      { value: 'multi-product', label: 'Multi Product Message', description: "Suitable for featuring new collections or certain categories" },
    ];

    const [footer, setFooterOpen] = useState(true);
    const [thumbnail, setThumbnailOpen] = useState<boolean>(true);
    const pickerRef = useRef<HTMLDivElement>(null);
    const [characterCount, setCharacterCount] = useState(0);
    const [charphone, setCharphone] = useState(0);
    const [charcoupon, setCharcoupon] = useState(0);
    const [catalogheader, setCatalogheader] = useState(0);
    const [charnum, setCountnum] = useState(0);
    const [urlCount, setUrlCount] = useState(0);
    const [urlCount1, setUrlCount1] = useState(0);

    const [weburlCount, setwebCount] = useState(0);
    const [weburlCount1, setwebCount1] = useState(0);

    const [varCount, setVarCount] = useState(0);
    const [characterCountVar, setCharacterVar] = useState(0);
    const [edit, setEdit] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<Item | null | any>(null);
    const footerCount = characterCountVar;
    const [exampleTxt, setExample] = useState('Ex: To enable  unsubscribe functionality, include STOP keyword in your footer message.');


    //multi_product
    const [sections, setSections] = useState<Section[]>([]);

    const updateSectionTitle = (index: any, value: any) => {
      const newSections = [...values.sections];
      newSections[index].title = value;
      setFieldValue("sections", newSections);
    };

    const updateSectionProducts = (index: number, selectedOptions: SelectCatalogBtnOption[]) => {
      const products = selectedOptions.map(option => ({ product_retailer_id: option.value }));
      const newSections = [...values.sections];
      newSections[index].product_items = products;
      setFieldValue("sections", newSections);
    };



    const [text, setText] = useState("");
    const [buttonCharacterCounts, setButtonCharacterCounts] = useState<
      ButtonInfo[]
    >([
      { label: "1", name: "button1", id: "button1", value: "", count: 0 },
      { label: "2", name: "button2", id: "button2", value: "", count: 0 },
      { label: "3", name: "button3", id: "button3", value: "", count: 0 },
      { label: "4", name: "button4", id: "button4", value: "", count: 0 },
      { label: "5", name: "button5", id: "button5", value: "", count: 0 },
      { label: "6", name: "button6", id: "button6", value: "", count: 0 },
      { label: "7", name: "button7", id: "button7", value: "", count: 0 },
      { label: "8", name: "button7", id: "button7", value: "", count: 0 },
      { label: "9", name: "button7", id: "button7", value: "", count: 0 },
      { label: "10", name: "button7", id: "button7", value: "", count: 0 },
    ]);

    const [items, setItems] = useState<Item[]>([]);
    const [clickCount, setClickCount] = useState<number>(0);
    const [openModal, setModal] = useState(false);

    const [authTextlength, setcharauthText] = useState<number>(0);

    useEffect(() => {
      if (data?.components) {
        const carouselComponent = data.components.find((component: any) => component.type === 'CAROUSEL');
        const footerMsg = data.components.find((component: any) => component.type === 'FOOTER');

        if (footerMsg) {
          setFooterOpen(true);
        } else {
          setFooterOpen(false);
        }

        if (data?.cards) {
          const mediaArray = data.cards.map((card: any) => card.media);
          setMedia(mediaArray);
        }

        if (carouselComponent && carouselComponent.cards) {
          // console.log(" data?.carouselColumns",  data?.carouselColumns)
          const initialItems = carouselComponent?.cards?.map((card: any, index: number) => ({ id: index, index: index, card: card, variable: data?.carouselColumns ? data?.carouselColumns?.find((column: any) => column?.card ? column?.card === index : '')?.variable || '' : '', countryCode: data?.buttonColumns ? data?.buttonColumns[index] : '' }));
          // console.log("initialitem", initialItems)
          setItems(initialItems);
          setClickCount(initialItems.length);
        }
      }
      // console.log("btn columns", data)
      setcountryArr(data?.buttonColumns ? data?.buttonColumns : [])
    }, [data]);



    const handleOpenModal = () => {
      setSelectedItem(null);
      setEdit(false);
      setModal(true);
    }


    const handleCloseModal = () => {
      setModal(false);
    }

    const handleAddItem = (media: any, value: any, variable: any, countryCode: any) => {
      // console.log("value in body file", value);
      // console.log("media", media);
      if (value) {
        var varArray = value.map((e: any) => e.variable);
        var phoneCode = value.map((e: any) => e.countryCode);
      }
      handleCarousel(media, value, varArray, countryCode);

      // console.log("parent media", media);
      // console.log("parent template", value);
      // console.log("child template value", temValues);
      // console.log("variable in body", variable);

      if (!edit) {
        setClickCount(prevCount => prevCount + 1);
        setItems(prevItems => [
          ...prevItems,
          { id: prevItems.length, index: prevItems.length, card: value[value?.length - 1]?.card, variable: value[value?.length - 1]?.variable, countryCode: value[value?.length - 1]?.countryCode }
        ]);
      } else {
        setItems(value);
      }

      setVariable(variable);
      setcountryArr(countryCode);
      setMedia(media);
      setValues(value);
    };

    const handleDelete = (index: number, length: number) => {
      sweetalert(
        "warning",
        "Are you sure you want to continue?",
        () => {
          setItems(prevItems => prevItems.filter(item => item.index !== index));
          setMedia((prevMedia: any) => prevMedia.filter((_: any, mediaIndex: number) => mediaIndex !== length));
          setcountryArr((prevcode: any) => prevcode.filter((_: any, codeIndex: number) => codeIndex !== length));
          // setValues((prevValues: any) => prevValues.filter((_: any, valuesIndex: number) => valuesIndex !== index));
          const newMedia = media?.filter((_: any, mediaIndex: any) => mediaIndex !== length);
          const newvariable = variableArr?.filter((_: any, mediaIndex: any) => mediaIndex !== length);
          const newcode = countryArr?.filter((_: any, mediaIndex: any) => mediaIndex !== length);
          handleCarousel(newMedia, items?.filter(item => item.index !== index), newvariable, newcode);
        },
        () => { }
      );
    };

    const handleEdit = (index: number) => {
      const itemToEdit = items.find(item => item.index === index);
      if (itemToEdit) {
        if (type !== 'create') {
          setEdit(true);
          setSelectedItem(itemToEdit);
        } else {
          setEdit(true);
          setSelectedItem({ card: temValues[index]?.card, media: media[index]?.url, index: index, variable: variableArr[index], countryCode: countryArr[index] });
        }
        setModal(true);
      }
    };

    const handleClose = () => {
      setModal(false);
    };


    // const handleCloseModal = () => {
    //   setModal(false);
    // }
    // // Step 3: Function to handle adding a new item
    // const handleAddItem = (media: any, values: any) => {
    //   handleCarousel(media, values);
    //   // setMedia(media);
    //   // setValues(values);
    //   console.log("parent media", media);
    //   console.log("parent template", values);
    //   console.log("child template value", temValues)
    //   // setClickCount(prevCount => prevCount + 1);
    //   // setItems(prevItems => [
    //   //   ...prevItems,
    //   //   { id: prevItems.length + 1, index: clickCount }
    //   // ]);
    //   if(edit === false){
    //   setClickCount(prevCount => prevCount + 1);
    //   setItems(prevItems => [
    //     ...prevItems,
    //     { id: prevItems.length, index: prevItems.length, card : values }
    //   ]);
    // }
    //   setMedia(media);
    //   setValues(values);
    // };

    // // Step 4: Function to handle deleting an item
    // const handleDelete = (index: number) => {
    //   console.log("delete index", index)
    //   setItems(prevItems => prevItems.filter(item => item.index !== index));
    //   setMedia((prevMedia:any) => prevMedia.filter((_:any, mediaIndex: number) => mediaIndex !== index));

    //   // Update temValues
    //   setValues(prevValues => prevValues.filter((_, valuesIndex: number) => valuesIndex !== index));


    // };

    // // Step 5: Function to handle editing an item
    // const handleEdit = (index: number) => {
    //   console.log("itemssssssssssssssss", items)
    //   const itemToEdit = items.find(item => item.index === index);
    //   if (itemToEdit) {
    //     if(type !== 'create'){
    //     setEdit(true);
    //     setSelectedItem(itemToEdit);
    //     }else {
    //       console.log("valuetemp", temValues)
    //       setEdit(true);
    //       // let editvalue: any[] = [];
    //       // // let cards = [{cards :temValues[index], media: media[index]?.url, index: index}];
    //       // editvalue.push({cards :temValues[index], media: media[index]?.url, index: index})
    //       setSelectedItem({card :temValues[index], media: media[index]?.url, index: index});
    //     }
    //     setModal(true);
    //   }
    // };

    // const handleClose = () => {
    //   setModal(false);
    //   // setSelectedItem([]);
    // }
    const buttonCount =
      10 -
      ((values?.phoneChecked && values?.btnOptions.includes("action") ? 1 : 0) +
        (values?.webUrlChecked && values?.btnOptions.includes("action") ? 1 : 0) +
        (values?.webUrlChecked1 && values?.btnOptions.includes("action")
          ? 1
          : 0));

    useEffect(() => {

      getAllData();
      fetchData(0, 0, '', 0);
      setUrlCount(values?.websiteName?.length);
      setCharcoupon(values?.couponValue?.length);
      setCatalogheader(values?.catalogheader?.length)
      setwebCount(values?.webUrl?.length);
      setVarCount(values?.variable?.length);
      setCharphone(values?.phoneInput1?.length);
      setCountnum(values?.phoneInput2?.length);
      setCharacterCount(values?.messageBody?.length);
      if (values.category !== "authentication") {
        setCharacterVar(values?.footerMsg?.length);
      }
      setcharauthText(values?.authtextButton?.length);
      setLimitCount(values?.limitedText?.length);
      setCodeCount(values?.limitedCode?.length);
      setLimitUrl(values?.limitedUrl?.length);
      setLimitSample(values?.limitVar?.length);
      setLimitButton(values?.limitedButton?.length);
      // if(values?.category==="authentication"){
      //   setCharacterVar(32+values?.timeIndicator?.toString()?.length);        
      // }

      setcode(countrycode);
      if (values.btnOptions.includes("quickReply")) {
        const updatedButtonCharacterCounts = buttonCharacterCounts.map(
          (button: ButtonInfo, index: number) => ({
            ...button,
            count: values.replyButtons[index]?.value?.length || 0,
          })
        );
        setButtonCharacterCounts(updatedButtonCharacterCounts);
      }
    }, []);

    const handleButton = (setFieldValue: any) => {
      setBtnOpen(!button);
      setFieldValue("btnOptions", []);
    };

    // useEffect(() => {
    //   const options: SelectBtnOption[] = product.map((item) => ({
    //     value: item.retailerId,
    //     label: item.name,
    //   }));

    //   setCatalogOptions(options);
    //     console.log("options",catalogOptions);

    // }, []);

    const handleInputChange = (newValue: any, index: any) => {
      const updatedCounts = buttonCharacterCounts.map((button, i) =>
        i === index
          ? { ...button, value: newValue, count: newValue.length }
          : button
      );
      setButtonCharacterCounts(updatedCounts);
    };

    const handleRadioChange = (optionValue: string[]) => {
      // Reset character counts for all buttons when radio buttons change
      const resetCounts = buttonCharacterCounts.map((button) => ({
        ...button,
        count: 0,
      }));
      setButtonCharacterCounts(resetCounts);

      // Handle your other radio button logic here
    };

    // State to store the value of the webUrl input field

    const addPlaceholder = (field: any, placeholder: any) => {
      field.onChange({
        target: {
          name: field.name,
          value: field.value + placeholder,
        },
      });
    };

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node; // Type casting here
      if (
        popoverOpen &&
        popovertarget &&
        popovertarget !== target &&
        !popovertarget.contains(target) &&
        pickerRef.current &&
        !pickerRef.current.contains(target)
      ) {
        setPopoverOpen(false);
        setpopovertarget(null);
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [popovertarget, popoverOpen]);
    // useEffect(() => {
    //     const handleMouseDown = () => {
    //         setIsMouseDown(true);
    //     };

    //     const handleMouseUp = () => {
    //         setIsMouseDown(false);
    //     };

    //     if (textareaRef.current) {
    //         textareaRef.current.addEventListener('mousedown', handleMouseDown);
    //         textareaRef.current.addEventListener('mouseup', handleMouseUp);
    //     }

    //     return () => {
    //         if (textareaRef.current) {
    //             textareaRef.current.removeEventListener('mousedown', handleMouseDown);
    //             textareaRef.current.removeEventListener('mouseup', handleMouseUp);
    //         }
    //     };
    // }, []);

    // useEffect(() => {
    //     if (textareaRef.current && isMouseDown) {
    //     }
    // }, [isMouseDown]);

    // const handleVariableClick = (variable: string) => {
    //     setFieldValue('selectedVariable', variable);
    //     const variables = [...values.addVariables];
    //     if (variable !== 'Custom') {
    //         variables.push({ name: variable, id: variable, value: '' });
    //         setFieldValue('addVariables', variables);
    //         setFieldValue('messageBody', values.messageBody + ' ' + `{{${variable}}}`);
    //     } else {
    //         variables.push({ id: `variable ${counter}`, name: `variable ${counter}`, value: '' });
    //         setFieldValue('addVariables', variables);
    //         setFieldValue('messageBody', values.messageBody + ' ' + `{{variable ${counter}}}`);
    //         setCounter(counter + 1); // Update the value of counter
    //     }
    // };

    const customSelect = {
      control: (provided: any, state: any) => ({
        ...provided,
        // zIndex: 1000,
        backgroundColor: state.isDisabled ? '#FBFBFB' : provided.backgroundColor,
        caretColor: 'transparent',
        height: "40px",
        width: "190px",
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#FBFBFB' : provided.backgroundColor,
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled ? 'black' : provided.color,
      }),
      menu: (provided: any) => ({
        ...provided,
        width: "95%",

      }),
      menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,  // Ensures the portal menu renders on top
      }),
    };

    const customSelectLimit = {
      control: (provided: any, state: any) => ({
        ...provided,
        // zIndex: 1000,
        backgroundColor: state.isDisabled ? '#FBFBFB' : provided.backgroundColor,
        caretColor: 'transparent',
        height: "40px",
        width: "205px",
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#FBFBFB' : provided.backgroundColor,
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled ? 'black' : provided.color,
      }),
      menu: (provided: any) => ({
        ...provided,
        width: "95%",

      }),
      menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,  // Ensures the portal menu renders on top
      }),
    };


    const CustomOption = (props: any) => {
      return (
        <components.Option {...props}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null} // Prevent checkbox from being directly toggled
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <img src={props.data?.imageUrl} height={90} width={90} alt="catalog-image" />
                <p style={{ marginLeft: '10px', flex: 1 }}>{props.data.label}</p>
              </div>
              <p style={{ marginLeft: '300px' }}>{props.data.price}</p>
            </div>
          </div>
        </components.Option>
      );
    };


    const DropdownIndicator = (props: any) => {
      return (
        <components.DropdownIndicator {...props}>
          {props.selectProps.type !== "view" && (
            <span
              color="default" // Use 'default' to avoid overriding styles
              style={{
                position: "relative",
                left: "0px",
                color: '#69B0E1',
                borderColor: '#69B0E1',
                backgroundColor: 'transparent',
                border: '1px solid #69B0E1',
                padding: '3',
                textAlign: "center",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "8px",
                paddingRight: "8px",
                fontSize: "14px",
                minWidth: 'auto',
              }}
            >
              <FontAwesomeIcon icon={faAdd}></FontAwesomeIcon>
              Add Products
            </span>
          )}
        </components.DropdownIndicator>
      );
    };

    const CustomcatlogMenu = (props: any) => {
      const { innerRef, innerProps, selectProps, children } = props;
      const MenuList = components.MenuList;
      return (
        <components.Menu {...props}>
          <div style={{ padding: '10px', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
            FB Catalogues          </div>
          <div ref={innerRef} {...innerProps}>
            <MenuList {...props} />
          </div>
        </components.Menu>
      )
    }
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const CustomMenu = (props: any) => {
      const { innerRef, innerProps, selectProps, children } = props;
      const MenuList = components.MenuList;
      return (
        <components.Menu {...props} style={{ zIndex: 9999999999 }}>
          <div style={{ padding: '10px', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
            WhatsApp Products
          </div>
          <input
            id="mpminput"
            type="text"
            ref={inputRef}
            // className="mpminput"
            placeholder="Search..."
            style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
            value={selectProps.inputValue}
            onChange={(e: any) => selectProps.setInputValue(e.target.value)}
          />

          <div ref={innerRef} {...innerProps}>
            <MenuList {...props} style={{ zIndex: 9999 }} />
          </div>
        </components.Menu>
      );
    };
    const addSection = () => {
      if (values?.sections?.length < 10) {
        setFieldValue("sections", [...values.sections, { title: '', product_items: [] }]);
      }
    };


    const deleteSection = (index: any) => {
      if (values?.sections?.length > 1) { // Ensure there is at least one section remaining
        setFieldValue("sections", values.sections.filter((_: any, idx: any) => idx !== index));
      }
    }

    const handleVariableClick = (variable: string) => {
      const textarea = textareaRef.current; // Get the reference to the textarea
      if (!textarea) return; // Ensure textarea reference exists

      const start =
        textarea.selectionStart !== null ? textarea.selectionStart : 0;
      const end = textarea.selectionEnd !== null ? textarea.selectionEnd : 0;

      const textBeforeCursor = values.messageBody.slice(0, start);
      const textAfterCursor = values.messageBody.slice(end);

      setFieldValue("selectedVariable", variable);

      const newMessageBody = `${textBeforeCursor}{{${variable}}}${textAfterCursor}`;
      setFieldValue("messageBody", newMessageBody);

      const variables = [...values.addVariables];
      if (variable !== "Custom") {
        variables.push({ name: variable, id: variable, value: "" });
      } else {
        const newVariable = {
          id: `variable ${counter}`,
          name: `variable ${counter}`,
          value: "",
        };
        variables.push(newVariable);
        setCounter(counter + 1); // Update the value of counter
      }
      setFieldValue("addVariables", variables);
    };

    const handleSampleVariable = (index: number, value: string) => {
      const updatedSampleVariables = [...values.addVariables];
      if (value.length < 25) {
        updatedSampleVariables[index].value = value;
        setFieldValue("addVariables", updatedSampleVariables);
      }
    };
    const handleReplyButtons = (value: string, index: number) => {
      const updatedReplyBtns = [...values.replyButtons];
      // let crrctValue;
      // const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;
      // crrctValue = value.replace(emojiRegex, "");
      // if (crrctValue.length < 25) {
      //   updatedReplyBtns[index].value = crrctValue;
      //   setFieldValue("replyButtons", updatedReplyBtns);
      // }
      if (value.length < 25) {
        updatedReplyBtns[index].value = value;
        setFieldValue("replyButtons", updatedReplyBtns);
      }
    };

    const handleMultiCatalog = (value: any, index: any) => {
      if (!Array.isArray(values.multiCatalog)) {
        console.error("values.multiCatalog is not an array:", values.multiCatalog);
        return;
      }

      const updatedMpm = [...values.multiCatalog];
      updatedMpm[index].value = value;
      setFieldValue("multiCatalog", updatedMpm);
    };
    useEffect(() => {
      adjustTextareaHeight();
    }, [values.messageBody]);

    const adjustTextareaHeight = () => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      }
    };
    // const codeChange = (e: string) => {
    // setCode(e)
    // }
    const addButtons = (index: number) => {
      const newButton = {
        label: `Button ${values.replyButtons.length ? values.replyButtons.length + 1 : index
          }`,
        name: `button${values.replyButtons.length ? values.replyButtons.length + 1 : index
          }`,
        id: `button${values.replyButtons.length ? values.replyButtons.length + 1 : index
          }`,
        value: "",
      };
      setFieldValue("replyButtons", [...values.replyButtons, newButton]);
    };

    // const removeReplyButton = (indexToRemove: number) => {
    //   handleInputChange(0, indexToRemove);
    //   const newButtons = values.replyButtons.slice();
    //   newButtons.splice(indexToRemove, 1);
    //   for (let i = indexToRemove; i < newButtons.length; i++) {
    //     newButtons[i].label = `Button ${i + 1}`;
    //     newButtons[i].name = `button${i + 1}`;
    //     newButtons[i].id = `button${i + 1}`;
    //     newButtons[i].value = newButtons[i].value ? newButtons[i].value : "";
    //     newButtons[i].count = newButtons[i].count ? newButtons[i].count : 0;
    //   }
    //   setFieldValue("replyButtons", newButtons);
    // };

    const removeReplyButton = (indexToRemove: number) => {
      // Create a copy of the replyButtons array
      const newButtons = [...values.replyButtons];

      // Remove the button at the specified index
      newButtons.splice(indexToRemove, 1);

      // Update labels, names, and ids for the remaining buttons to match their new order
      const updatedButtons = newButtons.map((button, index) => ({
        ...button,
        label: `Button ${index + 1}`, // Update label to maintain the correct numbering
        name: `button${index + 1}`,   // Update name and id as well
        id: `button${index + 1}`,
      }));

      // Update the form state
      setFieldValue("replyButtons", updatedButtons);
    };


    const handleFormatClick = (formatType: string) => {
      let selectedText;

      switch (formatType) {
        case "bold":
          selectedText = `${mouseSelectedText
            ? values.messageBody.replace(
              mouseSelectedText,
              "*" + mouseSelectedText + "*"
            )
            : values.messageBody + "**"
            }`;
          break;
        case "italic":
          selectedText = `${mouseSelectedText
            ? values.messageBody.replace(
              mouseSelectedText,
              "_" + mouseSelectedText + "_"
            )
            : values.messageBody + "_"
            }`;
          break;
        case "strikethrough":
          selectedText = `${mouseSelectedText
            ? values.messageBody.replace(
              mouseSelectedText,
              "~" + mouseSelectedText + "~"
            )
            : values.messageBody + "~~"
            }`;
          break;
        default:
          break;
      }
      setMouseSelectedText("");
      setFieldValue("messageBody", selectedText);
    };
    const extractedString = (text: string) => {
      const regex = /{{([^{}]+)}}/g;
      const matches = regex.exec(text);
      const extractedString =
        matches && matches.length > 1 ? matches[1].trim() : "";
      return extractedString;
    };
    const handleMouseUp = () => {
      const selection = window.getSelection();
      if (selection) {
        const selectedText = selection.toString();
        if (selectedText) {
          setMouseSelectedText(selectedText);
        }
      }
    };

    // useEffect(()=> {
    //   if(footer === true && values?.category === "marketing" && !values.footerMsg){
    //     setFieldValue("footerMsg", "Reply with 'STOP' to unsubscribe from marketing messages");
    //   }
    // },[footer])

    const handleFooter = (setFieldValue: any) => {
      setFooterOpen(!footer);
      setFieldValue("footerMsg", "");
    };
    const handleThumbnail = (setFieldValue: any) => {
      setThumbnailOpen(!thumbnail);
      setFieldValue("catalogDropdown", "")
    }
    const handleEmojiSelect = (e: any) => {
      const selectionStart = textareaRef.current!.selectionStart;
      const selectionEnd = textareaRef.current!.selectionEnd;
      const before = values.messageBody.substring(0, selectionStart);
      const after = values.messageBody.substring(
        selectionEnd,
        values.messageBody.length
      );
      const updatedValue = before + e.native + after;
      setFieldValue("messageBody", updatedValue);
      setTimeout(() => {
        textareaRef.current!.selectionStart = textareaRef.current!.selectionEnd =
          selectionStart + e.native.length;
      }, 0);
      setPopoverOpen(false);
      setpopovertarget(null);
    };
    // console.log(values.variable)
    const carouselType: SelectBtnOption[] = [
      { value: 'image carousel', label: 'Image Carousel' },
      { value: 'video carousel', label: 'Video Carousel' }
    ]
    const handlePlaceholderChange = () => {
      const newPlaceholderValue = "{VERIFICATION_CODE}";
      const updatedMessageBody = `${newPlaceholderValue} is your verification code.${values?.securitydisclaimer ? "\n\nFor your security, don't share this code." : ""}`;
      setFieldValue("messageBody", updatedMessageBody);
    };
    useEffect(() => {
      if (values.category === "authentication") {

        // if(values?.expirationwarning && values?.timeIndicator){
        //   // setFieldValue("footerMsg",`The code will expire in ${values?.timeIndicator} minutes.`)
        //   const val=values?.timeIndicator?.toString().length;
        //   setCharacterVar(32+val);        
        // }
        // else{
        //   // setFieldValue("footerMsg","")
        //   setCharacterVar(0);        

        // }
        const initialMessage = `{VERIFICATION_CODE} is your verification code.${values?.securitydisclaimer ? "\n\nFor your security, don't share this code." : ""}`;
        setFieldValue("messageBody", initialMessage);

      }
    }, [values.category, values.securitydisclaimer, values.timeIndicator, setFieldValue, values.expirationwarning]);

    useEffect(() => {
      const handleMouseUp = () => {
        const selection = window.getSelection();
        if (selection) {
          const selectedText = selection.toString();
          if (selectedText) {
            setMouseSelectedText(selectedText);
          }
        }
      };

      // Attach mouseup event to the document
      document.addEventListener("mouseup", handleMouseUp);

      // Clean up event listener when component unmounts 
      return () => {
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }, [setMouseSelectedText]);


    const reorder = (list: any[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    const onDragEnd = (result: DropResult) => {
      const { source, destination } = result;
      if (!destination) {
        return;
      }
      const reorderedButtons = reorder(values.replyButtons, source.index, destination.index);
      const updatedButtons = reorderedButtons.map((button, index) => ({
        ...button,
        label: `Button ${index + 1}`,
      }));
      setFieldValue('replyButtons', updatedButtons);
    };

    // console.log("valuesweb", values?.webUrl)
    // console.log("valuesweb1", values?.webUrl1)


    return (
      <div className="templateBody">
        {values.category === "authentication" && (
          <>
            <h6 className="pt-2">
              Message Body
              <span className="required" />
            </h6>
            <div className="headerbox">
              <div className="fixed-text-container mt-2 mb-2">
                <span contentEditable={false}>{"{"}</span>
                <span
                  contentEditable={false}
                  onInput={handlePlaceholderChange}
                  suppressContentEditableWarning={true}
                >
                  VERIFICATION_CODE
                </span>
                <span contentEditable={false}>{"} is your verification code."}</span>
                {values.securitydisclaimer && (
                  <span contentEditable={false} > <br />For your security, don't share this code.</span>
                )}
              </div>

            </div>


          </>

        )}
        {values.category !== "authentication" && (
          <>
            <h6 className="pt-2">
              Message Body
              <span className="required" />
            </h6>
            <div className={`headerbox `}>
              <div className="bodyOptions">
                <Label className="mr-2 mt-1">
                  Variables{" "}
                  <TooltipReference
                    placement="right"
                    icon={faInfoCircle}
                    content="This is text that you specify in the API that will be personalised to the customer, such as their name or invoice number.
                        "
                    tooltipId="addVariables"
                  />{" "}
                  :
                </Label>
                <div className="btn-group">
                  <Button
                    type="button"
                    onClick={() => handleVariableClick("Name")}
                    className="btn-sm mr-2"
                    disabled={type === "view"}
                  >
                    Name
                  </Button>
                  <Button
                    type="button"
                    onClick={() => handleVariableClick("Email")}
                    className="btn-sm mr-2"
                    disabled={type === "view"}
                  >
                    Email
                  </Button>
                  <Button
                    type="button"
                    onClick={() => handleVariableClick("Phone")}
                    className="btn-sm mr-2"
                    disabled={type === "view"}
                  >
                    Phone
                  </Button>
                  <Button
                    type="button"
                    onClick={() => handleVariableClick("Custom")}
                    className="btn-sm"
                    disabled={type === "view"}
                  >
                    Custom
                  </Button>
                </div>
              </div>
              <div className="seperationLine"></div>
              <FormGroup>
                <Field name="messageBody">
                  {({ field }: FieldProps & { form: FormikProps<any> }) => (
                    <div>
                      <textarea
                        ref={textareaRef}
                        {...field}
                        placeholder="Hey {{Name}}! 👋 
                                Explore the {{Brand_Name}} collection for a touch of brilliance. Get started now: Shop Here 🚀🌟"
                        maxLength={1024}
                        className={`form-control tempInputs mt-2 iconFontSize`}
                        onMouseUp={handleMouseUp}
                        onChange={(event) => {
                          let text = event.target.value;
                          text = text.replace(/\n{3,}/g, "\n\n");
                          // const lineBreaks = (text.match(/\n/g) || []).length;
                          // if (text.trim() === '' && lineBreaks > 1) {
                          // return;
                          // }
                          setCharacterCount(text.length);
                          setFieldValue("messageBody", text);
                          const regex = /{{([^{}]+)}}/g;
                          const matches = text.match(regex);
                          // const newVariables = matches
                          //     ? matches.map((match, index) => ({
                          //         id: extractedString(match),
                          //         name: extractedString(match),
                          //         value: values.addVariables[index].value,
                          //     }))
                          const newVariables = matches
                            ? matches.map((match, index) => {
                              const id = extractedString(match);
                              const name = extractedString(match);
                              const value = values.addVariables[index]?.value
                                ? values.addVariables[index].value
                                : "";
                              return {
                                id,
                                name,
                                value,
                              };
                            })
                            : [];
                          setFieldValue("addVariables", newVariables);
                          if (!newVariables.length) {
                            setFieldValue("selectedVariable", "");
                          }
                        }}
                        onPaste={(event) => {
                          // event.preventDefault();
                          // let pastedText = event.clipboardData.getData('text');
                          // pastedText = pastedText.replace(/\n{3,}/g, '\n\n');
                          // setFieldValue('messageBody', pastedText);
                          event.preventDefault();
                          let pastedText = event.clipboardData.getData("text");
                          pastedText = pastedText.replace(/\n{3,}/g, "\n\n");

                          const textarea = textareaRef.current;
                          if (textarea) {
                            const { selectionStart, selectionEnd } = textarea;
                            const currentValue = field.value || "";
                            const newValue =
                              currentValue.substring(0, selectionStart) +
                              pastedText +
                              currentValue.substring(selectionEnd);

                            const regex = /{{([^{}]+)}}/g;
                            const matches = newValue.match(regex);
                            const newVariables = matches
                              ? matches.map((match, index) => {
                                const id = extractedString(match);
                                const name = extractedString(match);
                                const value = values.addVariables[index]?.value
                                  ? values.addVariables[index].value
                                  : "";
                                return {
                                  id,
                                  name,
                                  value,
                                };
                              })
                              : [];
                            setFieldValue("addVariables", newVariables);
                            if (!newVariables.length) {
                              setFieldValue("selectedVariable", "");
                            }


                            setFieldValue("messageBody", newValue);
                            setCharacterCount(newValue.length);
                          }
                        }}
                        disabled={type === "view" || (values?.category === "catalog_marketing" && !values.catalogImageUrl)}
                      />
                      <div className="float-right text-muted small">
                        {characterCount} / 1024
                      </div>
                    </div>
                  )}
                </Field>
              </FormGroup>

              <div className="seperationLine"></div>
              <div className="mt-2">
                <button
                  type="button"
                  className="hideBorder mr-2"
                  onClick={() => handleFormatClick("bold")}
                  disabled={type === "view"}
                >
                  <strong>B</strong>
                </button>
                <button
                  type="button"
                  className="hideBorder mr-2"
                  onClick={() => handleFormatClick("italic")}
                  disabled={type === "view"}
                >
                  <em>I</em>
                </button>
                <button
                  type="button"
                  className="hideBorder mr-2"
                  onClick={() => handleFormatClick("strikethrough")}
                  disabled={type === "view"}
                >
                  <del>S</del>
                </button>
                <button
                  id="popemoji"
                  type="button"
                  className="hideBorder"
                  onClick={(event) => {
                    setPopoverOpen(true);
                    setpopovertarget(event.currentTarget);
                  }}
                  disabled={type === "view"}
                >
                  <FontAwesomeIcon
                    icon={faFaceSmile}
                    id="convAddEmoji"
                    className="text-black"
                    color="#90939F"
                    fontSize={14}
                  />
                </button>
                <div ref={pickerRef}>
                  <Popover
                    isOpen={popoverOpen}
                    target="popemoji"
                    toggle={() => {
                      setPopoverOpen(false);
                      setpopovertarget(null);
                    }}
                    placement="right"
                    className="emoji-popover"
                  >
                    <PopoverBody>
                      <div ref={pickerRef}>
                        {" "}
                        {/* wrap Picker with a div and assign the ref here */}
                        <Picker
                          data={customData}
                          onEmojiSelect={(e: any) => {
                            handleEmojiSelect(e);
                          }}
                        />
                      </div>
                    </PopoverBody>
                  </Popover>
                </div>
              </div>
            </div>
          </>
        )}
        <ErrorMessage
          name="messageBody"
          component="div"
          className="text-danger pt-1"
        />
        {values?.addVariables?.length > 0 && (
          <div className="dragHeader">
            Please declare the values for variables to get approve from whatsapp
            team
          </div>
        )}
        {values?.addVariables?.map(
          (
            variable: { name: string; id: string; value: string },
            ind: number
          ) => (
            <div key={ind} className="bodyOptions mt-2">
              <FormGroup>
                {ind === 0 ? <Label>Variable Name</Label> : ""}
                <Field
                  type="text"
                  name={`variableName_${ind}`}
                  value={variable.name}
                  className="form-control tempInputsBorder mt-2 formcontrol"
                  disabled
                />
              </FormGroup>
              <FormGroup className="seperateSample">
                {ind === 0 ? (
                  <Label>
                    Sample Value<span className="required"></span>
                  </Label>
                ) : (
                  ""
                )}
                <Field
                  type={
                    variable.name === "Name"
                      ? "text"
                      : variable.name === "Email"
                        ? "email"
                        : variable.name === "Phone"
                          ? "number"
                          : "text"
                  }
                  name={`sampleValue_${ind}`}
                  placeholder={
                    variable.name === "Name"
                      ? "Ex: David, Priya"
                      : variable.name === "Email"
                        ? "Ex: hello@gmail.com"
                        : variable.name === "Phone"
                          ? "Ex: 9876556423"
                          : `Content for {{${variable.name}}}`
                  }
                  className="form-control tempInputsBorder mt-2 formcontrol"
                  value={variable.value}
                  onChange={(e: any) => {
                    handleSampleVariable(ind, e.target.value);

                    // setCharacterVar(e.target.value.length)
                  }}
                  onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                    e.currentTarget.blur()
                  }
                  disabled={type === "view"}
                />
                {/* <div className='float-right text-muted'>{characterCount} / 25</div> */}
              </FormGroup>
            </div>
          )
        )}
        <ErrorMessage
          name="addVariables"
          component="div"
          className="text-danger pt-1"
        />
        {values.category === "catalog_marketing" && (
          <>
            <h6 className="pt-2">Template Format</h6>
            <div className="headerbox">
              <div className="mt-2">
                {/* {catalogInfo.map((i: any, index: number) => (
                <>
                <div className="catalogDetails">
                  <div className="ml-2" key={index}>{i.name.replace('_',' ')}</div>
                  <div className="ml-5 mb-3">Number of products: {i.productCount} items</div>
                  <br />
                  </div>
                </>
              ))} */}
                {radioOptions.map((option, index) => (
                  <label key={index} className="ml-4">
                    <Field
                      type="radio"
                      name="options"
                      value={values?.catalogOption}
                      disabled={type === "view" || !values?.catalogImageUrl}
                      className="ml-3"
                      onChange={() => {
                        setFieldValue("catalogOption", option?.value)
                        setFieldValue("sections", [])
                        setSelectedOption(option.value)
                        if (option.value === "multi-product") {
                          addSection()
                        }
                      }}
                      checked={values.catalogOption === option.value}
                    />
                    <div className="ml-3">
                      <div className="mpmtext">{option.label}</div>
                      <div className="text-muted descriptiontext">{option.description}</div>
                    </div>
                  </label>
                ))}
                {values?.catalogImageUrl && (
                  <div className="catalogbox">
                    <div className="product-info">
                      <div>{catalogInfo?.[0]?.name}</div>
                      <span className="text-muted mt-2">
                        No. of Products: {catalogInfo?.[0]?.productCount} items
                      </span>
                    </div>
                    <span className="last-sync">
                      Last synced: {moment(catalogInfo[0]?.updatedAt).format("LL")}
                    </span>
                  </div>)}

                {values?.category === "catalog_marketing" && !values.catalogImageUrl && (
                  <Alert color="danger">
                    <span className='d-flex justify-content-center align-items-center' >
                      No Products found.Connect Catalogue to Sync!
                    </span>
                    <div className='d-flex justify-content-center align-items-center m-2'><FacebookLogin freeTrial={false} /></div>
                  </Alert>
                )
                }


              </div>
            </div>
          </>
        )}
        {values?.catalogOption === "catalogue" && values?.catalogImageUrl && (
          <>
            <div className="d-flex">
              <h6 className="mt-2">Thumbnail</h6>
              <Button
                type="button"
                onClick={() => handleThumbnail(setFieldValue)}
                className="optionBtn mt-2"
              >
                (Optional)
              </Button>
            </div>
            {thumbnail && (
              <div className="headerbox">
                <h6 className="ml-4 mt-3 font-size-small">
                  Select thumbnail image
                </h6>

                {values?.catalogOption === "catalogue" && values.catalogImageUrl && (
                  <div className="col-md-5">
                    <FormGroup className="mr-2 ml-2 mt-2 formcontrol">
                      <Field name="catalogDropdown">
                        {({ field, form }: any) => (
                          <>
                            <Select
                              {...field}
                              disabled={type === "view"}
                              options={catalogOptions}
                              // formatOptionLabel={
                              //   (catalog: any) => (
                              //     <img src={catalog?.imageUrl} height={90} width={200} alt="catalog-image" />
                              //   )
                              // }
                              // isClearable={true}
                              placeholder="Select Product"

                              onChange={(selectedOption: SelectCatalogBtnOption) => {
                                form.setFieldValue(
                                  "catalogDropdown",
                                  selectedOption?.value
                                );
                                form.setFieldValue("catalogImageUrl", selectedOption?.imageUrl)
                                console.log("selected option", values?.catalogImageUrl)

                                form.setFieldValue("variable", "");
                                form.setFieldValue("websiteName", "");
                                form.setFieldValue("webUrl", "");
                              }}
                              value={catalogOptions.find(
                                (option) => option.value === field.value
                              )}
                              isDisabled={type === "view"}
                              styles={customStyles}
                              components={{
                                Menu: CustomcatlogMenu
                              }}

                            />
                          </>
                        )}
                      </Field>
                    </FormGroup>
                  </div>
                )}
                <div className="text-muted ml-4" style={{ position: "relative", bottom: "10px" }}>If image not selected, Meta will use your first product’s image as thumbnail.</div>

              </div>
            )}
          </>

        )}


        {values.catalogOption === "multi-product" && (
          <>
            <div className="d-flex">
              <h6>Message Header</h6><span className="required"></span>


            </div>
            <div className="headerbox">
              <FormGroup className="mr-2 md-2 mt-2">
                <Field
                  type="text"
                  id="catalogheader"
                  name="catalogheader"
                  // value={"View catalog"}
                  className="form-control tempInputsBorder formcontrol"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    restrictText(event, setFieldValue, 60);
                    setCatalogheader(event?.target?.value?.length)
                  }}
                  placeholder="Enter sample text"
                  disabled={
                    type === "view"
                  }
                />
                <div className="float-right text-muted small"> {catalogheader} /60</div>
              </FormGroup>
            </div>
            <ErrorMessage name="catalogheader" component="div" className="text-danger pt-1" />

          </>
        )}
        {values.catalogOption === "multi-product" && (
          <>
            <h6>Select Product</h6>
            <div className="headerbox">
              {values?.catalogOption !== "catalogue" && (
                <>
                  {values?.sections?.map((section: any, sectionIndex: any) => (
                    <div className="col-md-5" key={sectionIndex}>
                      <div className="mpmsectionbox">
                        <FormGroup className="ml-2 col-md-7">
                          <Label htmlFor={`title-${sectionIndex}`}>
                            Enter title<span className="required"></span>
                            <span className="ml-1">
                              <TooltipReference
                                placement="right"
                                icon={faInfoCircle}
                                content="Name can only contain lowercase alphanumeric characters & underscores (_)"
                                tooltipId="tempName"
                              />
                            </span>
                          </Label>
                          <Field
                            disabled={type === "view"}
                            type="text"
                            id={`title-${sectionIndex}`}
                            name={`title-${sectionIndex}`}
                            className="form-control formcontrol"
                            placeholder="Enter title"
                            value={section?.title?.slice(0, 23)}
                            onChange={(e: any) =>
                              updateSectionTitle(sectionIndex, e.target.value)}
                          />
                          <span className="float-right text-muted small">
                            {section?.title?.length} / 24
                          </span>
                          <ErrorMessage name={`sections.${sectionIndex}.title`} component="div" className="text-danger pt-1" />
                        </FormGroup>
                        {/* <span className="ml-3 mt-3 font-size-small">Add products</span><span className="required"></span> */}
                        {/* <span className="ml-1">
                        <FontAwesomeIcon icon={faInfoCircle} color="#666E7D" title="Supports up to 30 products total, across all sections."></FontAwesomeIcon>

                        </span> */}
                        {/* <span className="ml-1">
                              <TooltipReference
                                placement="right"
                                icon={faInfoCircle}
                                content="Supports up to 30 products total, across all sections."
                                tooltipId={`addproduct-${sectionIndex}`}
                              />
                            </span> */}
                        <div className="d-flex"></div>
                        <FormGroup className="ml-4 formcontrol" style={{ width: '100%', marginTop: "30px", position: "relative", right: "7px", }}>
                          <Field name={`catalogDropdown-${sectionIndex}`}
                            disabled={type === "view"}
                          >
                            {({
                              field,
                              form: { setFieldValue },
                            }: {
                              field: any;
                              form: { setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void };
                            }) => (
                              <>
                                <Select
                                  options={filteredCatalogOptions}
                                  isMulti
                                  isClearable={false}
                                  placeholder=""
                                  formatOptionLabel={(catalog) => (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: "'#EBF7FD'" }}>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={catalog?.imageUrl} height={90} width={90} alt="catalog-image" />
                                        <p style={{ marginLeft: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }} title={catalog.label}>
                                          {catalog?.label?.length > 7 ? `${catalog?.label?.substring(0, 7)}...` : catalog.label}
                                        </p>
                                      </div>
                                      {/* <p style={{ marginLeft: '300px' }}>{catalog.price}</p> */}
                                      <p style={{ marginLeft: catalog?.label?.length <= 10 ? '420px' : "420px", display: 'flex', alignItems: 'center' }}>
                                        {catalog.price.includes('₹') ? (
                                          <>
                                            <FontAwesomeIcon icon={faIndianRupeeSign} />
                                            {catalog.price.replace('₹', '')}
                                          </>
                                        ) : (
                                          <>
                                            <FontAwesomeIcon icon={faIndianRupeeSign} />
                                            {catalog.price}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  )}
                                  onChange={(selectedOptions: any) => {

                                    updateSectionProducts(sectionIndex, selectedOptions as SelectCatalogBtnOption[]);
                                    const productItems = selectedOptions.map((option: any) => ({
                                      product_retailer_id: option.value
                                    }));
                                    const updatedSection = {
                                      ...values.sections,
                                      product_items: productItems
                                    };
                                    const updatedSections = values.sections.map((sec: any, idx: any) =>
                                      idx === sectionIndex ? updatedSection : sec
                                    );
                                  }}
                                  value={catalogOptions.filter(option =>
                                    section.product_items.some((item: any) => item.product_retailer_id === option.value)
                                  )}
                                  // isSearchable={false}
                                  isDisabled={type === 'view'}
                                  styles={catalogStyles}
                                  components={{
                                    DropdownIndicator,
                                    Menu: CustomMenu,

                                  }}
                                />
                                {type !== "view" && (
                                  <>
                                    <div className="addDelButtons">
                                      {sectionIndex === values.sections.length - 1 && values.sections.length < 10 && type !== "view" && (
                                        <Button
                                          style={{ backgroundColor: "#EFF8EF", color: "#397F37", border: "none", fontSize: "14px", position: "relative", top: "-35px" }}
                                          className="mr-3"
                                          onClick={() => {
                                            addSection()
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faAdd} className="mr-2"></FontAwesomeIcon>
                                          Add Section
                                        </Button>)}
                                      {values?.sections.length > 1 && type !== "view" && (
                                        <Button
                                          style={{ backgroundColor: "#FFEAEA", color: "#E89090", border: "none", fontSize: "14px", position: "relative", top: "-35px" }}
                                          onClick={() => deleteSection(sectionIndex)}
                                          className="mr-2"
                                        >
                                          Delete Section
                                        </Button>
                                      )}
                                    </div>
                                  </>
                                )}

                                <ErrorMessage name={`sections.${sectionIndex}.product_items`} component="div" className="text-danger pt-1" />
                              </>

                            )}
                          </Field>
                        </FormGroup>
                      </div>

                      {/* <ErrorMessage name="sections" component="div" className="text-danger pt-1" /> */}

                    </div>

                  ))}

                </>


              )}
            </div>
          </>
        )}
        {values.category === "authentication" && (

          <>
            <Label  >
              Time-To-Live
              <span className="ml-2">
                <TooltipReference
                  placement="right"
                  icon={faInfoCircle}
                  content="If Meta is unable to deliver a message to a WhatsApp user, they will continue attempting to deliver the message for a period of time known as a time-to-live."
                  tooltipId="footer"
                />
              </span>

            </Label>
            <Field
              className="m-2"
              type="number"
              name="ttlseconds"
              placeholder="Enter TTL seconds"
              disabled={type === "view"}
              value={
                values.ttlseconds
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue("ttlseconds", event?.target?.value)
              }}
            />

            <div className="d-flex mb-2">
              <FormGroup switch className="m-2" >

                <Label check>
                  Security disclaimer </Label>
                <Input
                  role="switch"
                  type="switch"
                  checked={values?.securitydisclaimer}
                  disabled={type === "view"}
                  onChange={() => setFieldValue("securitydisclaimer", !values?.securitydisclaimer)}
                />
              </FormGroup>


              <FormGroup switch className="m-2" >

                <Label check>
                  Expiration warning </Label>
                <Input
                  role="switch"
                  disabled={type === "view"}
                  type="switch"
                  checked={values?.expirationwarning}
                  onChange={() => {
                    setFieldValue("expirationwarning", !values?.expirationwarning)
                    setFieldValue("timeIndicator", "")

                  }}
                />
              </FormGroup>
            </div>
            {values.category === "authentication" && values.expirationwarning && (
              <>
                <h6 className={values.expirationwarning ? "" : "text-muted"}>Time Indicator(in Minutes)
                  <span className="ml-1">
                    <FontAwesomeIcon icon={faInfoCircle} title="This value would indicate the number of minutes the password or code is valid. Minimum 1 and maximum 90 minutes."></FontAwesomeIcon>
                    {/* <TooltipReference
              placement="right"
              content="This value would indicate the number of minutes the password or code is valid. Minimum 1 and maximum 90 minutes."
              tooltipId="footer"
            /> */}
                  </span>
                </h6>
                <div className="headerbox">
                  <Field
                    className="m-2"
                    type="number"
                    name="timeIndicator"
                    placeholder="Enter expiration duration"
                    disabled={type === "view" || !values?.expirationwarning}
                    value={
                      values.timeIndicator
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue("timeIndicator", event?.target?.value)
                    }}
                  />
                  {values?.expirationwarning && (
                    <ErrorMessage
                      name="timeIndicator"
                      component="div"
                      className="text-danger pt-1"
                    />

                  )}
                </div>
              </>
            )}
          </>
        )}

        {values?.category !== 'carousel' && values.category !== "authentication" && values?.category !== 'limited' && <h6 className="pt-2">
          Footer
          {values.category !== "authentication" && (  
            <Button
              type="button"
              onClick={() => handleFooter(setFieldValue)}
              className="optionBtn ftBtn mt-1"
            >
              (Optional)
            </Button>
          )}
          <span className="ml-1">
            <TooltipReference
              placement="right"
              icon={faInfoCircle}
              content=" Add a short line of text to the bottom of your message template.If you add the marketing opt-out button, 
                        the associated footer will be shown here by default"
              tooltipId="footer"
            />
          </span>
        </h6>}
        {footer && values?.category !== 'carousel' && values.category !== "authentication" && values?.category !== 'limited' ? (
          <>
            <div className="headerbox">
              <FormGroup>
                <Field
                  type="text"
                  name="footerMsg"
                  placeholder="You can use this space to add a tagline, a way to unsubscribe, etc.,"
                  className="form-control tempInputs mt-2 text-muted"
                  //disabled={type === "view" || values.category === "marketing" || values.category === "coupon_marketing" || values.category === "catalog_marketing" || values.category === "authentication"}
                  value={
                    // values?.category === "marketing" && !values.footerMsg && exampleTxt
                    //   ? "Reply with 'STOP' to unsubscribe from marketing messages"
                    //   :
                    values.footerMsg
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    restrictText(event, setFieldValue, 60);
                    setCharacterVar(event.target.value.length);
                    setExample('');
                  }}
                />

                <div className="float-right text-muted small">
                  {footerCount} / 60
                </div>

              </FormGroup>
            </div>
            {values?.category === 'marketing' && <p className="text-muted">{exampleTxt}</p>}
          </>
        ) : (
          ""
        )}
        {values.category === "coupon_marketing" && values?.category !== 'carousel' && (
          <div>
            <h6 className="pt-2">Call to Action(Copy code
              <span className="ml-1">
                <TooltipReference
                  placement="right"
                  icon={faInfoCircle}
                  content="Coupon code templates are marketing templates that display a single copy code button. When tapped, the code is copied to the customer's clipboard."
                  tooltipId="coupon"
                />
              </span>
              )<span className="required"></span>
            </h6>
            <div className="headerbox">
              <div className="mt-2 col-md-7">
                {/* <Label className="mt-2 mr-2">Button text :</Label> */}
                <Label className="mt-1 mr-2">Enter Coupon code :</Label>
                <FormGroup className="mr-2 md-2">
                  <Field
                    type="text"
                    id="couponValue"
                    name="couponValue"
                    // value={"View catalog"}
                    className="form-control tempInputsBorder formcontrol"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      restrictText(event, setFieldValue, 16);
                      // setFieldValue("couponValue",event?.target?.value?.slice(0,15))
                      setCharcoupon(event?.target?.value?.length);
                    }}
                    placeholder="Enter sample text"
                    disabled={
                      type === "view"
                    }
                  />
                  <div className="float-right text-muted small"> {values?.couponValue?.length} /15</div>
                </FormGroup>

              </div>
              <div className="ml-3">
                <ErrorMessage
                  name="couponValue"
                  component="div"
                  className="text-danger pt-1"
                />
              </div>
            </div>
          </div>
        )}
        {values?.category !== 'carousel' && values?.category !== 'limited' && <div className="d-flex">
          <h6 className="pt-1">Buttons</h6>
          {values.category !== "authentication" && (
            <Button
              type="button"
              onClick={() => handleButton(setFieldValue)}
              className="optionBtn mt-1 ml-2"
            >
              (Optional)
            </Button>
          )}
          <span className="ml-1 mt-1 h6">
            <TooltipReference
              placement="right"
              icon={faInfoCircle}
              content="Create buttons that let customers respond to your message or take action"
              tooltipId="buttons"
            ></TooltipReference>
          </span>
        </div>}
        {button && values?.category !== 'carousel' && values?.category !== 'limited'? (
          <div className="headerbox">
            <FormGroup>
              <div
                role="group"
                aria-labelledby="options-group"
                className="headerOptions"
              >
                <ul>
                  {values.category !== "catalog_marketing" && values.category !== "authentication" ? (
                    buttonsOptions.map((option) => (
                      <li key={option.value} className="radioOption ml-0">
                        <label>
                          <Field
                            type="checkbox"
                            className="ml-3"
                            name="btnOptions"
                            value={option.value}
                            checked={values.btnOptions.includes(option.value)}
                            onChange={(e: any) => {
                              const { value, checked } = e.target;
                              if (value === 'action') {
                                setFieldValue("phoneChecked", false)
                                setFieldValue("webUrlChecked", false)
                                setFieldValue("webUrlChecked1", false)
                              }
                              let updatedOptions = [];

                              if (Array.isArray(values.btnOptions)) {
                                if (checked) {
                                  updatedOptions = [...values.btnOptions, value];
                                } else {
                                  updatedOptions = values.btnOptions.filter(
                                    (item: any) => item !== value
                                  );
                                }
                              } else {
                                let btnOptionsArray = values.btnOptions
                                  ? values.btnOptions.split(",")
                                  : [];

                                updatedOptions = checked
                                  ? [values.btnOptions, value]
                                  : [
                                    btnOptionsArray?.find(
                                      (item: any) => item !== value
                                    ),
                                  ];
                              }
                              setFieldValue("btnOptions", updatedOptions);

                              if (value === "quickReply") {
                                if (checked && !values.replyButtons.length) {
                                  addButtons(1);
                                } else if (!checked) {
                                  setFieldValue("replyButtons", []);
                                }
                              }
                              handleRadioChange(updatedOptions);
                            }}
                            disabled={type === "view"}
                          />
                          <span className="headerOptnLabel">{option.label}</span>
                        </label>
                      </li>
                    ))
                  ) : (
                    values.category === "catalog_marketing" && (
                      <label>
                        <Field
                          type="checkbox"
                          className="ml-3"
                          name="catalog"
                          disabled={true}
                          checked={true}
                        />
                        <span className="headerOptnLabel">Catalog</span>
                      </label>)
                  )

                  }
                  {values.category === "authentication" && (
                    <label>
                      <Field
                        type="checkbox"
                        className="ml-3"
                        name="authButton"
                        disabled={true}
                        onChange={() => setFieldValue("authButton", !values.authButton)}
                        checked={
                          values?.authButton || values.category === "authentication"
                        }
                      />
                      <span className="headerOptnLabel">Copy Code<FontAwesomeIcon className="ml-2" icon={faInfoCircle} title="A copy code button copies the one-time password or code to the user's clipboard. The user can then manually switch to your app and paste the password or code into your app's interface."></FontAwesomeIcon></span>
                    </label>
                  )}
                </ul>
              </div>
              {!values.btnOptions.includes("quickReply") &&
                values.btnOptions.includes("action") && (
                  <div className="ml-1 dragHeader">
                    Create up to 10 buttons that let customers respond to your
                    message or take action
                  </div>
                )}
              {(values.btnOptions.includes("quickReply") &&
                !values.btnOptions.includes("action")) ||
                (values.btnOptions.includes("quickReply") &&
                  values.btnOptions.includes("action") && (
                    <div className="ml-1 dragHeader">
                      Create up to 10 buttons that let customers respond to your
                      message or take action
                    </div>
                  ))}
            </FormGroup>
            {values.btnOptions.includes("quickReply") ||
              values.btnOptions.includes("action") ? (
              <div className="seperationLine"></div>
            ) : (
              ""
            )}
            {values.category === "catalog_marketing" && (
              <div className="mt-2">
                <div className="actionsBtns">
                  <Label className="mt-2 mr-2">Button</Label>
                  <FormGroup className="mr-2">
                    <Field
                      type="text"
                      id="1"
                      name="catalogButton"
                      value={selectedOption === "catalogue" || values.catalogOption === "catalogue" ? "View catalog" : "View items"}
                      className="form-control tempInputsBorder formcontrol"
                      placeholder="Ex: Interested, yes, no"
                      disabled={
                        type === "view" || values.category === "catalog_marketing"
                      }
                    />
                    <div className="float-right text-muted small">11</div>
                  </FormGroup>
                </div>
              </div>
            )}

            {values.category === "authentication" && values.authButton && values.category !== 'limited' && (
              <div className="mt-2">
                <div className="actionsBtns">
                  <Label className="mt-1 ml-3">Button</Label>
                  <FormGroup className="mr-2 col-md-5">
                    <Field
                      type="text"
                      id="2"
                      name="authtextButton"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        restrictText(event, setFieldValue, 20);
                        setcharauthText(event?.target?.value?.length)
                      }}
                      // value={selectedOption==="catalogue"||values.catalogOption==="catalogue" ?"View catalog":"View items"}
                      className="form-control tempInputsBorder formcontrol"
                      placeholder="Ex: Interested, yes, no"
                      disabled={
                        type === "view" || values.category === "catalog_marketing"
                      }
                    />
                    <div className="float-right text-muted small">{authTextlength}/20</div>
                  </FormGroup>
                  <ErrorMessage
                    name="authtextButton"
                    component="div"
                    className="text-danger pt-1"
                  />
                </div>
              </div>
            )}
            {values.btnOptions.includes("action") ? (
              <>
                <div className="actionBtns mt-3">
                  <FormGroup>
                    <Label className="mt-2">
                      <Field
                        type="checkbox"
                        name="phoneChecked"
                        checked={values.phoneChecked}
                        onChange={handleChange}
                        className="mr-2"
                        disabled={type === "view"}
                      />
                      <span className="mr-2">Phone</span>
                    </Label>
                  </FormGroup>
                  <FormGroup className="">
                    <Field
                      type="text"
                      id="phoneInput1"
                      name="phoneInput1"
                      style={{
                        backgroundColor: "transparent",
                      }}
                      placeholder="Ex: Call us"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        restrictText(event, setFieldValue, 26);
                        setCharphone(event.target.value.length);
                      }}
                      className="form-control formcontrol tempInputsBorder"
                      disabled={type === "view" || !values.phoneChecked}
                    />
                    <span className="float-right small text-muted">
                      {charphone} / 26
                    </span>
                    <ErrorMessage
                      name="phoneInput1"
                      component="div"
                      className="text-danger pt-1"
                    />
                  </FormGroup>
                  <div className="form-group mobile-login-flag col-md-6.5 ml-2">
                    <div className="input-group ">
                      <div className="input-group-prepend ml-2">
                        <FlagInput
                          countryCode={countrycode}
                          codeChange={setcode}
                        />
                        {/* {type === 'view'  || type === 'edit' || type === 'create' ? '+1' : '+91'} */}
                      </div>
                      <Field
                        type="text"
                        name="phoneInput2"
                        id="phoneInput2"
                        style={{
                          backgroundColor: "transparent",
                        }}
                        className="form-control tempInputs formcontrol"
                        placeholder="Ex: 9988776655"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value.replace(/[^\d]/g, "");
                          if (value.length < 21) {
                            setFieldValue("phoneInput2", value);
                            setCountnum(value.length);
                          }
                        }}
                        disabled={
                          type === "view" ||
                          !values.phoneChecked ||
                          values.phoneInput1 === ""
                        }
                      />
                    </div>
                    <span className="float-right small text-muted">
                      {charnum} / 20
                    </span>

                    <ErrorMessage
                      name="phoneInput2"
                      component="div"
                      className="text-danger pt-1"
                    />
                  </div>
                </div>
                <h6 className="mr-2 font-size-small">CTA-URL</h6>

                <div className="headerOptions urlBtns mt-3">
                  <FormGroup>
                    <Label className="mt-2">
                      <Field
                        type="checkbox"
                        name="webUrlChecked"
                        checked={values.webUrlChecked}
                        onChange={handleChange}
                        className="mr-2 formcontrol"
                        disabled={type === "view"}
                      />
                      <span className="mr-2">URL</span>
                    </Label>
                  </FormGroup>
                  <div className="row">
                    <div className="col-md-12 urlBtns">
                      <div className="col-md-4">
                        <FormGroup className="mr-2 formcontrol">
                          <Field name="webUrlDropdown">
                            {({ field, form }: any) => (
                              <Select
                                {...field}
                                options={webUrlOptions}
                                onChange={(selectedOption: SelectBtnOption) => {
                                  form.setFieldValue(
                                    "webUrlDropdown",
                                    selectedOption?.value
                                  );
                                  form.setFieldValue("variable", "");
                                  form.setFieldValue("websiteName", "");
                                  form.setFieldValue("webUrl", "");
                                }}
                                value={webUrlOptions.find(
                                  (option) => option.value === field.value
                                )}
                                isDisabled={
                                  type === "view" || !values.webUrlChecked
                                }
                                styles={customSelect}
                              // Set the value prop correctly
                              />
                            )}
                          </Field>
                        </FormGroup>
                      </div>
                      <FormGroup className="mr-2 col-md-3">
                        <Field
                          type="text"
                          id="websiteName"
                          name="websiteName"
                          className="form-control tempInputsBorder formcontrol"
                          disabled={type === "view" || !values.webUrlChecked}
                          placeholder="Ex: Visit us"
                          style={customSelectLimit}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            restrictText(event, setFieldValue, 25);
                            setUrlCount(event.target.value.length);
                          }}
                        />
                        <span className="float-right small text-muted">
                          {urlCount} / 25
                        </span>
                        <ErrorMessage
                          name="websiteName"
                          component="div"
                          className="text-danger pt-1"
                        />
                      </FormGroup>
                      <div className="col-md-5">
                        <FormGroup className="mr-2">
                          <div>
                            <Field
                              type="text"
                              id="webUrl"
                              name="webUrl"
                              style={customSelectLimit}
                              className="form-control tempInputsBorder formcontrol "
                              disabled={type === "view" || !values.webUrlChecked}
                              placeholder={
                                values?.webUrlDropdown === "staticUrl"
                                  ? "Ex: http:websitename.com"
                                  : "Ex: http:websitename.com/{{1}}"
                              }
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                if (values?.webUrl?.includes("{{1}}")) {
                                  restrictText(
                                    event,
                                    setFieldValue,
                                    values.webUrl.length
                                  );
                                } else {
                                  restrictText(event, setFieldValue, 2000);
                                  setwebCount(event.target.value.length);
                                }
                              }}
                            />
                            <span className="float-right small text-muted">
                              {weburlCount} / 2000
                            </span>

                            <ErrorMessage
                              name="webUrl"
                              component="div"
                              className="text-danger pt-1"
                            />
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  {/* {values.webUrlDropdown === "dynamicUrl" &&
                !values.webUrl.includes("{{1}}") ? (
                  <>
                    <Button
                      className="addbtn float-right"
                      type="button"
                      onClick={() => {
                        if (!values.webUrl.includes("{{1}}")) {
                          setFieldValue(
                            "webUrl",
                            values.webUrl ? values.webUrl + "{{1}}" : ""
                          );
                        }
                      }}
                    >
                      {" "}
                      Add Variable{" "}
                    </Button>
                  </>
                ) : (
                  ""
                )} */}
                </div>
                <div className="offset-md-3">
                  {values.webUrlDropdown === "dynamicUrl" ? (
                    <FormGroup>
                      <div className="d-flex var-align">
                        <span className="mr-2 text-dark mt-2 dynamic-variable">
                          {"Sample Variable:"}
                        </span>
                        <Field
                          type="text"
                          id="variable"
                          name="variable"
                          className="form-control col-6"
                          placeholder="Content for variable"
                          disabled={!values.webUrl || type === "view"}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            restrictText(event, setFieldValue, 25);
                            setVarCount(event.target.value.length);
                            if (!values.webUrl.includes("{{1}}")) {
                              setFieldValue(
                                "webUrl",
                                values.webUrl ? values.webUrl + "{{1}}" : "{{1}}"
                              );
                            }
                          }}
                        />
                      </div>
                      <span className="offset-md-8 text-muted small mt-2">
                        {" "}
                        {varCount}/ 25
                      </span>
                      <ErrorMessage
                        name="variable"
                        component="div"
                        className="text-danger pt-1 offset-md-5 col-4"
                      />
                    </FormGroup>
                  ) : (
                    ""
                  )}
                </div>
                {values.webUrlChecked ? (
                  <div className="headerOptions urlBtns mt-3">
                    <FormGroup>
                      <Label className="mt-2">
                        <Field
                          type="checkbox"
                          name="webUrlChecked1"
                          checked={values.webUrlChecked1}
                          onChange={handleChange}
                          className="mr-2 formcontrol"
                          disabled={type === "view"}
                        />
                        <span className="mr-2">URL</span>
                      </Label>
                    </FormGroup>
                    <div className="row">
                      <div className="col-md-12 urlBtns">
                        <div className="col-md-4">
                          <FormGroup className="mr-2 formcontrol">
                            <Field name="webUrlDropdown1">
                              {({ field, form }: any) => (
                                <Select
                                  {...field}
                                  options={webUrlOptions1}
                                  onChange={(selectedOption: SelectBtnOption) => {
                                    form.setFieldValue(
                                      "webUrlDropdown1",
                                      selectedOption?.value
                                    );
                                    form.setFieldValue("variable1", "");
                                    form.setFieldValue("websiteName1", "");
                                    form.setFieldValue("webUrl1", "");
                                  }}
                                  value={webUrlOptions1.find(
                                    (option) => option.value === field.value
                                  )}
                                  isDisabled={
                                    type === "view" || !values.webUrlChecked1
                                  }
                                  styles={customSelect}
                                // Set the value prop correctly
                                />
                              )}
                            </Field>
                          </FormGroup>
                        </div>
                        <FormGroup className="mr-2 col-md-3">
                          <Field
                            type="text"
                            id="websiteName1"
                            name="websiteName1"
                            className="form-control tempInputsBorder formcontrol"
                            disabled={type === "view" || !values.webUrlChecked1}
                            placeholder="Ex: Visit us"
                            style={{
                              position: "relative",
                              left: "-25px",

                              width: "170px",
                              backgroundColor: values?.webUrlChecked
                                ? "white"
                                : "transparent",
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              restrictText(event, setFieldValue, 25);
                              setUrlCount1(event.target.value.length);
                            }}
                          />
                          <span className="float-right small text-muted ml-2">
                            {urlCount1} / 25
                          </span>
                          <ErrorMessage
                            name="websiteName1"
                            component="div"
                            className="text-danger pt-1"
                          />
                        </FormGroup>
                        <div className="col-md-5">
                          <FormGroup className="mr-2">
                            <div>
                              <Field
                                type="text"
                                id="webUrl1"
                                name="webUrl1"
                                className="form-control tempInputsBorder formcontrol"
                                style={{
                                  backgroundColor: values?.webUrlChecked1
                                    ? "white"
                                    : "transparent",
                                }}
                                disabled={
                                  type === "view" || !values.webUrlChecked1
                                }
                                placeholder={
                                  values?.webUrlDropdown1 === "staticUrl"
                                    ? "Ex: http:websitename.com"
                                    : "Ex: http:websitename.com/{{1}}"
                                }
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  if (values?.webUrl1?.includes("{{1}}")) {
                                    restrictText(
                                      event,
                                      setFieldValue,
                                      values.webUrl1.length
                                    );
                                  } else {
                                    restrictText(event, setFieldValue, 2000);
                                    setwebCount1(event.target.value.length);
                                  }
                                }}
                              />
                              <span className="float-right small text-muted">
                                {weburlCount1} / 2000
                              </span>
                              <ErrorMessage
                                name="webUrl1"
                                component="div"
                                className="text-danger pt-1"
                              />
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    {/* {values.webUrlDropdown === "dynamicUrl" &&
                !values.webUrl.includes("{{1}}") ? (
                  <>
                    <Button
                      className="addbtn float-right"
                      type="button"
                      onClick={() => {
                        if (!values.webUrl.includes("{{1}}")) {
                          setFieldValue(
                            "webUrl",
                            values.webUrl ? values.webUrl + "{{1}}" : ""
                          );
                        }
                      }}
                    >
                      {" "}
                      Add Variable{" "}
                    </Button>
                  </>
                ) : (
                  ""
                )} */}
                  </div>
                ) : (
                  ""
                )}
                <div className="offset-md-3">
                  {values.webUrlDropdown1 === "dynamicUrl" &&
                    values.webUrlChecked ? (
                    <FormGroup>
                      <div className="d-flex var-align">
                        <span className="mr-2 text-dark mt-2 dynamic-variable">
                          {"Sample Variable :"}
                        </span>
                        <Field
                          type="text"
                          id="variable1"
                          name="variable1"
                          className="form-control col-6"
                          placeholder="Content for variable"
                          disabled={!values.webUrl1 || type === "view"}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            restrictText(event, setFieldValue, 25);
                            setVarCount(event.target.value.length);
                            if (!values.webUrl1.includes("{{1}}")) {
                              setFieldValue(
                                "webUrl1",
                                values.webUrl1 ? values.webUrl1 + "{{1}}" : ""
                              );
                            }
                          }}
                        />
                      </div>
                      <span className="offset-md-8 text-muted small mt-2">
                        {" "}
                        {varCount}/ 25
                      </span>
                      <ErrorMessage
                        name="variable1"
                        component="div"
                        className="text-danger pt-1 offset-md-5 col-4"
                      />
                    </FormGroup>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            {values.btnOptions.includes("quickReply") ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="replyButtonsDroppable">
                  {(provided: DroppableProvided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {values.replyButtons.slice(0, buttonCount).map(
                        (
                          button: {
                            label: string;
                            name: string;
                            id: string;
                            value: string;
                          },
                          ind: number
                        ) => (
                          <Draggable
                            key={button.id.toString()}
                            draggableId={button.id.toString()}
                            index={ind}
                            isDragDisabled={type === "view"}
                          >
                            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="actionBtns mt-1"
                              // style={{
                              //   ...provided.draggableProps.style,
                              //   backgroundColor: snapshot.isDragging ? "#e0e0e0" : "#fff",
                              //   padding: "8px",
                              //   marginBottom: "10px",
                              //   border: "1px solid #ccc",
                              // }}
                              >
                                <RxDragHandleDots2 size={20} className="mt-1 mr-2" />
                                <Label className="mt-2 mr-2">{button.label}</Label>
                                <FormGroup className="mr-2">
                                  <Field
                                    type="text"
                                    id={button.id}
                                    name={button.name}
                                    value={button.value}
                                    className="form-control tempInputsBorder formcontrol"
                                    placeholder="Ex: Interested,yes,no"
                                    onChange={(e: any) => {
                                      handleReplyButtons(e.target.value, ind);
                                      handleInputChange(e.target.value, ind);
                                    }}
                                    disabled={type === "view"}
                                  />
                                  <div className="float-right text-muted small">
                                    {values?.replyButtons[ind]?.value?.length} / 25
                                  </div>
                                </FormGroup>
                                <div className="addRemoveIcon">
                                  {type !== "view" && (
                                    <>
                                      {values.replyButtons.length < buttonCount ? (
                                        <span
                                          onClick={() => {
                                            addButtons(ind + 2);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlusCircle}
                                            className="addIcon"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {values.replyButtons.length !== 1 ? (
                                        <span onClick={() => removeReplyButton(ind)}>
                                          <FontAwesomeIcon
                                            icon={faTimesCircle}
                                            className="removeIcon"
                                          />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {values.btnOptions.includes("action") && values?.category !== 'carousel' && values?.category !== 'limited' && (
          <ErrorMessage
            name="callToAction"
            component="div"
            className="text-danger pt-1"
          />
        )}
        {values.btnOptions.includes("quickReply") && values?.category !== 'carousel' && values?.category !== 'limited' && (
          <ErrorMessage
            name="replyButtons"
            component="div"
            className="text-danger pt-1"
          />
        )}

        {values?.category == 'limited' && (
          <>
          <h6 className="mt-3">Limited Time Offer<span className="required"></span>
          <span className="ml-1 mt-2">
                    <TooltipReference
                      placement="right"
                      icon={faInfoCircle}
                      content="Create buttons that let customers respond to your message or take action."
                      tooltipId="footer"
                    />
                  </span></h6>
          <div className="headerbox">
            <FormGroup>
                <Field
                        type="text"
                        id="limitedText"
                        name="limitedText"
                        className="form-control formcontrol ml-0"
                        disabled={
                          type === "view"
                        }
                        placeholder="Enter text for limited time offer"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("limitedText", e.target.value);
                          restrictText(e, setFieldValue, 16);
                          setLimitCount(e.target.value?.length);
                        }}
                      />
                      <ErrorMessage
                        name="limitedText"
                        component="div"
                        className="text-danger pt-1"
                      />
                       <div className="float-right text-muted small mb-2">{limitCount}/16</div>
            </FormGroup>
            <FormGroup>
                <Field
                        type="text"
                        id="limitedCode"
                        name="limitedCode"
                        className="form-control formcontrol"
                        disabled={
                          type === "view"
                        }
                        placeholder="Enter text for coupon code"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("limitedCode", e.target.value);
                          restrictText(e, setFieldValue, 15);
                          setCodeCount(e.target.value?.length);
                        }}
                      />
                      <div className="float-right text-muted small mb-1"> {codeCount}/15 </div>
                      <ErrorMessage
                        name="limitedCode"
                        component="div"
                        className="text-danger pt-1"
                      />
            </FormGroup> 
            <br/>
            <div className="d-flex">
            <FormGroup className="formcontrol">
                          <Field name="limitDropDown">
                            {({ field, form }: any) => (
                              <Select
                                {...field}
                                options={webUrlOptions}
                                onChange={(selectedOption: SelectBtnOption) => {
                                  form.setFieldValue(
                                    "limitDropDown",
                                    selectedOption?.value
                                  );
                                  form.setFieldValue("variable", "")
                                }}
                                value={webUrlOptions.find(
                                  (option) => option.value === field.value
                                )}
                                isDisabled={
                                  type === "view" 
                                }
                                styles={customSelectLimit}
                              />
                            )}
                    </Field>
            </FormGroup>
                <Field
                        type="text"
                        id="limitedUrl"
                        name="limitedUrl"
                        className="form-control ml-2 "
                        disabled={
                          type === "view"
                        }
                        placeholder="Enter url"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("limitedUrl", e.target.value);
                          restrictText(e, setFieldValue, 200);
                          setLimitUrl(e.target.value?.length);
                        }}
                      />
            </div>
            <div className="float-right text-muted small mb-1 mt-0"> {UrlCount} / 200 </div>
            <ErrorMessage
                        name="limitedUrl"
                        component="div"
                        className="offset-md-3 text-danger "
                  />
              {values.limitDropDown === "dynamicUrl" &&
                    <FormGroup className="offset-md-2">
                      <div className="d-flex var-align">
                        <span className="mr-2 text-dark mt-2 dynamic-variable">
                          {"Sample Variable :"}
                        </span>
                        <Field
                          type="text"
                          id="limitVar"
                          name="limitVar"
                          className="form-control col-6"
                          placeholder="Content for variable"
                          disabled={type === "view"}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            restrictText(event, setFieldValue, 25);
                            setLimitSample(event.target.value?.length);
                            if (!values.limitedUrl.includes("{{1}}")) {
                              setFieldValue(
                                "limitedUrl",
                                values.limitedUrl ? values.limitedUrl + "{{1}}" : ""
                              );
                            }
                          }}
                        />
                      </div>
                       <div className="offset-md-6 text-muted small mb-1"> {sampleCount}/200 </div>
                      <ErrorMessage
                        name="limitVar"
                        component="div"
                        className="text-danger  col-4"
                      />
                      
                    </FormGroup>
                }
            <FormGroup>
                <Field
                        type="text"
                        id="limitedButton"
                        name="limitedButton"
                        className="form-control formcontrol"
                        disabled={
                          type === "view"
                        }
                        placeholder="Enter text for the button"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("limitedButton", e.target.value);
                          restrictText(e, setFieldValue, 25);
                          setLimitButton(e.target.value?.length);
                        }}
                      />
                       <div className="float-right text-muted small"> {limitBtnCount}/25 </div>
                      <ErrorMessage
                        name="limitedButton"
                        component="div"
                        className="text-danger pt-1"
                      />
            </FormGroup>
          </div>
          </>
        )}
        {((values.webUrlChecked && values?.category !== 'carousel')) && (
          <div className="headerbox">
            <FormGroup>
              <Label className="mt-2 ml-2">
                <Field
                  type="checkbox"
                  name="trackLinks"
                  checked={values.trackLinks}
                  onChange={handleChange}
                  className="mr-2 formcontrol"
                  disabled={type === "view"}
                />
                <span className="mr-2">
                  Track link clicks ( Enable this to see URL click insights under
                  broadcast and message trackers)
                </span>
              </Label>
            </FormGroup>
          </div>
        )}

        {values?.category === 'carousel' &&
          <>
            <div className="headerbox">
              <Label className="m-0 h6">Carousel Format</Label>
              <p className="small">The media header format  and button type must be the same across all cards that make up a carousel template.</p>
              <div className="d-flex mb-2">
                <Label className="col-2 d-flex">Carousel Type {' '}<span className="required" /></Label>
                <Field name="carouselType">
                  {({ field, form }: any) => (
                    <>
                      <Select
                        {...field}
                        options={carouselType.map((option) => ({
                          ...option,
                        }))}
                        onChange={(selectedOption: SelectBtnOption) => {

                          form.setFieldValue(
                            "carouselType",
                            selectedOption?.value
                          );
                        }}
                        value={carouselType.find(
                          (option) => option.value === field.value
                        ) || null}
                        className="col-4"
                        isDisabled={items.length > 0 || type === 'view'}
                      ></Select>
                      <ErrorMessage
                        name="carouselType"
                        component="div"
                        className="text-danger pt-1"
                      />
                    </>
                  )}
                </Field>
              </div>
              <div className="d-flex mb-2">
                <Label className="col-2">Button Type <span className="required" /></Label>
                <Field name="buttonOne">
                  {({ field, form }: any) => (
                    <>
                      <Select
                        {...field}
                        options={buttonTypes.map((option) => ({
                          ...option,
                        }))}
                        onChange={(selectedOption: SelectBtnOption) => {

                          form.setFieldValue(
                            "buttonOne",
                            selectedOption?.value
                          );
                        }}
                        value={buttonTypes.find(
                          (option) => option.value === field.value
                        ) || null}
                        className="col-4"
                        isDisabled={items.length > 0 || type === 'view'}
                      ></Select>
                      <ErrorMessage
                        name="buttonOne"
                        component="div"
                        className="text-danger pt-1"
                      />
                    </>
                  )}
                </Field>
              </div>
              <div className="d-flex">
                <Label className="col-2">Button Type</Label>
                <Field name="buttonTwo">
                  {({ field, form }: any) => (
                    <Select
                      {...field}
                      options={buttonTypes.map((option) => ({
                        ...option,
                      }))}
                      onChange={(selectedOption: SelectBtnOption) => {

                        form.setFieldValue(
                          "buttonTwo",
                          selectedOption?.value
                        );
                      }}
                      value={buttonTypes.find(
                        (option) => option.value === field.value
                      ) || null}
                      className="col-4"
                      isDisabled={items.length > 0 || type === 'view'}
                    ></Select>
                  )}
                </Field>
              </div>
            </div>
            {items?.map((item, ind) => (
              <div key={item.id} className=" mb-2 headerbox">
                <div className="d-flex">
                  <div className="mr-3">
                    {items[ind]?.card?.components[0]?.format === 'IMAGE' ?
                      <img src={type !== 'create' ? items[ind]?.card?.components && items[ind]?.card?.components[0]?.example.header_handle[0] ? items[ind]?.card?.components[0]?.example.header_handle[0] : '' : media[ind]?.url ? media[ind]?.url : ''} width={60} height={60} className="rounded"></img>
                      : items[ind]?.card?.components[0]?.format === 'VIDEO' ?
                        <video src={type !== 'create' ? items[ind]?.card?.components && items[ind]?.card?.components[0]?.example.header_handle[0] ? items[ind]?.card?.components[0]?.example.header_handle[0] : '' : media[ind]?.url ? media[ind]?.url : ''} width={60} height={60} className="rounded"></video> : ''}
                  </div>
                  <div>
                    <span title={items[ind]?.card?.components[1]?.text} className="bodyCarousel">{items[ind]?.card?.components && items[ind]?.card?.components[1] ? (items[ind]?.card?.components[1]?.text?.length > 70 ? items[ind]?.card?.components[1]?.text.slice(0, 69) + '...' : items[ind]?.card?.components[1]?.text) : ''}</span>
                    <div className="pt-1 d-flex">
                      {items[ind]?.card?.components[2]?.buttons?.map((ele: any, buttonIndex: number) => (
                        <p key={buttonIndex} className="quickbtnscarousel m-1 text-center" title={ele?.text}>
                          {ele?.type === 'QUICK_REPLY' ? '' : ele?.type === 'PHONE_NUMBER' ? <img src={phone} width={20} /> : ele?.type === 'URL' ? <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1" /> : ''}
                          {ele?.text?.length > 15 ? ele?.text?.slice(0, 14) + '...' : ele?.text}</p>
                      ))}
                    </div>
                  </div>
                </div>
                <div className=" editDeleteIcon">
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="iconColor mr-3 iconFontSize text-muted"
                    onClick={() => handleEdit(item.index)}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="iconColor mr-1 iconFontSize text-muted"
                    onClick={() => {
                      if (type !== "view") {
                        handleDelete(item.index, ind)
                      }
                    }}
                  />
                </div>
              </div>
            ))}
            <div className="d-flex">
              {items.length < 10 &&
                <>
                  <Button type='button' className="sendButton w-25 text-center" disabled={items.length >= 10 || !values.carouselType || !values.buttonOne} onClick={handleOpenModal}>+Add Carousel Card </Button>
                  <span className="ml-1 mt-2">
                    <TooltipReference
                      placement="right"
                      icon={faInfoCircle}
                      content="Carousel template allowed up to 10 carousel cards."
                      tooltipId="footer"
                    />
                  </span>
                </>
              }

              {items.length >= 10 && <TooltipReference
                placement="bottom"
                content={
                  items.length >= 10 ? "Only 10 cards are allowed in Carousel template." : ""
                }
                tooltipId="source"
              >  <Button className="sendButton w-100 text-center" disabled={items.length >= 10} type='button'>+Add Carousel Card </Button>
              </TooltipReference>}
              {!items.length && <Label className="text-danger pt-2 pl-2">Atleast add 1 card.</Label>}
            </div>
            {openModal && (
              <Modall
                isOpen={openModal}
                onClose={handleClose}
                title={"Carousel Card"}
                size={'xl'}
                closeOnOutsideClick={false}
              >
                <div>
                  <Carousel
                    value={values}
                    // setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    type={type}
                    handleAddItem={handleAddItem}
                    restrictText={restrictText}
                    closeModal={handleCloseModal}
                    images={media}
                    temValues={items}
                    item={selectedItem}
                    edit={edit}
                    countryArr={countryArr}
                  />
                </div>
              </Modall>
            )}
          </>
        }
      </div>
    );
  };

export default TemplateBody;
