import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { contactCreateOnConv, getAllContacts } from "../../services/conversationservice";
import "./conversation.scss";
import { getConvBasedOnContact } from "../../services/conversationservice";
import { Button } from "react-bootstrap";
import AddContact from "../Contacts/addContacts";
import { InputGroup } from "react-bootstrap";
import SearchInput from '../../common/searchComponent';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { processMobileNumber } from "../../common/commonfns";
import SwitchButton from "../Contacts/SwitchButton";
import { Label } from "reactstrap";
import { ChannelUid } from "../../redux/actions/action";
import { sliceText } from "../../common/sliceText";
import TooltipReference from "../../common/tooltip";


type Contact = {
  uid: string;
  name: string;
  countryCode?: string;
  mobileNumber: string;
  // ... any other properties ...
};

type ContactsProps = {
  handleClose: () => void;
  getSingleConversation?: (conv: any) => void;
  status?: string;
  EditCommunityList?: any;
  addNewContact?: (addContact: any) => void;
  getConvFromContact?: () => void;
  contactType?: string;
  onSelect?: (_: Contact) => void;
  noCaption?: boolean;
  isInvalidAccess?: boolean;
}
interface Payload {
  uid?: string;
  channelId?: string;
  limit: number;
  page: number;
  conversationType: string;
  search?: string;
}
interface searchPayload {
  uid?: string;
  channelId?: string;
  limit: number;
  page: number;
}

const Contacts: React.FC<ContactsProps> = ({
  handleClose,
  getSingleConversation,
  status,
  EditCommunityList,
  addNewContact,
  getConvFromContact,
  contactType,
  onSelect, noCaption,
  isInvalidAccess
}) => {
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );
 
  const [contacts, setContacts] = useState<any[]>([]);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [addContact, setAddContact] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const contactsListRef = useRef<HTMLDivElement>(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchPgNo, setsearchPgNo] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchContactsConv, setSearchContacts] = useState<any[]>([]);
  const [notFound, setNotFound] = useState(false);
  const [isChannel, isSetChannel] = useState(false);

  var hasMoreContacts = true;
  const activeChannel = useSelector((state: any) => state.cartreducer.channelUid);

  useEffect(() => {
    loadContacts();
  }, [pageNumber, businessId,isChannel,activeChannel])

  useEffect(() => {
    if (searchTerm) {
      searchContacts();
    }
  }, [searchPgNo, businessId])

  const loadContacts = () => {
    
    if (businessId) {
      // var payload: Payload = {
      //   limit: status === 'CAMPAIGN' ? 10000 : 15,
      //   page: pageNumber,
      //   conversationType: contactType === 'supportInbox' ? 'ACCOUNT' : 'LEAD'
      let payload = {};
      // };

      if (
        status === 'CONVERSATION'
        || status === 'initiateConversation'
        || status === 'SELECT'
      ) {
        payload = {
          uid: businessId,
          channelId: isChannel === true ? '' : activeChannel?.value,
          limit: 20, 
          page: pageNumber,
          conversationType: contactType === 'supportInbox' ? 'ACCOUNT' : 'LEAD'
        }
      }
      else if (status === 'CAMPAIGN') {
        payload = {
          uid: businessId,
          limit: 100,
          page: 0,
        }
      }
      else {
        payload = {
          uid: businessId,
          limit: 20,
          page: pageNumber,
          conversationType: contactType === 'supportInbox' ? 'ACCOUNT' : 'LEAD'
        };
      }
      getAllContacts(payload)
        .then((res) => {
          if (res.data.list.length) {
            if (status === 'CONVERSATION'
              || status === 'initiateConversation'
              || status === 'SELECT'
              || status === 'CAMPAIGN') {
                if(pageNumber===0){
                  setContacts(res.data.list)
                }
                else{
                  setContacts([ ...contacts,...res.data.list]);
                }
            }
            const getAll = res.data.list;
            const contact_id = EditCommunityList.flatMap(
              (contactList: any) => contactList.uid
            );
            const data = getAll.filter(
              (contactList: any) => !contact_id.includes(contactList.uid)
            );
            if (status === "CAMPAIGN") {
              setContacts([...contacts, ...data]);
            }
            hasMoreContacts = true;
          }
          else {
            hasMoreContacts = false;
          }
        })
        .catch((err) => { });
    }
  }

  useEffect(() => {
    if (contactsListRef.current) {
      contactsListRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (searchTerm) {
      searchContacts();
    }
    else {
      setSearchContacts([]);
    }
  }, [searchTerm]);

  const searchContacts = () => {
    // var searchPayload: Payload = {
    //   limit: 15,
    //   page: pageNumber,
    //   conversationType: contactType === 'supportInbox' ? 'ACCOUNT' : 'LEAD',
    //   search: searchTerm
    // };
    let searchPayload = {}

    if (status === 'CONVERSATION' || status === 'SELECT' || status === 'initiateConversation') {
      searchPayload = {
        uid: businessId,
        channelId: isChannel ? '' : activeChannel?.value,
        limit: 20,
        page: pageNumber,
        conversationType: contactType === 'supportInbox' ? 'ACCOUNT' : 'LEAD',
        search: searchTerm
      }
    }
    else if (status === 'CAMPAIGN') {
      searchPayload = {
        uid: businessId,
        limit: 1000,
        page: 0,
        search: searchTerm
      }
    }
    else {
      searchPayload = {
        uid: businessId,
        limit: 20,
        page: pageNumber,
        conversationType: contactType === 'supportInbox' ? 'ACCOUNT' : 'LEAD',
        search: searchTerm
      };
    }
    getAllContacts(searchPayload)
      .then((res) => {
        if (res.data.list.length) {
          if (status === 'CONVERSATION' || status === 'SELECT' || status === 'initiateConversation') {
            if (pageNumber === 0)
              setSearchContacts(res.data.list);
            else
              setSearchContacts([...searchContactsConv, ...res.data.list]);
          }

          const getAll = res.data.list;
          const contact_id = EditCommunityList.flatMap(
            (contactList: any) => contactList.uid
          );
          const data = getAll.filter(
            (contactList: any) => !contact_id.includes(contactList.uid)
          );
          if (status === "CAMPAIGN") {
            setContacts([...contacts, ...data]);
            if (pageNumber === 0)
              setSearchContacts(data);
            else
              setSearchContacts([...searchContactsConv, ...data]);
          }

          hasMoreContacts = true;
        }
        else {
          hasMoreContacts = false;
        }
      })
      .catch((err) => { });
  }

  const getSelectedContact = (contact: any) => {
    if (status === 'CONVERSATION' || status === 'initiateConversation') {
      const contactPayload = {
        uid: businessId,
        contactUid: contact?.uid,
        channelUid: activeChannel?.value,
        contactType: contactType === 'supportInbox' ? 'ACCOUNT' : 'LEAD',
        optin: contact?.optState
      };
      contactCreateOnConv(contactPayload, 'convFromContact').then((res: any) => {
        if (getSingleConversation) {
          getSingleConversation(res);
          if (getConvFromContact)
            getConvFromContact();
          handleClose();
        }
      });
    } else if (status === 'SELECT' && onSelect) {
      onSelect(contact);
    }
  };
  const createContact = (val: boolean) => {
    setAddContact(val);
  };

  const handleChange = (event: any, contact: number) => {
    const checkedData = event.target.checked;
    if (checkedData) {
      setIsChecked([...isChecked, contact] as any);
    } else {
      const updatedCheckedContacts = isChecked.filter(
        (selectedRow: any) => selectedRow !== contact
      );
      setIsChecked(updatedCheckedContacts as any);
    }
  };

  const addContactToCampaign = () => {
    if (addNewContact) {
      // const mergeContact = [...isChecked, ...EditCommunityList];
      const uids = isChecked.map((data: any) => data.uid);
      addNewContact(uids);
      handleClose();
    }
  };

  const handleContactsListScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (status !== 'CAMPAIGN') {
      const target = e.target as HTMLDivElement;
      if (Math.trunc(target.scrollHeight - target?.scrollTop) === target.clientHeight && hasMoreContacts) {
        if (searchTerm) {
          setsearchPgNo((prevpage) => prevpage + 1);
        }
        else {
          setPageNumber((prevpageno) => prevpageno + 1);
        }
      }
    }
  };

  const onSearchChange = (searchTerm: string) => {
    setPageNumber(0);
    setSearchTerm(searchTerm);
  }

  useEffect(() => {
   if(searchContactsConv.length===0)
    {
      setNotFound(true);
    }
    else{
      setNotFound(false);
    }
  
  }, [searchContactsConv]);
  const numberMaskString = localStorage.getItem('numberMask');
  const numberMask = numberMaskString === 'true';



  return (
    <React.Fragment>
      {addContact ? (
        <>
          <div className="float-right close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} />
          </div>
          <div className="ml-2">
            <span>
              <FontAwesomeIcon icon={faArrowLeft} onClick={() => createContact(false)} />
            </span>
            <span className="addContactHeader">Add Contact</span>
          </div>
          {status==='SELECT' ?(
            <AddContact
            component="appoinment"
            createContact={createContact}
            // fetchContacts={()=>loadContacts()}
            // getSingleConversation={getSingleConversation}
            handleClose={() =>{ 
              setAddContact(false)
              setPageNumber(0)
              loadContacts();

            }}
            // getConvFromContact={getConvFromContact}
            // contactType={contactType}
          ></AddContact>
          ):<AddContact
          component="conversation"
          createContact={createContact}
          getSingleConversation={getSingleConversation}
          handleClose={handleClose}
          getConvFromContact={getConvFromContact}
          contactType={contactType}
        ></AddContact>}
          
        </>
      ) : (
        <>
          {!noCaption ? (
            <>
            <div className="contactHeader">
              <div>
                <h6 className="pl-2">{status === "CAMPAIGN" ? 'Add More Contacts' :
                  status === 'SELECT' ? 'Select Contact' : 'New Conversation'}</h6>
              </div>
              <div>
                {status === "CAMPAIGN" ? (
                  isChecked.length > 0 ?
                    <Button
                      type="button"
                      className="sendButton"
                      onClick={addContactToCampaign}
                    >
                      Update
                    </Button>
                    : ''
                ) : (
                  <span className={`${isInvalidAccess ? "notAllowed" : ""}`} >
                  <Button
                    type="button"
                    className="sendButton createContactBtn"
                    onClick={() => createContact(true)}
                    disabled={numberMask || isInvalidAccess}
                  >
                    Create Contact
                  </Button>
                  </span>
                )}
              </div>
              <div className="contactClose cursor-pointer" onClick={handleClose}>
                <FontAwesomeIcon icon={faClose} />
              </div>
            </div>
      
          </>
          ) : null}
          <div className="row align-items-center">
          <div className={`${status === 'CAMPAIGN'  ? 'col-md-12' : channelData?.length < 2 ? 'col-md-12' : status === 'SELECT'?"col-md-12 mb-2":  'col-md-6 pe-1'}`}>
          <SearchInput component="conversation" onSearchChange={onSearchChange}
          placeHolder="Search Contacts"
          />
          </div>
          {( (status === 'CONVERSATION'
        || status === 'initiateConversation'
        || status === 'SELECT' )&& (channelData?.length > 1))&&
              (
            <div className={status === 'SELECT'?"col-md-7 ml-4 ": "col-md-6"} style={{gap:'10px'}}>
                                      <input
                                type="checkbox"
                                className="form-check-input"
                                id='isNotChannel'
                                onClick={()=>{isSetChannel(!isChannel);setPageNumber(0)}}
                                checked={isChannel}
                              />
              <Label htmlFor='isNotChannel' className="form-check-label ms-2">
              Show all channel contacts
              </Label>
            </div>
        )}
          {/* <div className={`${status === 'CAMPAIGN' ? 'col-md-12' :'col-md-10 ml-4 mb-3'}`}>
          <SearchInput component="conversation" onSearchChange={onSearchChange}
          placeHolder="Search Contacts"
          />
          </div> */}
        
        </div>
          <hr />
          {/* <div className="contactsList" ref={contactsListRef} onScroll={handleContactsListScroll}> */}
          {searchTerm ?
            <div className="contactsList" ref={contactsListRef} onScroll={handleContactsListScroll}>
              {searchContactsConv.length > 0 ? (
  searchContactsConv.map((contact, index: number) => {
    const isSelected = selectedContact && contact.uid === selectedContact.uid;
    return (
      <>
      <div
        className={`contactPanel ${isSelected ? "selected" : ""}`}
        key={index}
        style={{ cursor: status === 'CAMPAIGN' ? 'auto' : 'pointer' }}
        onClick={() => getSelectedContact(contact)}
      >
        <div className="d-flex ">
          {status === "CAMPAIGN" && (
            <input
              type="checkbox"
              checked={isChecked.some((data: any) => data?.id === contact.id)}
              onChange={(e) => handleChange(e, contact)}
            />
          )}
          <div className="contacts ms-2">
            {contact.name[0].toUpperCase()}
          </div>
          <div className="mt-1">
          {
          contact?.name?.length > 35 ? (
            <TooltipReference
              placement="right"
              content={contact.name}
              tooltipId={`contactNameForCAMP_${contact.uid}`}
            >
              {sliceText(contact.name, 35)}
            </TooltipReference>
          ) : (
            contact.name
          )
         }
          </div>
        </div>
        <div className={`${numberMask ? "numberMasking_Blur" :''}`}>
          {processMobileNumber(contact?.countryCode, contact?.mobileNumber)}
        </div>
      </div>
      {index !== searchContactsConv.length - 1 && <hr />}
      </>
    );
  })
) : (
  <>
  
  <div className="notFound"> No Contacts Found!</div>
  <hr/>
  </>
)}
            </div>
            :
            <div className="contactsList" ref={contactsListRef} onScroll={handleContactsListScroll}>
              {contacts &&
                contacts.map((contact, index: number) => {
                  const isSelected =
                    selectedContact && contact.uid === selectedContact.uid;
                  return (
                    <>
                      <div
                        className={`contactPanel ${isSelected ? "selected" : ""}`}
                        key={index}
                        style={{ cursor: status === 'CAMPAIGN' ? 'auto' : 'pointer' }}
                        onClick={() => getSelectedContact(contact)}
                      >
                        <div className="d-flex ">
                          {status === "CAMPAIGN" && (
                            <input
                              type="checkbox"
                              checked={isChecked.some((data: any) => data?.id === contact.id)}
                              onChange={(e) => handleChange(e, contact)}
                            />
                          )}
                          <div className="contacts ms-2">
                            {contact.name[0].toUpperCase()}
                          </div>
                          <div className="mt-1">
                          {
                      contact?.name?.length > 35 ? (
                        <TooltipReference
                          placement="right"
                          content={contact.name}
                          tooltipId={`contactNameForConv_${contact.uid}`}
                        >
                          {sliceText(contact.name, 35)}
                        </TooltipReference>
                      ) : (
                        contact.name
                      )
                    }
                        </div>
                        </div>
                        <div className={`${numberMask ? "numberMasking_Blur" :''}`}>
                          {/* {contact.mobileNumber} */}
                          {contact?.countryCode ? "+" + contact?.countryCode + " " + contact?.mobileNumber.slice(contact?.countryCode?.length)
                            : contact?.mobileNumber.slice(contact?.countryCode?.length)}
                        </div>
                      </div>
                      {index !== contacts.length - 1 && <hr />}
                    </>
                  );
                })}
            </div>
          }
        </>
      )}
    </React.Fragment>
  );
};

Contacts.defaultProps = { noCaption: false };

export default Contacts;
