import React, { useState, useEffect } from "react";
import "./template.scss";
import whatsapplogo from "../../whatsapp/image/whatsapplogo.svg";
import paperplane from "../../whatsapp/image/paperplane.png";
import phone from "../../assets/img/phonesymbol.png";
import "./template.scss";
import { sliceText } from "../../common/sliceText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faExternalLinkAlt, faList, faReply } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import MapLoader from "../../common/MapLoader";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
// import whatsapplogo from './whatsapp/image/paperplane.png'

interface TemplatePreviewprops {
  values: any;
  files: any;
  component: any;
  type: any;
  carouselData: any;
}

const TemplatePreview: React.FC<TemplatePreviewprops> = ({
  values,
  files,
  component,
  type,
  carouselData,
}) => {
  // console.log("values",values);
  const channels = useSelector((state: any) => state.cartreducer.channelUid);
  const extractVariableName = (text: string) => {
    // Use regular expressions to extract the variable name within {{}}
    const regex = /{{(.*?)}}/g;
    const matches = text?.match(regex);
    if (matches) {
      return matches.map((match: string) => match.replace(/{{|}}/g, ""));
    }
    return [];
  };
// console.log("car", carouselData)
  const wordsExtractedFromSymbols = (symbol: string, text: string) => {
    var regex;
    if (symbol === "*") {
      regex = /\*(.*?)\*/g;
    } else if (symbol === "_") {
      regex = /_([^_]+)_/g;
    } else {
      regex = /~([^~]+)~/g;
    }
    const extractedWords = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      extractedWords.push(match[1]);
    }
    return extractedWords;
  };
  const [buttonCount, setButtonCount] = useState(0);
  const [replybuttonCount, setReplyButtonCount] = useState(0);

  // console.log("c",buttonCount);
  

  useEffect(() => {
    updateButtonCount();
    updateReplyButtonCount();
  }, [values]);
  

  const updateButtonCount = () => {
    let count = 0;
    if (values?.phoneChecked &&values?.btnOptions.includes("action")) count += 1;
    if (values?.webUrlChecked && values?.btnOptions.includes("action"))
      count += 1;
    if (values?.webUrlChecked1 &&values?.btnOptions.includes("action"))
      count += 1;
    if (values?.category==="coupon_marketing")
      count+=1;
    count += values?.replyButtons?.slice(0, 7)?.length;
    setButtonCount(count);
  };
  const updateReplyButtonCount=()=>{
    let replyCount=2;
        if (values?.phoneChecked && values?.btnOptions.includes("action"))
          replyCount -= 1;
        if (values?.webUrlChecked && values?.btnOptions.includes("action"))
          replyCount -= 1;
        setReplyButtonCount(replyCount);
  }

  const renderMessageText = (values: any, text: string) => {
    const variableNames = extractVariableName(text);
    let renderedText = text;
    if (values?.addVariables?.length) {
      variableNames.forEach((variableName, index) => {
        const replacement = `<span class="sampleVarColor mt-2">${
          values.addVariables[index]?.value
            ? sliceText(values.addVariables[index].value, 10)
            : sliceText(variableName, 10)
        }</span>`;
        renderedText = renderedText.replace(`{{${variableName}}}`, replacement);
      });
    } else {
      variableNames.forEach((variableName, index) => {
        const replacement = `<span class="sampleVarColor mt-2">${sliceText(
          variableName,
          10
        )}</span>`;
        renderedText = renderedText.replace(`{{${variableName}}}`, replacement);
      });
    }
    if (renderedText?.includes("*")) {
      const boldWords = wordsExtractedFromSymbols("*", renderedText);
      boldWords.forEach((b) => {
        const replaceAsBold = `<span class="fontWeight">${b}</span>`;
        renderedText = renderedText.replace(`*${b}*`, replaceAsBold);
      });
    }
    if (renderedText?.includes("_")) {
      const italicWords = wordsExtractedFromSymbols("_", renderedText);
      italicWords.forEach((i) => {
        const replaceAsItalic = `<i>${i}</i>`;
        renderedText = renderedText.replace(`_${i}_`, replaceAsItalic);
      });
    }
    if (renderedText?.includes("~")) {
      const tildeWords = wordsExtractedFromSymbols("~", renderedText);
      tildeWords.forEach((t) => {
        const replaceAsTilde = `<span class="strikeThrough">${t}</span>`;
        renderedText = renderedText.replace(`~${t}~`, replaceAsTilde);
      });
    }
    // return (
    //     <div dangerouslySetInnerHTML={{ __html: renderedText }} />
    // );
    return (
      <div
        style={{ whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={{ __html: renderedText }}
      />
    );
  };
  const [scrollY, setScrollY] = useState(0);
  const scrollThreshold = 150;

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isFixed = scrollY >= scrollThreshold;

  const handleMapClick = (lat: any, long: any) => {
    const url = `https://www.google.co.in/maps/place/${lat},${long}`;
    window.open(url, "_blank");
  };

  return (
    <React.Fragment>
      {component === "createtem" ? (
        <div className={`previewImg ml-3 mt-4 ${isFixed ? "fixed" : ""}`}>
          <p className={`channelname ${isFixed ? "fixedchannelname" : ""}`}  title={channels?.label}>
            <span className="mr-1">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2884_12148)">
                  <path
                    d="M0.423595 9.53519C0.42313 11.2156 0.8622 12.8564 1.69708 14.3026L0.34375 19.2438L5.40049 17.9179C6.79912 18.6793 8.36618 19.0783 9.95863 19.0784H9.96282C15.2198 19.0784 19.4991 14.8006 19.5013 9.54271C19.5024 6.99488 18.511 4.59907 16.71 2.79659C14.9092 0.994264 12.5142 0.00116279 9.96243 0C4.70484 0 0.425843 4.27752 0.423672 9.53519"
                    fill="url(#paint0_linear_2884_12148)"
                  />
                  <path
                    d="M0.0829457 9.87721C0.0824031 11.6181 0.537209 13.3175 1.40186 14.8155L0 19.9339L5.23806 18.5605C6.68132 19.3474 8.30628 19.7622 9.95977 19.7629H9.96403C15.4096 19.7629 19.8426 15.3312 19.845 9.88512C19.8459 7.24574 18.8189 4.7638 16.9535 2.89674C15.0878 1.02992 12.6072 0.00108527 9.96403 0C4.51752 0 0.0851163 4.43101 0.0829457 9.87721ZM3.20233 14.5575L3.00674 14.2471C2.18457 12.9398 1.75062 11.4291 1.75124 9.87783C1.75302 5.35109 5.43705 1.66822 9.96713 1.66822C12.1609 1.66915 14.2226 2.52434 15.7733 4.07597C17.324 5.62775 18.1772 7.69054 18.1767 9.8845C18.1747 14.4112 14.4905 18.0946 9.96403 18.0946H9.96078C8.4869 18.0938 7.0414 17.698 5.78078 16.95L5.48078 16.7721L2.3724 17.5871L3.20233 14.5574V14.5575Z"
                    fill="url(#paint1_linear_2884_12148)"
                  />
                  <path
                    d="M7.49452 5.74801C7.30956 5.33692 7.11491 5.32863 6.93902 5.32142C6.79499 5.31522 6.63034 5.31568 6.46584 5.31568C6.30119 5.31568 6.03367 5.37762 5.80755 5.62452C5.58119 5.87165 4.94336 6.46886 4.94336 7.68351C4.94336 8.89824 5.82809 10.0721 5.95142 10.237C6.07491 10.4016 7.65941 12.974 10.1689 13.9636C12.2544 14.786 12.6789 14.6224 13.1315 14.5812C13.5842 14.5401 14.5923 13.9841 14.7979 13.4076C15.0037 12.8312 15.0037 12.3371 14.942 12.2338C14.8803 12.131 14.7157 12.0692 14.4688 11.9458C14.2218 11.8223 13.008 11.225 12.7817 11.1426C12.5554 11.0603 12.3908 11.0192 12.2262 11.2664C12.0615 11.5132 11.5887 12.0692 11.4446 12.2338C11.3006 12.3989 11.1565 12.4194 10.9097 12.2959C10.6627 12.172 9.86755 11.9117 8.92421 11.0706C8.19026 10.4162 7.69475 9.60808 7.55072 9.36088C7.40669 9.11405 7.5353 8.98026 7.6591 8.85723C7.77003 8.74661 7.90607 8.56894 8.02964 8.42483C8.15274 8.28064 8.19382 8.17777 8.27615 8.01312C8.35855 7.84832 8.31731 7.70413 8.25568 7.58064C8.19382 7.45715 7.71406 6.23615 7.49452 5.74801Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_2884_12148"
                    x1="958.224"
                    y1="1924.38"
                    x2="958.224"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#1FAF38" />
                    <stop offset="1" stop-color="#60D669" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2884_12148"
                    x1="992.248"
                    y1="1993.39"
                    x2="992.248"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#F9F9F9" />
                    <stop offset="1" stop-color="white" />
                  </linearGradient>
                  <clipPath id="clip0_2884_12148">
                    <rect width="19.845" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            {channels?.label?.length > 17? channels?.label?.slice(0,16)+'...' : channels?.label}
          </p>
          {values?.catalogImageUrl &&
            values.category === "catalog_marketing" && (
              <div className={`layout1 ${isFixed ? "fixedlayout" : ""}`}>
                <img
                  src={values.catalogImageUrl}
                  width={217}
                  height={125}
                  alt="Catalog Image"
                />
                <div style={{fontWeight:"bold"}} >
                {values.options === "text"  ? values.content : ""}
                </div>


                <div className="pt-2">
                  {renderMessageText(values, values.messageBody)}
                </div>
                <div className="pt-1 text-muted" title={values.footerMsg}>
                  <i>
                    {values.footerMsg ? sliceText(values.footerMsg, 40) : ""}
                  </i>
                </div>
              </div>
            )} 
            

          {/* <img src={'src/assets/img/previewPhone.png'} alt='Template Preview' className='tempPreview' /> */}
          {(values.category !== "catalog_marketing" && values.messageBody) ||
          (values.content && values.category !== "catalog_marketing") ||
          (values.file && values.category !== "catalog_marketing") || (values.latitude && values.longitude) ||
          (values.footerMsg && values.category !== "catalog_marketing") ? (
            <div className={`${values.category === 'carousel'? 'layoutcarousel' : 'layout1'}  ${isFixed ? "fixedlayout" : ""}`}>
              <div title={values.content} className={`fontWeight`}>
                {values.options === "text"  ? values.content : ""}
                
                {/* ? sliceText(values.content, 40) : '' : '' */}
                {values.options === 'location' && !isNaN(values.latitude) && !isNaN(values.longitude) ? (
        <MapLoader>
          <div id="send-msg-map">
            <GoogleMap
              id="msg-map"
              mapContainerStyle={{ width: "220px", height: "225px" }}
              zoom={12}
              center={{
                lat: parseFloat(values.latitude),
                lng: parseFloat(values.longitude),
              }}
            >
              <Marker
                position={{
                  lat: parseFloat(values.latitude),
                  lng: parseFloat(values.longitude),
                }}
              />
            </GoogleMap>
          </div>
        </MapLoader>
      ) : null}
                {values.options === "image" ||
                values.options === "video" ||
                values.options === "document"
                  ? files.map((file: { imgUrl: string }, index: number) => (
                      <>
                        {values.options === "image" && (
                          <img
                            key={index}
                            src={file.imgUrl}
                            width={217}
                            height={125}
                            alt="oldimage"
                          />
                        )}
                        {values.options === "video" && (
                          <video
                            key={index}
                            src={file.imgUrl}
                            controls
                            className="pdfSizeInPreview"
                          />
                        )}
                        {values.options === "document" && (
                          <iframe
                            src={file.imgUrl}
                            className="pdfSizeInPreview"
                          ></iframe>
                        )}
                      </>
                    ))
                  : null}
              </div>
              <div className="pt-2">
                {renderMessageText(values, values.messageBody)}
              </div>
              <div className="pt-1 text-muted" title={values.footerMsg}>
                <i>{values.footerMsg ? sliceText(values.footerMsg, 40) : ""}</i>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* {values.category === "catalog_marketing" && values.catalogOption==="catalogue" && (
            <div className={`pt-1 text-center quickbtns mt-1 btnwidth`}>
              View catalog
            </div>
          )} */}
           {values.category === "catalog_marketing" && values.catalogOption==="catalogue" && values.catalogImageUrl &&values?.messageBody && (
             <div
             className={`previewBtn ${
              isFixed && !values.messageBody ? "fixedlayout" : !values?.catalogImageUrl ?
              "previewBtn":""
            }`}           >
            <div className={`pt-1 text-center quickbtns mt-1 btnwidth`}>
            {/* <FontAwesomeIcon icon={faCopy} className="me-2" /> */}
            View catalog
            </div>
            </div>
          )}  
          
           {values.category === "catalog_marketing" && values.catalogOption!=="catalogue" &&values.catalogImageUrl && (
            <div className={`pt-1 text-center quickbtns mt-1 btnwidth`}>
              View items
            </div>
          )}
           {values?.category === "authentication" && values?.authButton &&values?.authtextButton?.length>=1 && (
            <div className={`pt-1 text-center quickbtns mt-1 btnwidth`}>
{values?.authtextButton}
            </div>
          )}
          

           {values.category === "coupon_marketing" && values?.messageBody && (
             <div
             className={`previewBtn ${
               isFixed && !values.messageBody ? "fixedlayout" : ""
             }`}
           >
            <div className={`pt-1 text-center quickbtns mt-1 btnwidth`}>
            <FontAwesomeIcon icon={faCopy} className="me-2" />
              Copy code
            </div>
            </div>
          )}   

          {values.phoneChecked ||
          values.webUrlChecked ||
          values.phoneInput1 ||
          values.websiteName ||
          values.category !== 'carousel' ||
          values.replyButtons.some((obj: any) => obj.value) ? (
            <div
              className={`previewBtn ${
                isFixed && !values.messageBody ? "fixedlayout" : ""
              }`}
            >
              {/* {values.btnOptions === 'action' && type !== 'view'?
                                <div className='d-flex mt-1'>
                                    {values.phoneChecked ? <div className={`replyActionBtns1 text-center mt-1 mr-1 ${values.webUrlChecked ? 'col-5' : 'col-10'}`}>
                                        {values.phoneChecked ? <img src={phone} width={20} /> : ''}
                                        {values.phoneInput1 ? <span className='footer' title={values.phoneInput1}>{sliceText(values.phoneInput1, 7)}</span> : ''}
                                    </div> : ''}
                                    {values.webUrlChecked ? <div className={`replyActionBtns1 text-center mt-1  ${values.phoneChecked ? 'col-5' : 'col-10'}`}>
                                        {values.webUrlChecked ? <FontAwesomeIcon icon={faExternalLinkAlt} /> : ''}
                                        {values.websiteName ? <span className='footer' title={values.websiteName}>{sliceText(values.websiteName, 7)}</span> : ''}
                                    </div> : ''}
                                </div> : ''} */}

              {values.btnOptions.includes("action") ? (
                <div className="mt-1">
                  {values.phoneChecked ? (
                    <div
                      className={`replyActionBtns1 text-center mt-1 btnwidth`}
                    >
                      {values.phoneChecked ? (
                        <img src={phone} width={20} />
                      ) : (
                        ""
                      )}
                      {values.phoneInput1 ? (
                        <span className="footer" title={values.phoneInput1}>
                          {sliceText(values.phoneInput1, 20)}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {values.webUrlChecked  ? (
                    <div
                      className={`replyActionBtns1 text-center mt-1 btnwidth`}
                    >
                      {values.webUrlChecked ? (
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      ) : (
                        ""
                      )}
                      {values.websiteName ? (
                        <span className="footer" title={values.websiteName}>
                          {sliceText(values.websiteName, 20)}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {values.webUrlChecked &&
                  values.webUrlChecked1 &&
                  buttonCount <= 2 ? (
                    <div
                      className={`replyActionBtns1 text-center mt-1 btnwidth`}
                    >
                      {values.webUrlChecked1 ? (
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      ) : (
                        ""
                      )}
                      {values.websiteName1 ? (
                        <span className="footer" title={values.websiteName1}>
                          {sliceText(values.websiteName1, 20)}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {/* {values.btnOptions === 'quickReply' && type !== 'view'?
                                <>

                                    {
                                        values.replyButtons.map((val: { label: string, name: string, id: string, value: string }, ind: number) => (
                                            <div>  <div key={ind} title={val.value} className={`pt-1 text-center quickbtns 
                                        ${values.replyButtons.length === 1 ? 'col-10 mt-2' : (ind === 2 ? 'col-10 mt-2' : ind === 1 ? 'col-5 mt-2' : 'col-5 mt-2 margin-top')} 
                                        ${values.replyButtons.length === 3 && ind === 1 ? 'quickbtns2 ml-1' : values.replyButtons.length === 2 && ind === 1 ? 'quickbtns2 ml-1' : ''}`}
                                            >
                                             {values.replyButtons.length === 1 && ind === 0 ? sliceText(val.value, 20) : values.replyButtons.length === 3 && ind === 2? sliceText(val.value, 20) : sliceText(val.value, 8)}
                                            </div></div>
                                        ))}
                                </> : ''} */}

            {values.category === 'limited' ? (
                <>
                  {
                      <div>
                        {values?.limitedCode && <div
                          className={`pt-1 text-center quickbtns mt-1 btnwidth`}
                        >
                         <FontAwesomeIcon icon={faCopy} className="me-2" />Copy offer code
                        </div>}
                        {values?.limitedButton && <div
                          className={`pt-1 text-center quickbtns mt-1 btnwidth`}
                        >
                          <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1"/> {values?.limitedButton?.length > 18? values?.limitedButton.slice(0,17)+ '...' : values?.limitedButton }
                        </div>}
                      </div>
                    }
                  </>
                ): ''}

              {values.btnOptions.includes("quickReply") ? (
                <>
                  {values.replyButtons.slice(0, replybuttonCount).map(
                    (
                      val: {
                        label: string;
                        name: string;
                        id: string;
                        value: string;
                      },
                      ind: number
                    ) => (
                      <div key={ind}>
                        <div
                          title={val.value}
                          className={`pt-1 text-center quickbtns mt-1 btnwidth`}
                        >
                          <FontAwesomeIcon icon={faReply} className="mr-1"/> {sliceText(val.value, 20)}
                        </div>
                      </div>
                    )
                  )}
                  {/* {values.replyButtons.length > 2 && (
                    <div
                      className="pt-1 text-center quickbtns mt-1 btnwidth"
                      onClick={() => {
                        // Your logic to show more buttons
                      }}
                    >
                      Explore More
                    </div>
                  )} */}
                </>
              ) : (
                ""
              )}

              {buttonCount > 2 && (
                <div
                  className="pt-1 text-center quickbtns mt-1 btnwidth"
                  onClick={() => {}}
                >
                  <FontAwesomeIcon
                    icon={faList}
                    widths={80}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Explore More
                </div>
              )}
            </div>
          ) : (
            ""
          )}


{values.category === 'carousel' && carouselData?.length? 
     <div className={`carouselTemps ${isFixed ? "fixedlayout" : ""}`}>
     <div className="d-flex">
       {carouselData?.map((e: any, ind: number) => (
         <div key={ind} className="templateRow">
          <div className="">
           <span>
            {e?.card?.components[0]?.format === 'IMAGE'?
             <img src={e?.card?.components[0]?.example?.header_handle[0]} className="" width={170} height={90} alt="Header" /> :
             (e?.card?.components[0]?.format === 'VIDEO')? 
             <video  src={e?.card?.components[0]?.example?.header_handle[0]}
                controls
                width={170}
                height={90}
                /> : ''}
           </span>
           <div title={e?.card?.components[1]?.text}>{e?.card?.components[1]?.text.length > 15? e?.card?.components[1]?.text?.slice(0,14) + '...' : e?.card?.components[1]?.text}</div>
           </div>
           <div className="pt-2">
             {e?.card?.components[2]?.buttons?.map((ele: any, buttonIndex: number) => (
               <p key={buttonIndex} className="quickbtns m-1 text-center" title={ele?.text}>
                {ele?.type === 'QUICK_REPLY'? <FontAwesomeIcon icon={faReply} className="mr-1"/> : ele?.type === 'PHONE_NUMBER'? <img src={phone} width={20} /> : ele?.type === 'URL'? <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1"/> : '' }  
                {ele?.text?.length > 15? ele?.text?.slice(0,14)+'...' : ele?.text}</p>
             ))}
           </div>
         </div>
       ))}
     </div>
   </div>
   : ''}


        </div>
      ) : (
        <div className="whatsappImage linkpreview">
          {/* <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_2884_12148)">
                            <path
                                d="M0.423595 9.53519C0.42313 11.2156 0.8622 12.8564 1.69708 14.3026L0.34375 19.2438L5.40049 17.9179C6.79912 18.6793 8.36618 19.0783 9.95863 19.0784H9.96282C15.2198 19.0784 19.4991 14.8006 19.5013 9.54271C19.5024 6.99488 18.511 4.59907 16.71 2.79659C14.9092 0.994264 12.5142 0.00116279 9.96243 0C4.70484 0 0.425843 4.27752 0.423672 9.53519"
                                fill="url(#paint0_linear_2884_12148)"
                            />
                            <path
                                d="M0.0829457 9.87721C0.0824031 11.6181 0.537209 13.3175 1.40186 14.8155L0 19.9339L5.23806 18.5605C6.68132 19.3474 8.30628 19.7622 9.95977 19.7629H9.96403C15.4096 19.7629 19.8426 15.3312 19.845 9.88512C19.8459 7.24574 18.8189 4.7638 16.9535 2.89674C15.0878 1.02992 12.6072 0.00108527 9.96403 0C4.51752 0 0.0851163 4.43101 0.0829457 9.87721ZM3.20233 14.5575L3.00674 14.2471C2.18457 12.9398 1.75062 11.4291 1.75124 9.87783C1.75302 5.35109 5.43705 1.66822 9.96713 1.66822C12.1609 1.66915 14.2226 2.52434 15.7733 4.07597C17.324 5.62775 18.1772 7.69054 18.1767 9.8845C18.1747 14.4112 14.4905 18.0946 9.96403 18.0946H9.96078C8.4869 18.0938 7.0414 17.698 5.78078 16.95L5.48078 16.7721L2.3724 17.5871L3.20233 14.5574V14.5575Z"
                                fill="url(#paint1_linear_2884_12148)"
                            />
                            <path
                                d="M7.49452 5.74801C7.30956 5.33692 7.11491 5.32863 6.93902 5.32142C6.79499 5.31522 6.63034 5.31568 6.46584 5.31568C6.30119 5.31568 6.03367 5.37762 5.80755 5.62452C5.58119 5.87165 4.94336 6.46886 4.94336 7.68351C4.94336 8.89824 5.82809 10.0721 5.95142 10.237C6.07491 10.4016 7.65941 12.974 10.1689 13.9636C12.2544 14.786 12.6789 14.6224 13.1315 14.5812C13.5842 14.5401 14.5923 13.9841 14.7979 13.4076C15.0037 12.8312 15.0037 12.3371 14.942 12.2338C14.8803 12.131 14.7157 12.0692 14.4688 11.9458C14.2218 11.8223 13.008 11.225 12.7817 11.1426C12.5554 11.0603 12.3908 11.0192 12.2262 11.2664C12.0615 11.5132 11.5887 12.0692 11.4446 12.2338C11.3006 12.3989 11.1565 12.4194 10.9097 12.2959C10.6627 12.172 9.86755 11.9117 8.92421 11.0706C8.19026 10.4162 7.69475 9.60808 7.55072 9.36088C7.40669 9.11405 7.5353 8.98026 7.6591 8.85723C7.77003 8.74661 7.90607 8.56894 8.02964 8.42483C8.15274 8.28064 8.19382 8.17777 8.27615 8.01312C8.35855 7.84832 8.31731 7.70413 8.25568 7.58064C8.19382 7.45715 7.71406 6.23615 7.49452 5.74801Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <linearGradient
                                id="paint0_linear_2884_12148"
                                x1="958.224"
                                y1="1924.38"
                                x2="958.224"
                                y2="0"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#1FAF38" />
                                <stop offset="1" stop-color="#60D669" />
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear_2884_12148"
                                x1="992.248"
                                y1="1993.39"
                                x2="992.248"
                                y2="0"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#F9F9F9" />
                                <stop offset="1" stop-color="white" />
                            </linearGradient>
                            <clipPath id="clip0_2884_12148">
                                <rect width="19.845" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg> */}
          {/* <img src={previewImage} width={690} height={600} className='linkPreview'/> */}
          <div className="d-flex">
            <span className="rounded-circle ml-3">
              <img src={whatsapplogo} alt="whatsapp" width={25} />
            </span>
            <span className="pl-2 pt-2 whatnumber">{values.mobileNo}</span>
          </div>
          <div className=" whatmessage">
            {files ? (
              <span>
                {values.message.length > 17
                  ? `${values.message.slice(0, 17)}...`
                  : values.message}
              </span>
            ) : (
              ""
            )}
          </div>
          {!files ? (
            <div className="layout whatmessagesend">
              <span>
                {values.message.length > 300
                  ? `${values.message.slice(0, 300)}...more`
                  : values.message}
              </span>
            </div>
          ) : (
            ""
          )}
          <span className="plane">
            <img src={paperplane} alt="whatsapp" width={25} />
          </span>
        </div>
      )}


    </React.Fragment>
  );
};

export default TemplatePreview;
