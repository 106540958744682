import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Popover, PopoverBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  getConversationCharges,
  getConversationData,
} from "../../services/AnalyticsService";
import "./Analytics.scss";
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import { ErrorImage } from "../../common/ErrorMessage";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { BsInfoCircle, BsThreeDotsVertical } from "react-icons/bs";
import { LiaRupeeSignSolid } from "react-icons/lia";

// Define the keys for conversation types
type ConversationKeys =
  | "marketing"
  | "utility"
  | "authentication"
  | "service"
  | "freeTier"
  | "freeEntryPoint";

// Map conversation labels
const labels: Record<ConversationKeys, string> = {
  marketing: "Marketing",
  utility: "Utility",
  authentication: "Authentication",
  service: "Service",
  freeTier: "Free Tier",
  freeEntryPoint: "Free entry point",
};

// Define the tooltips for "All Conversations"
const conversationTooltips: Record<ConversationKeys, string> = {
  marketing: "Marketing Conversations initiated by the business",
  utility: "Utility Conversations initiated by the business",
  authentication: "Authentication Conversations initiated by the business",
  service: "Service Initiated by the users and responded by the business",
  freeTier: "",
  freeEntryPoint: "",
};

interface FormValues {
  channelName: { value: string; label: string } | null;
  monthSelected: { value: string; label: string } | null;
}

// Define the shape of each day's data
interface ConversationDetails {
  totalConversations: number;
  marketing: number;
  utility: number;
  authentication: number;
  service: number;
  freeTier: number;
  freeEntryPoint: number;
  totalAmount: number;
}

// Define the shape of the entire dataset
interface ConversationData {
  [date: string]: ConversationDetails;
}

const ViewInsights = () => {
  const [exportLoading, setExportLoading] = useState(false);
  const [conversationData, setConversationData] = useState<any>(null);
  const moment = extendMoment(originalMoment);
  const validationSchema = Yup.object({
    channelName: Yup.object().nullable().required("Channel Name is required"),
    monthSelected: Yup.object().nullable().required("Month is required"),
  });

  const selectedChannel = useSelector(
    (state: any) => state.cartreducer.channelUid.value
  );

  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );

  const channelOptions = channelData.map((channel: any) => ({
    value: channel.uid,
    label: channel.name,
  }));

  const TableData = ({ data }: { data: ConversationData | null }) => {
    const headers = [
      "DATE",
      "MARKETING",
      "UTILITY",
      "AUTHENTICATION",
      "SERVICE",
      "FREE TIER",
      "FREE ENTRY POINT",
      "TOTAL AMOUNT",
      "", // Adding the 'Actions' header
    ];

    // Add a check to ensure data is not null or undefined
    if (!data) {
      return <div>No data available.</div>;
    }

    const [popoverOpen, setPopoverOpen] = useState<string | null>(null);
    const popoverRef = useRef<any>(null);

    const handleExport = async (date: string) => {
      const fromTimeUTC = Date.UTC(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0
      );
      const toTimeUTC = Date.UTC(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        23,
        59,
        59
      );

      const response = await getConversationData(
        fromTimeUTC,
        toTimeUTC,
        selectedChannel
      );

      // Export to Excel with the fetched data
      exportToExcel(response.conversationReport, date);

      // Close popover after exporting
      setPopoverOpen(null);
    };

    const exportToExcel = async (conversationReport: any[], date: string) => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Conversation Report");

      worksheet.columns = [
        { header: "DATE", key: "conversationDate", width: 15 },
        { header: "NAME", key: "name", width: 25 },
        { header: "MOBILE NUMBER", key: "mobileNumber", width: 20 },
        { header: "COUNTRY CODE", key: "countrycode", width: 10 },
        { header: "CATEGORY", key: "category", width: 15 },
        { header: "CHARGES", key: "charges", width: 10 },
        { header: "CHANNEL NAME", key: "channelName", width: 20 },
      ];

      conversationReport.forEach((conversation) => {
        worksheet.addRow({
          conversationDate: new Date(
            conversation.conversationDate
          ).toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }),
          name: conversation.name,
          mobileNumber: conversation.mobileNumber,
          countrycode: conversation.countrycode,
          category: conversation.category,
          charges: `₹ ${conversation.charges.toFixed(4)}`,
          channelName: conversation.channelName,
        });
      });

      worksheet.views = [
        { state: "frozen", xSplit: 0, ySplit: 1, topLeftCell: "A2" },
      ];

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `ConversationReport_${date}.xlsx`);
    };

    const handlePopoverClick = (date: string) => {
      handleExport(date); // Trigger the API call and export logic
      setPopoverOpen(null); // Close the popover
    };

    return (
      <table className="table table-bordered">
        <thead className="thead-table">
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).length > 0 ? (
            <>
              {Object.entries(data).map(([date, details]) => (
                <tr key={date}>
                  <td className="d-flex justify-content-center align-items-center">
                    {new Date(date).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                    })}
                  </td>
                  <td>{details.marketing}</td>
                  <td>{details.utility}</td>
                  <td>{details.authentication}</td>
                  <td>{details.service}</td>
                  <td>{details.freeTier}</td>
                  <td>{details.freeEntryPoint}</td>
                  <td>
                    <LiaRupeeSignSolid /> {details.totalAmount.toFixed(4)}
                  </td>
                  <td>
                    <div
                    //  ref={popoverRef}
                    >
                      <BsThreeDotsVertical
                        id={`popover-${date}`}
                        onClick={() =>
                          setPopoverOpen(popoverOpen === date ? null : date)
                        }
                        style={{ cursor: "pointer" }}
                      />

                      <Popover
                        placement="top"
                        isOpen={popoverOpen === date}
                        target={`popover-${date}`}
                        toggle={() => setPopoverOpen(null)}
                      >
                        <PopoverBody
                          onClick={() => handlePopoverClick(date)} // Trigger the API call when clicked
                          style={{ cursor: "pointer" }}
                        >
                          Export to Excel
                        </PopoverBody>
                      </Popover>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "90vw" }}
            >
              <ErrorImage />
            </div>
          )}
        </tbody>
      </table>
    );
  };

  const getDefaultChannelOption = () => {
    return (
      channelOptions.find((option: any) => option.value === selectedChannel) ||
      null
    );
  };

  function getDefaultMonthYearOption() {
    const now = new Date();
    return {
      value: now.toISOString(),
      label: `${now.toLocaleString("default", {
        month: "long",
      })} ${now.getFullYear()}`,
    };
  }

  function getMonthYearOptions() {
    const options = [];
    const now = new Date();
    for (let i = 0; i < 6; i++) {
      const date = new Date(now.getFullYear(), now.getMonth() - i);
      options.push({
        value: date.toISOString(), // Keep the full value with the year
        label: date.toLocaleString("default", {
          month: "long", // Display only the month in the label
        }),
        isDisabled: date > now,
      });
    }
    return options;
  }

  const [initialValues, setInitialValues] = useState<FormValues>({
    channelName: getDefaultChannelOption(),
    monthSelected: getDefaultMonthYearOption(),
  });

  const handleSelectionChange = async (values: FormValues) => {
    if (values.channelName && values.monthSelected) {
      const selectedDate = new Date(values.monthSelected.value);
      const fromTimeUTC = Date.UTC(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1
      );
      const toTimeUTC = Date.UTC(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0,
        23,
        59,
        59
      );

      const response = await getConversationCharges(
        fromTimeUTC,
        toTimeUTC,
        values.channelName.value
      );

      setConversationData(response);
    }
  };

  useEffect(() => {
    handleSelectionChange(initialValues);
  }, [initialValues]);

  const exportReportHandler = async () => {
    if (!conversationData) return;

    setExportLoading(true);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Conversation Data");

    // Define headers for the Excel sheet (the same as in your table)
    worksheet.columns = [
      { header: "DATE", key: "date", width: 15 },
      { header: "MARKETING", key: "marketing", width: 15 },
      { header: "UTILITY", key: "utility", width: 15 },
      { header: "AUTHENTICATION", key: "authentication", width: 15 },
      { header: "SERVICE", key: "service", width: 15 },
      { header: "FREE TIER", key: "freeTier", width: 15 },
      { header: "FREE ENTRY POINT", key: "freeEntryPoint", width: 20 },
      { header: "TOTAL AMOUNT", key: "totalAmount", width: 15 },
    ];

    // Add a header row with styling
    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } };
    headerRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFF7342" },
    };
    headerRow.alignment = { horizontal: "center" };

    // Map conversation data and add rows
    Object.entries(conversationData.credits).forEach(([date, details]: any) => {
      worksheet.addRow({
        date: new Date(date).toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
        }),
        marketing: details.marketing,
        utility: details.utility,
        authentication: details.authentication,
        service: details.service,
        freeTier: details.freeTier,
        freeEntryPoint: details.freeEntryPoint,
        totalAmount: `₹ ${details.totalAmount.toFixed(4)}`,
      });
    });

    // Freeze the header row
    worksheet.views = [
      { state: "frozen", xSplit: 0, ySplit: 1, topLeftCell: "A2" },
    ];

    // Write to a buffer and download the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `ConversationChargesReport_${new Date().toISOString()}.xlsx`);

    setExportLoading(false);
  };

  // Titles and keys for each dashboard column
  const dashboardConfig = [
    {
      title: "All Conversations",
      totalKey: "totalConversations",
      amountKey: "totalAmount",
      keys: [
        "marketing",
        "utility",
        "authentication",
        "service",
      ] as ConversationKeys[],
      dataKey: "allConversations",
    },
    {
      title: "Free Conversations",
      totalKey: "totalConversations",
      keys: ["freeTier", "freeEntryPoint"] as ConversationKeys[],
      dataKey: "freeConversation",
    },
    {
      title: "Billable Conversations",
      totalKey: "totalConversations",
      amountKey: "totalAmount",
      keys: [
        "marketing",
        "utility",
        "authentication",
        "service",
      ] as ConversationKeys[],
      dataKey: "billableConversations",
    },
    {
      title: "Charges",
      totalKey: "totalAmount",
      keys: [
        "marketing",
        "utility",
        "authentication",
        "service",
      ] as ConversationKeys[],
      dataKey: "totalCharges",
      format: (value: number) => (
        <>
          <LiaRupeeSignSolid /> {value ? value.toFixed(2) : "0.00"}
        </>
      ),
    },
  ];

  // const tableData = useMemo(() => {
  //   return Object.keys(conversationData).map((date) => ({ date, ...conversationData.credit[date] }));
  // }, [conversationData]);

  return (
    <Container>
      <div className="view-insights-header">
        <h5 className="d-flex align-items-center mb-2">
          <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
            <Button color="link" className="backBtn">
              <FontAwesomeIcon icon={faArrowLeft} color="black" />
            </Button>
          </Link>
          <span className="mr-2">View Insights</span>
        </h5>
      </div>

      <div className="view-insights-body">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSelectionChange}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="insights-filter">
                <div className="d-flex">
                  <div className="form-group me-3 d-none">
                    <label htmlFor="channelName">Channel Name</label>
                    <Select
                      options={channelOptions}
                      name="channelName"
                      value={values.channelName}
                      onChange={(option) => {
                        setFieldValue("channelName", option);
                        handleSelectionChange({
                          ...values,
                          channelName: option,
                        });
                      }}
                      className="channel-select"
                    />
                    <ErrorMessage
                      name="channelName"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="monthSelected">Select by Month</label>
                    <Select
                      options={getMonthYearOptions()}
                      name="monthSelected"
                      value={
                        values.monthSelected
                          ? {
                              value: values.monthSelected.value,
                              label: values.monthSelected.label.includes(
                                new Date(values.monthSelected.value)
                                  .getFullYear()
                                  .toString()
                              )
                                ? values.monthSelected.label // If the year is already included in the label, don't add it again
                                : `${values.monthSelected.label} ${new Date(
                                    values.monthSelected.value
                                  ).getFullYear()}`, // Append the year only if it's not already in the label
                            }
                          : null
                      }
                      onChange={(option) => {
                        setFieldValue("monthSelected", option);
                        handleSelectionChange({
                          ...values,
                          monthSelected: option,
                        });
                      }}
                      className="month-select"
                    />

                    <ErrorMessage
                      name="monthSelected"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="export-excel ms-3">
                    <Button
                      disabled={exportLoading}
                      onClick={exportReportHandler}
                      color="light"
                      className="export"
                    >
                      {!exportLoading && (
                        <svg
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.75 3H11.25M7.5 13V5.5M7.5 5.5L9.6875 7.6875M7.5 5.5L5.3125 7.6875"
                            stroke="#423B3B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      )}

                      <span className="ml-1">
                        {exportLoading ? "Loading..." : "Export"}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        {/* Dashboard Display */}
        {conversationData && (
          <div className="dashBoard">
            {dashboardConfig.map((column) => (
              <div key={column.title} className="dashBoard-column">
                <div className="d-flex justify-content-between align-items-center">
                  <strong>{column.title} </strong>

                  <strong>
                    {column.title != "Charges" && conversationData[column.dataKey][column.totalKey] !==
                      undefined && (
                      <>{conversationData[column.dataKey][column.totalKey]}</>
                    )}
                    {column.title === "Charges" &&
                      column.totalKey &&
                      conversationData[column.dataKey][column.totalKey] !==
                        undefined && (
                        <>
                          <LiaRupeeSignSolid />
                          {conversationData[column.dataKey][
                            column.totalKey
                          ].toFixed(2)}
                        </>
                      )}
                  </strong>
                </div>

                {column.keys.map((key) => (
                  <div
                    key={key}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      {labels[key as keyof typeof labels]}
                      {column.title === "All Conversations" && (
                        <BsInfoCircle
                          size={10}
                          className="ms-1"
                          title={
                            conversationTooltips[
                              key as keyof typeof conversationTooltips
                            ]
                          }
                        />
                      )}{" "}
                    </div>
                    <div>
                      {column.format
                        ? column.format(conversationData[column.dataKey][key])
                        : conversationData[column.dataKey][key]}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}

        <div className="insights-info">
          <div>
            <BsInfoCircle /> Monitor the daily number of WhatsApp conversations.
          </div>
          <div className="insights-info-sub">
            * All insights data is approximate and may vary from your invoices
            due to minor differences in data processing.
          </div>
        </div>

        <div className="data-table">
          <TableData data={conversationData?.credits} />
        </div>
      </div>
    </Container>
  );
};

export default ViewInsights;
