import { auth, getToken, messaging, onMessage } from "../../Firebase/firebase";
import { showAlert, toast } from "../../common/alert";
import * as actions from './actions';
import axios from '../../utils/axiosForAuth';
import axiosCommon from '../../utils/commonAxios';
import componentAxios from '../../utils/axios';
import moment from 'moment';

export function signInWithEmail(email: string, password: string, navigate: (path: string) => void,
    setJustLoggedIn: React.Dispatch<React.SetStateAction<boolean>>, requestMessagingToken: () => void, loading: () => void, isZohoDataPresent: boolean): void {
    auth.signInWithEmailAndPassword(email, password)
        .then((result: any) => {
            if (result.user?.emailVerified) {
                signinWithToken(result, setJustLoggedIn, requestMessagingToken, loading, navigate, isZohoDataPresent);
            }
            else {
                toast('error', 'Please Activate your Email');
                loading()
            }
        })
        .catch((error: any) => {
            loading()
            toast('error', 'Invalid Credentials');
        });
}

export const signinWithToken = (result: any, setJustLoggedIn: React.Dispatch<React.SetStateAction<boolean>>, requestMessagingToken: () => void, loading: () => void,
    navigate: (path: string) => void, isZohoDataPresent?: boolean) => {
    // var fcmToken: string;
    // getToken(messaging, {
    //     vapidKey: 'BKy6AjlZQH2S7UthKTJ6A4Q2S2QMvLY76n0skgzpZgSZIqHf9y8_XElwlXjRMhaM2FRcAmT_5JAutcMBtetymIc',
    // }).then((currentToken: string) => {
    //     fcmToken = currentToken;
    //     Notification.requestPermission();
    //     result.user.getIdToken().then((idToken: string) => {
    //         axios.post(`auth/authenticate?gcmId=${fcmToken}`, idToken, {
    //             headers: {
    //                 'Content-Type': 'text/plain',
    //             }
    //         })
    //             .then(res => {
    //                 localStorage.setItem('apiToken', JSON.stringify(res.data));
    //                 componentAxios.get('profile', {
    //                     headers: {
    //                         uid: result.user?.uid
    //                     }
    //                 }).then(res => {
    //                     localStorage.setItem('businessId', res.data?.business?.uid);
    //                     setJustLoggedIn(true);
    //                     loading()
    //                     requestMessagingToken();
    //                     const givenDate = moment(res.data?.business?.plan?.expireAt);
    //                     const today = moment();
    //                     if (givenDate.isBefore(today, 'day')) {
    //                         navigate(`${process.env.PUBLIC_URL}/common/subscribePage`);
    //                     }
    //                     else {
    //                         if (res.data?.role?.name === 'MEMBER') {
    //                             navigate(`${process.env.PUBLIC_URL}/conversations/`);
    //                         } else {
    //                             if (res.data.business?.freeTrial) {
    //                                 navigate(`${process.env.PUBLIC_URL}/welcome`);
    //                             }
    //                             else {
    //                                 navigate(`${process.env.PUBLIC_URL}/dashboard`);
    //                             }
    //                         }
    //                     }
    //                     //}
    //                 })
    //             })
    //             .catch(err => {
    //                 toast('error', err.response.data.message);
    //                 loading();
    //             });
    //     }).catch((err: any) => {
    //         toast('error', 'Failed to retrieve ID token.');
    //     });
    // }).catch(err => {
    //     toast('error', 'FCM token cannot be retrieved');
    // })
    result.user.getIdToken().then((idToken: string) => {
        axios.post(`auth/authenticate`, idToken, {
            headers: {
                'Content-Type': 'text/plain',
            }
        })
            .then(res => {
                localStorage.setItem('apiToken', JSON.stringify(res.data));
                componentAxios.get('profile', {
                    headers: {
                        uid: result.user?.uid
                    }
                }).then(res => {
                    localStorage.setItem('businessId', res.data?.business?.uid);
                    setJustLoggedIn(true);
                    loading()
                    const givenDate = moment(res.data?.business?.plan?.expireAt);
                    const today = moment();
                    if (givenDate.isBefore(today, 'day')) {
                        navigate(`${process.env.PUBLIC_URL}/common/subscribePage`);
                    }
                    else {
                        if (res.data?.role?.name === 'MEMBER') {
                            navigate(`${process.env.PUBLIC_URL}/conversations/`);
                        } else {
                            if (isZohoDataPresent)
                                navigate(`${process.env.PUBLIC_URL}/channels`);
                            else {
                                if (res.data.business?.freeTrial) {
                                    navigate(`${process.env.PUBLIC_URL}/welcome`);
                                }
                                else {
                                    navigate(`${process.env.PUBLIC_URL}/dashboard`);
                                }
                            }
                        }
                    }
                    //}
                })
            })
            .catch(err => {
                toast('error', err.response.data.message);
                loading();
            });
    }).catch((err: any) => {
        toast('error', 'Failed to retrieve ID token.');
    });

}

export const verifyEmailPhone = (data: any, code: string, navigate: (path: string) => void): Promise<any> => {
    const verifydata = {
        countryCode: code,
        mobileNumber: data.mobileNo,
        email: data.email,
    };
    //return createUserAndSendActivationEmail(data, code);
    return axios
        .post('auth/verify', verifydata)
        .then(() => {
            return createUserAndSendActivationEmail(data, code, navigate);
        })
        .catch((error) => {
            toast('error', error.response.data.message);
            if (error.response.data.message)

                return { success: false, data: '' };
        });
};


const createUserAndSendActivationEmail = (userdata: any, code: string, navigate: (path: string) => void): Promise<any> => {
    return new Promise((resolve, reject) => {
        auth
            .createUserWithEmailAndPassword(userdata.email, userdata.password)
            .then((userCredential: any) => {
                const user = userCredential.user;
                user
                    .sendEmailVerification()
                    .then(() => {
                        const registerPayload = {
                            uid: user.uid,
                            name: userdata.name,
                            businessName: userdata.compName,
                            businessSite: userdata.compWeb,
                            mobileNumber: userdata.mobileNo,
                            email: userdata.email,
                            password: userdata.password,
                            referralCode: userdata.isReferred ? userdata.referralCode : '',
                            agree: userdata.agreeToTerms,
                            countryCode: code
                        };
                        axios
                            .post('auth/register', registerPayload)
                            .then((res) => {
                                const updateUserPayload = {
                                    uid: user.uid,
                                    email: user.email,
                                    password: userdata.password,
                                    mobileNumber: userdata.mobileNo,
                                    countryCode: code
                                    // ... other fields to update
                                };
                                toast('success', `Activation link will be sent to this email ${userdata.email}`, '385px');
                                axios
                                    .post('auth/updateUser', updateUserPayload)
                                    .then(() => {
                                        resolve({ success: true, data: res.data });
                                        navigate(`${process.env.PUBLIC_URL}/login`);
                                    })
                                    .catch((error) => {
                                        toast('error', error.response.data.message);
                                        reject({ success: false, data: '' });
                                    });
                            })
                            .catch((error) => {
                                if (error.response.data.message === 'Email already exists') {
                                    user.delete().then(() => {
                                        toast('error', error.response.data.message);
                                    }).catch((err: any) => {
                                    })
                                    reject({ success: false, data: '' });
                                }
                                else {
                                    toast('error', error.response.data.message);
                                    reject({ success: false, data: '' });
                                }
                            });
                    }).catch((error: any) => {
                        toast('error', 'Error sending activation email');
                        reject({ success: false, data: '' });
                    });
            })
            .catch((error: any) => {
                toast('error', 'Error creating user');
                reject({ success: false, data: '' });
            });
    });
};

const getParameterByName = (name: string): string => {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results === null)
        return "";
    else
        return decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const handleSignInWithEmailLink = () => {
    const returnUrl: string = getParameterByName("oobCode");
    if (returnUrl !== "") {
        auth.applyActionCode(getParameterByName("oobCode")).then((res: any) => {
            console.log(res);
        })
    }
};

export async function SelectCurrency() {
    try {
        const response = await axiosCommon.get('getCountries');
        return response.data;
    } catch (error: any) {
        toast('error', error.response.data.message);
    }
}

export async function timeZone() {
    try {
        const response = await axiosCommon.get('getTimeZone');
        return response.data;
    } catch (error: any) {
        toast('error', error.response.data.message);
    }
}

export async function timeZoneWithGMT() {
    try {
        const response = await axiosCommon.get('getTimeZoneWithGMT');
        return response.data;
    } catch (error: any) {
        toast('error', error.response.data.message);
    }
}

export const submitAddnDetails = (data: any, setIsLoading: any) => {
    axios.patch(`auth/account/${data?.business.uid}`, data?.business).then(res => {
        toast('success', 'Business details updated successfully');
        setIsLoading(false);
    }).catch((err: any) => {
        toast('error', err.response.data.message);
        setIsLoading(false);
    })
};

export const getNotifications = async (fcmToken: string) => {
    try {
        const response = await axios.post(`auth/getFireBaseNotification?=&gcmId=${fcmToken}&message=hai`)
        return response.data
    }
    catch (error: any) {
        return error;
    }

}