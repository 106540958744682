import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { getTemplateTrackingAnalytics, getTemplateTrackingRecords } from "../../services/templateService";
import BizTableReports from "../../common/BizTableReports";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { BsCalendar } from "react-icons/bs";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Input,
  PopoverBody,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
  Dropdown,
  Button,
  Spinner,
  OverlayTrigger,
  Overlay,
  Popover,
  Form,
  ListGroup,
  Tooltip,
} from "react-bootstrap";
import DateRangePicker from "react-daterange-picker";
import excelIcon from "../../assets/img/excel.png";
import * as XLSX from "xlsx";
import getAdjustedDateRange from "../../utils/getAdjustedDateRange";
import { dateSplit } from '../../common/commonfns';
import SyncButton from "../../common/syncButton";
import { toast } from "../../common/alert";
const moment = extendMoment(originalMoment);
enum DateFilterType {
  Past7Days = "Past7Days",
  Past30Days = "Past30Days",
  Past90Days = "Past90Days",
  ThisWeek = "ThisWeek",
  PreviousWeek = "PreviousWeek",
  ThisMonth = "ThisMonth",
  PreviousMonth = "PreviousMonth",
  CustomRange = "CustomRange",
}
type Props = {};
const TemplateAnalytics: React.FC<Props> = (props: Props) => {
  const [data, setData] = useState<any>([]);
  const { templateId, templateName } = useParams();
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid.value
  );
  const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datepickertarget = useRef(null);
  const filtertarget = useRef(null);
  const targetMember = useRef(null);
  const targetType = useRef(null);
  const currentDate = new Date();
  const today = moment().clone().startOf("day");
  const [dateRangeValue, setDateRangeValue] = useState(
    moment.range(
      today.clone().subtract(7, "days").startOf("day"),
      today.endOf("day")
    )
  );
  const { start: adjustedStart, end: adjustedEnd } = getAdjustedDateRange(
    dateRangeValue.start,
    dateRangeValue.end
  );
  const navigate = useNavigate();
  const tableData = useMemo(() => {
    return Object.keys(data).map((date) => ({ date, ...data[date] }));
  }, [data]);
  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed (0-11)
    const year = date.getUTCFullYear();
    // Format day, month, and year as DD-MM-YYYY
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  };
  const exportExcel = async (cell: any, row: any) => {
    const { date } = row;
    const Ids = {
      templateId,
      channelUid,
    };
    try {
      const response = await getTemplateTrackingRecords(Ids, date);
      if (response) {
        // Create a new workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet([]);
        // Apply custom headers to the worksheet
        const headers = [
          'Template Name',
          templateName,
          '',
          '',
          '',
          '',
          '',
        ];
        const subHeaders = [
          'Contact Name',
          'Mobile Number',
          'Status',
          'Reactions',
          'Replied',
          'Unsubscribed',
          'Clicked',
          'Error Code',
          'Error Description',
        ];
        // Add headers to the worksheet
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
        XLSX.utils.sheet_add_aoa(ws, [subHeaders], { origin: 'A2' });
        // Convert response data to an array of arrays (if needed) and add it below the headers
        const data = response.map((item: any) => [
          item.name,
          item.mobileNumber,
          item.status,
          item.reactions,
          item.replyMessages,
          item.unSubscribed,
          item.clickedUrl,
          item.errorCode,
          item.errorMessage,
        ]);
        // Add the data to the worksheet starting from row 3 (below the headers)
        XLSX.utils.sheet_add_aoa(ws, data, { origin: 'A3' });
        // Set worksheet columns with headers and formatting
        ws['!cols'] = [
          { width: 20 }, // Contact Name
          { width: 27 }, // Mobile Number
          { width: 12 }, // Delivery Status
          { width: 23 }, // Sent Timestamp
          { width: 15 }, // Replied Messages
          { width: 15 }, // User Reactions
          { width: 30 }, // Clicked Links
        ];
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // Generate and save the Excel file
        XLSX.writeFile(wb, `${date}-${templateName}.xlsx`);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        sticky: "left",
        Cell: ({ cell, row }: any) => <p title={moment(cell.value).format("MMM YYYY h:mm A")}>{dateSplit(cell.value)}</p>,
      },
      // {
      //   Header: "Recipients",
      //   accessor: "totalRecipients",
      // },
      {
        Header: "Sent",
        accessor: "totalSentCount",
      },
      {
        Header: "Delivered",
        accessor: "totalDeliveredCount",
      },
      {
        Header: "Read",
        accessor: "totalReadCount",
      },
      {
        Header: "Replied",
        accessor: "totalRepliedCount",
      },
      {
        Header: "Clicked",
        accessor: "totalClicksCount",
      },
      {
        Header: "Reactions",
        accessor: "totalReactionCount",
      },
      {
        Header: "Failed",
        accessor: "totalFailedCount",
      },
      // {
      //   Header: "UnSubscribed",
      //   accessor: "unSubscribed",
      // },
      {
        Header: "Action",
        accessor: "edit",
        Cell: ({ cell, row }: any) => <img alt="Report" src={excelIcon} onClick={() => exportExcel(cell, row.original)} width={20} />
        ,
      }
    ],
    []
  );
  const fetchData = useCallback(
    async (pageSize: number, currentPage: number) => {
      const startDate = formatDate(adjustedStart.valueOf());
      const endDate = formatDate(adjustedEnd.valueOf());
      const listPayload = {
        from: startDate,
        to: endDate
      };
      const Ids = {
        templateId,
        channelUid,
      };
      const response = await getTemplateTrackingAnalytics(Ids, listPayload);
      if (response) {
        setData(response);
        return { data: response };
      } else {
        return { data: [], totalPages: 0 };
      }
    },
    [templateId, channelUid, dateRangeValue]
  );
  const getStartDate = (
    subtractDays: number,
    unit: moment.unitOfTime.DurationConstructor = "days"
  ) => {
    return today.clone().subtract(subtractDays, unit).startOf(unit);
  };
  const changeDateFilterHandler = (event: any) => {
    const filter = event.target.value as DateFilterType;
    switch (filter) {
      case DateFilterType.Past7Days:
        setDateRangeValue(
          moment.range(
            today.clone().subtract(7, "days").startOf("day"),
            today.endOf("day")
          )
        );
        setShowDatePicker(false);
        break;
      case DateFilterType.Past30Days:
        setDateRangeValue(moment.range(getStartDate(30), today.endOf("day")));
        setShowDatePicker(false);
        break;
      case DateFilterType.Past90Days:
        setDateRangeValue(moment.range(getStartDate(90), today.endOf("day")));
        setShowDatePicker(false);
        break;
      case DateFilterType.ThisWeek:
        setDateRangeValue(
          moment.range(today.clone().startOf("week"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;
      case DateFilterType.PreviousWeek:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "week"),
            getStartDate(1, "week").endOf("week")
          )
        );
        setShowDatePicker(false);
        break;
      case DateFilterType.ThisMonth:
        setDateRangeValue(
          moment.range(today.clone().startOf("month"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;
      case DateFilterType.PreviousMonth:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "month"),
            getStartDate(1, "month").endOf("month")
          )
        );
        setShowDatePicker(false);
        break;
      case DateFilterType.CustomRange:
        setShowDatePicker(true);
        break;
      default:
        break;
    }
    // setCurrentData((item: any) => ({ ...item, startTime: adjustedStart.valueOf(),endTime: adjustedEnd.valueOf() }));
    setDateFilter(filter);
  };

  return (
    <div>
      <div className="d-flex h5">
        <FaArrowLeft
          color="black"
          className="me-2"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div>{templateName} - Analytics</div>
      </div>
      <Row className="pb-2">
        <Col md={6}></Col>
        <Col md={1} className="d-flex justify-content-end">
          <SyncButton onClick={() => {
            fetchData(0, 20)
            setTimeout(() => {
              toast("success", "Template Analytics Synced Successfully");
            }, 1000)
          }
          } />
        </Col>
        <Col className="p-0">
          {" "}
          <div className="date-filter-selector">
            <Input
              className={`dateFilter-dateFilter`}
              type="select"
              size={1}
              value={dateFilter}
              style={{
                fontFamily: '"Public Sans", sans-serif',
                fontSize: "14px",
                padding: "8px 25px",
              }}
              onChange={changeDateFilterHandler}
            >
              <option value={DateFilterType.Past7Days}>Past 7 days</option>
              <option value={DateFilterType.Past30Days}>Past 30 days</option>
              <option value={DateFilterType.Past90Days}>Past 90 days</option>
              <option value={DateFilterType.ThisWeek}>This week</option>
              <option value={DateFilterType.PreviousWeek}>Previous week</option>
              <option value={DateFilterType.ThisMonth}>This month</option>
              <option value={DateFilterType.PreviousMonth}>
                Previous month
              </option>
              <option value={DateFilterType.CustomRange}>Custom range</option>
            </Input>
          </div>
        </Col>
        <Col>
          <div className="date-range-picker">
            <Overlay
              target={datepickertarget.current}
              show={showDatePicker}
              placement="bottom"
              rootClose={true}
              onHide={() => setShowDatePicker(false)}
            >
              <Popover id="popover-basic" style={{ maxWidth: "350px" }}>
                <DateRangePicker
                  value={dateRangeValue}
                  minDate={currentDate}
                  onSelect={(value: any) => {
                    setDateRangeValue(
                      moment.range(
                        moment(value.start).startOf("day"),
                        moment(value.end).endOf("day")
                      )
                    );
                    // setCurrentData((item: any) => ({ ...item, startTime: adjustedStart.valueOf(),endTime: adjustedEnd.valueOf() }));
                    setDateFilter(DateFilterType.CustomRange);
                    setShowDatePicker(false);
                    // campaignFilterQuery.refetch();
                  }}
                  maximumDate={today.endOf("day")}
                  singleDateRange={true}
                />
              </Popover>
            </Overlay>
            <div
              className={`form-control calenderInput-alignDatepicker`}
              ref={datepickertarget}
              onClick={() => { setShowDatePicker((current) => !current) }}
            >
              <BsCalendar />
              <span className="value ml-2">
                {dateRangeValue.start.format("DD/MM/YYYY")}
                {" - "}
                {dateRangeValue.end.format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <BizTableReports
        fetchData={fetchData}
        data={tableData}
        columns={columns}
        tableName="TEMPLATE_ANALYTICS"
      />
    </div>
  );
};
export default TemplateAnalytics;