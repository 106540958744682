/**
 * Page header for bot list, flow list.
 */

import React, { useContext, useState } from 'react';

import styles from './listPageHeader.module.css';
import SearchBox from './searchBox';
import { MdArrowBack } from 'react-icons/md';
import { Link } from 'react-router-dom';
import TooltipReference from "../../../common/tooltip";
import { planCodes } from '../../../common/commonfns';
import { Position } from 'reactflow';
import { Button, Form as FormBootstrap, Row, Col, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { on } from 'process';
import initialContext from '../designFlow/editor/useContext';
import { getAddonCode } from '../../../common/commonfns';

const ListPageHeader: React.FC<any> = ({ title, createLabel, action, onCreate, value, onChange, placeholder, back, data, business, botName, botCount, isInvalidAccess }) => {
    const datalength = data?.length;
    const initial = useContext(initialContext);
    return (
        <>
            <div className={styles.grid}>
                <div style={{ flex: 1 }}></div>
                <div>
                    <InputGroup className="searchDesign rounded ">
                        <InputGroup.Text className="searchIcon hideBorder iconsColor">
                            <FontAwesomeIcon icon={faSearch} aria-hidden="true" />
                        </InputGroup.Text>
                        <input
                            className="form-control hideBorder"
                            type="search"
                            placeholder={placeholder} // You can replace this with a static placeholder if needed
                            value={value || ""}
                            onChange={onChange}
                        // readOnly
                        />
                        {value && (
                            <InputGroup.Text className="searchIcon hideBorder iconsColor">
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    aria-hidden="true"
                                    onClick={onChange}
                                />
                            </InputGroup.Text>
                        )}
                    </InputGroup>
                    {/* <SearchBox placeholder={placeholder} value={value} onChange={onChange} /> */}
                </div>
                <div className={`${!isInvalidAccess ? "pointer" : "notAllowed"}`}>
                    {(title === 'My Bots') ?
                        <>
                            {botCount >= (business?.plan?.bots +
                                (business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('bots'))?.quantity ?
                                    business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('bots'))?.quantity : 0)
                            ) ?
                                <TooltipReference
                                    placement="bottom"
                                    content={`${isInvalidAccess ? "You dont't have permission" : `You cannot add more than ${(business?.plan?.bots +
                                        (business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('bots'))?.quantity ?
                                            business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('bots'))?.quantity : 0)
                                    )} bots in ${business?.plan?.name}`}`}
                                    tooltipId={`botlength${botCount}`}
                                >
                                    <Button variant='primary' disabled={(botCount >= (business?.plan?.bots +
                                        (business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('bots'))?.quantity ?
                                            business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('bots'))?.quantity : 0)
                                    )) || isInvalidAccess} className={[styles.crBtn].join(' ')} onClick={onCreate}>+ {createLabel}</Button>
                                </TooltipReference> :<>
                               {isInvalidAccess ? (
                                <TooltipReference
                                  placement="bottom"
                                  content="You don't have permission"
                                  tooltipId="bot"
                                >
                                  <Button
                                    variant="primary"
                                    className={styles.crBtn}
                                    disabled={isInvalidAccess}
                                    onClick={onCreate}
                                  >
                                    + {createLabel}
                                  </Button>
                                </TooltipReference>
                              ) : (
                                <Button
                                  variant="primary"
                                  className={styles.crBtn}
                                  disabled={isInvalidAccess}
                                  onClick={onCreate}
                                >
                                  + {createLabel}
                                </Button>
                              )}      
                              </>                        
                            }
                        </>
                        :
                        <>
                            {datalength >= (business?.plan?.code === planCodes('free') ? '1' : '6') ?
                                <TooltipReference
                                    placement="bottom"
                                    content={` ${isInvalidAccess ? "You don't have permission" : `You cannot add more than ${business?.plan?.code === planCodes('free') ? '1' : '6'} flows in ${business?.plan?.name}`}`}
                                    tooltipId={`botlength${datalength}`}
                                >
                                    <Button variant='primary' className={[styles.crBtn].join(' ')} onClick={onCreate} disabled={(datalength >= (business?.plan?.code === planCodes('free') ? '1' : '6')) || isInvalidAccess}>+ {createLabel}</Button>
                                </TooltipReference> :
                                <>
                               {isInvalidAccess ? (
                                <TooltipReference
                                  placement="bottom"
                                  content="You don't have permission"
                                  tooltipId="botFlow"
                                >
                                  <Button
                                    variant="primary"
                                    className={styles.crBtn}
                                    onClick={onCreate}
                                    disabled={isInvalidAccess}
                                  >
                                    + {createLabel}
                                  </Button>
                                </TooltipReference>
                              ) : (
                                <Button
                                  variant="primary"
                                  className={styles.crBtn}
                                  onClick={onCreate}
                                  disabled={isInvalidAccess}
                                >
                                  + {createLabel}
                                </Button>
                              )}
                              </>
                            }
                        </>
                    }
                </div>
            </div>
            <div className={styles.title}>
                <h1>
                    {back ? (
                        <Link to={back} className={styles.back}>
                            <Button variant="link" style={{ outline: 'none', color: 'black' }} onClick={() => { initial.isLoading = false }}>
                                <MdArrowBack />
                            </Button>
                        </Link>
                    ) : null}
                    {title} {botName && <span className={styles.botName}>{<svg className={`${styles.botSVG} side-menu__icon`} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <path d="M15.0508 11.5032C16.1277 11.5032 17 12.3132 17 13.3131V14.0021H16.9946C16.958 14.7821 16.7275 15.7591 15.7852 16.557C14.7557 17.43 12.9777 18 10 18C7.02123 18 5.24431 17.43 4.21477 16.557C3.27246 15.7591 3.042 14.7811 3.00538 14.0021H3V13.3121C3 12.3132 3.87231 11.5032 4.94923 11.5032H15.0508ZM6.23077 3.00041C5.80234 3.00041 5.39146 3.15844 5.08852 3.43974C4.78558 3.72103 4.61538 4.10255 4.61538 4.50037V8.50026C4.61538 8.89807 4.78558 9.27959 5.08852 9.56089C5.39146 9.84219 5.80234 10.0002 6.23077 10.0002H13.7692C14.1977 10.0002 14.6085 9.84219 14.9115 9.56089C15.2144 9.27959 15.3846 8.89807 15.3846 8.50026V4.50037C15.3846 4.10255 15.2144 3.72103 14.9115 3.43974C14.6085 3.15844 14.1977 3.00041 13.7692 3.00041H10.5385V2.50042C10.5415 2.43401 10.5297 2.36773 10.5037 2.30579C10.4777 2.24385 10.4381 2.18759 10.3875 2.14059C10.3369 2.09358 10.2763 2.05685 10.2096 2.03273C10.1429 2.0086 10.0715 1.99761 10 2.00044C9.70277 2.00044 9.46154 2.23043 9.46154 2.50042V3.00041H6.23077ZM6.76923 6.50031C6.76923 6.2351 6.88269 5.98076 7.08465 5.79323C7.28662 5.60569 7.56054 5.50034 7.84615 5.50034C8.13177 5.50034 8.40569 5.60569 8.60765 5.79323C8.80962 5.98076 8.92308 6.2351 8.92308 6.50031C8.92308 6.76552 8.80962 7.01987 8.60765 7.2074C8.40569 7.39493 8.13177 7.50029 7.84615 7.50029C7.56054 7.50029 7.28662 7.39493 7.08465 7.2074C6.88269 7.01987 6.76923 6.76552 6.76923 6.50031ZM11.0769 6.50031C11.0769 6.2351 11.1904 5.98076 11.3923 5.79323C11.5943 5.60569 11.8682 5.50034 12.1538 5.50034C12.4395 5.50034 12.7134 5.60569 12.9153 5.79323C13.1173 5.98076 13.2308 6.2351 13.2308 6.50031C13.2308 6.76552 13.1173 7.01987 12.9153 7.2074C12.7134 7.39493 12.4395 7.50029 12.1538 7.50029C11.8682 7.50029 11.5943 7.39493 11.3923 7.2074C11.1904 7.01987 11.0769 6.76552 11.0769 6.50031Z" />
                    </svg>}{' '}{botName}{' '}</span>}
                </h1>
                {action ? action : null}
            </div>
        </>
    );
};

export default ListPageHeader;