import React, { useState, useEffect, useCallback } from 'react';
import { getAllCatalogue, createLog, updateLog, deleteLog } from '../../services/catalogueService';
import { useSelector } from 'react-redux';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Catalogue.scss';
import RightSidebar from "../../common/rightSidebar";
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { Label, FormGroup, Button, Row,Col, } from 'reactstrap';
import * as Yup from 'yup';
// import { CatalogueMeta } from './MetaCatalogue';
// import { ProductLog } from './ProductLog';
import { rupeesToPaisa, paisaToRupees } from '../../common/commonfns';
import BizTable  from "../../common/BizTable";
import SearchInput from "../../common/searchComponent";
import TooltipReference from "../../common/tooltip";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Catalogue =() =>{
    const [allData, setAlldata] = useState<any>([]);
    const [showContent, setContent] = useState<boolean>(false);
    const [showEdit, setEdit] = useState<boolean>(false);
    const [selectedRow, setRow] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const channel :any = useSelector ((state: any) => state.cartreducer.channelUid);
    const [searchTerm, setSearchTerm] = useState<string | null>();
    const [count,setCount] = useState<number>(0);
    const [paginationPayload, setPaginationPayload] = useState({
      pageIndex: 0,
      pageSize: 10,
      searchText: '',
      currentPage: 0
    });
    const isFreeTrail = useSelector((state: any) => state.cartreducer.business?.business?.freeTrial)? true : false;
    
    const {currentPage} = paginationPayload;

    const [isInvalidAccess, setIsInvalidAccess] = useState(false);
    const roleRules = useSelector((state: any) => state.cartreducer.roleRules);
  
    useEffect(() => {
      if (roleRules) {
        const isInvalidAccess = roleRules.canViewCatalogue === true && roleRules.canCreateAndManageCatalogue === false;
        setIsInvalidAccess(isInvalidAccess);
      }
    }, [roleRules]);

 const columns = [
  {
    Header: (
      <input
        type="checkbox"
        hidden={true}
        // checked={this.state.allChecked}
        // onChange={this.toggleAllCheckboxes}
      />
    ),
    accessor: "checkbox",
    // Cell: this.checkboxCellRenderer,
    sticky: "left",
  },
    {
      Header: "S.No",
      accessor: "serialNumber",
      sticky: "left",
      Cell: ({ row, state }: any) => {
        if(searchTerm){
          return row.index + 1
        }
        else{
          const { pageSize, currentPage } = paginationPayload;
          return (pageSize * currentPage) + row.index + 1;
        }
      },
      // Cell: ({ cell, row }: any) =>console.log( row.index * currentPage,row.index,currentPage),
    },
    // {
    //   Header: "Mobile Number",
    //   accessor: "mobileNumber",
    //   // Cell: ({ cell, row }: any) => this.mobileData(cell.value, row.original),
    // },
    {
      Header: "Product name",
      accessor: "name",
      Cell: ({ cell, row }: any) => <div className="text-wrap">{cell.value}</div>,
      // Cell: ({ cell, row }: any) => this.emailData(cell.value, row.original),
      grid: "third-column",
    },
    {
      Header: "Product code",
      accessor: "code",
      grid: "col-md-1",
      Cell: ({ cell, row }: any) => <div className="text-wrap" title={cell.value}>{cell.value?.length > 30? cell.value?.slice(0,29) + '...' : cell.value}</div>,
      // Cell: ({ cell, row }: any) => contactDataFormat(cell.value, row.original),
    },
    {
      Header: "Amount",
      accessor: "amount",
      grid: "col-md-1",
      Cell: ({ cell, row }: any) => <span className='nofont'>₹{paisaToRupees(cell.value)}</span>,
    },
    {
      Header: "Sales amount",
      accessor: "saleAmount",
      Cell: ({ cell, row }: any) => row.original?.saleAmount < 0 ? '-' : <span className='nofont'>₹{paisaToRupees(cell.value)}</span>,
      grid: "col-md-1",
    },
    {
      Header: "Action",
      accessor: "status",
      Cell: ({ cell, row }: any) => renderEditColumn(cell, row),
      grid: "col-md-1",
    },

  ] as any;

  const fetchData = useCallback(async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {
    // const current = currentPage;
    // const limit = paginationLimit.current;
    // const page = currentPage > 0 ? currentPage - 1 : 0; 
    setPaginationPayload({
      pageIndex: pageIndex,
      pageSize:  pageSize,
      searchText: searchText,
      currentPage: currentPage
    })

    const listPayload = {
      page: searchTerm ? 0 : currentPage,
      limit: pageSize,
      search: searchTerm
    };
    const businessId = channel?.channelAllData?.business?.uid;
//cmd for getAllData
    if(businessId){
    const response = await getAllCatalogue(businessId,listPayload);

    if (response) {
      const { digitalItem,count } = response;
      const countData = searchTerm ? digitalItem?.length : count;
      setCount(countData)
      setAlldata(digitalItem);
      setIsLoading(false);
   
      return {data:digitalItem,totalPages:100}
    }else {
      return {data:[],totalPages:0}
    }
    }
    else{
      return {data:[],totalPages:0}
    }
  }, [channel?.channelAllData?.business?.uid, searchTerm]);
 


    const handleEdit = (cell:any, row:any) => {
        setEdit(true);
        setRow(row);
        setContent(true);
    }
    const handleDelete = async(cell:any, row:any) =>{
        await deleteLog(row.original?.uid);
        fetchData(0,20,'',0);
        // getAllData();
    }
    
    const renderEditColumn = (cell: any, rowData: object) => {
        return (
          <div className={`ActionField ${isInvalidAccess ? "notAllowed" : "pointer"} `}>            
            <div
            className={`ml-5 ${isInvalidAccess ? "disabledState" : ""}`} 
            onClick={
              () => handleEdit(cell, rowData)}>
              <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
                stroke="#666E7D"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
                stroke="#666E7D"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            </div>
    
            <div onClick={
          () => handleDelete(cell, rowData)} className={`mr-5 ${isInvalidAccess ? "disabledState" : ""} `}>   
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4H3.33333H14"
                  stroke="#666E7D"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
                  stroke="#666E7D"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.66602 7.3335V11.3335"
                  stroke="#666E7D"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.33398 7.3335V11.3335"
                  stroke="#666E7D"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
    
          </div>
        );
      };
    
    // const getAllData = async () => {
    //   setIsLoading(true);
    //     try {
    //       const response = await getAllCatalogue(channel?.channelAllData?.business?.uid);
    //       const data = response.data;
    //       setAlldata(data);
    //       setIsLoading(false);
    //     } catch (error) {
    //       setIsLoading(false);
    //       console.error('Error:', error); 
    //     }
    //   };

    useEffect(() => {
      // if(activeTab === '1'){
        // getAllData();
      // }
      }, [channel]);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Product name is required'),
        code: Yup.string().required('Product code is required'),
        amount: Yup.string()
        .matches(/^\d*\.?\d*$/, 'Amount must be a number')
        .required('Amount required'),
        salesAmount: Yup.string()
  .test('is-valid-amount', 'Sales amount must be a valid number greater than or equal to 1', function (value) {
    const amount = parseFloat(value as any);
    return  !amount || amount >= 1;
  })
  .test('is-less-than-amount', 'Sales amount must not be greater than the specified amount', function(value) {
    const parentAmount = this.parent.amount;
    const amount = parseFloat(parentAmount || '0'); 
    return !value || parseFloat(value) <= amount;
  })

      

        // salesAmount: Yup.string()
        // .matches(/^\d*\.?\d*$/, 'Sales amount must be a number')
        // .required('Sales amount required'),
    
      });

    let serialNumber = 0;
    const indexFunc = () => {
        serialNumber++;
        return (
            <div>{serialNumber+ '.'}</div>
        );
    };
    
  
    const showContentFunc = ()=> {
        setContent(true);
    }
    const handleCancel = ()=> {
        setContent(false);
        setEdit(false);
    }

    const onSearchChange = (searchTerm: string) => {
      const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
      setSearchTerm(searchTermCheck);
    };

    const handleSubmit = async(values: any)=> {
        const payload = {
            "name":values.name,
            "code": values.code,
            "amount":  rupeesToPaisa(values.amount),
            "saleAmount": values.salesAmount? rupeesToPaisa(values.salesAmount) : -1,
        }
        if(!showEdit){
        const data = [];
        data.push(payload);
          const res =   await createLog(channel?.channelAllData?.business?.uid, data);
          if(res){
            fetchData(0,20,'',0);
          }
        }else{
            await updateLog(selectedRow?.original?.uid, payload);
            fetchData(0,20,'',0);
        }
        // getAllData(); 
        setContent(false);
    }

    // const dataWithSerialNum = allData.map((item: any, index: any) => ({
    //   ...item,
    //   serialNumber: index + 1
    // }));

    return(
        <div>
            <h5 className='mt-3'>Catalogues</h5>
            {/* <FacebookOnboarding/> */}
            {/* <Nav >
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "activepay" : ""}
                  onClick={() => setTab("1")}
                >
                  <h6>BizMagnet Catalogue</h6>
                </NavLink>
              </NavItem>
              <NavItem>
            <NavLink
              className={activeTab === '2' ? 'activepay' : ''}
              onClick={() => setTab("2")}
            >
              <h6>Meta Catalogue</h6>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '3' ? 'activepay' : ''}
              onClick={() => setTab("3")}
            >
              <h6>Products</h6>
            </NavLink>
          </NavItem>
            </Nav> */}
            {/* {activeTab === '1'?<> */}
            <Row className='pt-3 pb-2'>
              <Col md={3}>
            <SearchInput component="flow" onSearchChange={onSearchChange} 
          placeHolder="Search Catalogue"
          />
              </Col>
              <Col>
              </Col>
              <Col md={2} className={`button-global-align ${isInvalidAccess ? "notAllowed" : "pointer"} `}>
              {isFreeTrail? 
                <TooltipReference
                placement="right"
                content="Unable to Add Items in Trial Account"
                tooltipId="buttons"
              >
                <Button type='button' className='sendButton' disabled={isFreeTrail || isInvalidAccess}><FontAwesomeIcon icon={faPlus} /> Add Item</Button>
              </TooltipReference> : 
                <>{isInvalidAccess ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip">You don't have access to add items</Tooltip>}
                  >
                    <span>
                      <Button
                        type="button"
                        className="sendButton"
                        disabled
                      >
                        <FontAwesomeIcon icon={faPlus} /> Add Item
                      </Button>
                    </span>
                  </OverlayTrigger>
                ) : (
                  <Button
                    type="button"
                    className="sendButton"
                    onClick={() => showContentFunc()}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add Item
                  </Button>
                )}</>
            }</Col>
            </Row>
            {isLoading ? (
          <div className="centerItemsWithWidth">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className='Cat'>
            {/* <BootstrapTable
            data={dataWithSerialNum}
            search
            pagination={dataWithSerialNum?.length > 10}
            version="4"
            striped
            hover
            containerStyle={{ textAlign: "left" }}
            options={{
              headerAlign: "center",
              noDataText: "No records found",
              sortIndicator: true,
              hidePageListOnlyOnePage: true,
              clearSearch: false,
              alwaysShowAllBtns: true,
              sizePerPageList: [10, 25, 50, 100],
              withFirstAndLast: true,
              sizePerPage: 10,
              paginationPosition: "bottom",
            } as any}
          >
            <TableHeaderColumn dataField="id" isKey hidden>
              #
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="80"
              dataField='serialNumber'
              dataFormat={(cell,row)=> row.serialNumber + '.'}
            >
              <b>S.No</b>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="name"
              dataAlign="left"
              width="80"
              columnClassName="text-wrap"
            >
              <b>product name</b>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="code"
              dataAlign="left"
              width="80"
              columnClassName="text-wrap"
            >
              <b>product code</b>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="amount"
              dataAlign="center"
              width="80"
              dataFormat={cell => cell? <span className='nofont'>₹{paisaToRupees(cell)}</span> : '-'}
            >
              <b>amount</b>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="saleAmount"
              dataAlign="center"
              width="80"
              dataFormat={(cell, row) => row.saleAmount < 0 ? '-' : <span className='nofont'>₹{paisaToRupees(cell)}</span> }
            >
              <b>sales amount</b>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="status"
              dataAlign="center"
              width="80"
              dataFormat={renderEditColumn}
            >
              <b>action</b>
            </TableHeaderColumn>
          </BootstrapTable> */}
               <BizTable
              columns={columns}
              fetchData={fetchData}
              counts={count}
              refetchData={allData}
              tableName='CATALOGUE'
            /> 
        </div>
        {/* // : activeTab === '2'? <CatalogueMeta/> : <ProductLog/> */}
      
           {showContent && (
                <RightSidebar handleCancel={handleCancel} title={!showEdit ? "Add Item" : "Edit Item"} width="425px">
                  <Formik
                    initialValues={{
                      name: showEdit ? selectedRow?.original?.name : '',
                      code: showEdit ? selectedRow?.original?.code : '',
                      amount: showEdit? paisaToRupees(selectedRow?.original?.amount) :'',
                      salesAmount: showEdit? selectedRow?.original?.saleAmount < 0? '': paisaToRupees(selectedRow.original.saleAmount) : '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnChange={true}
                  >
                    {({ values, setFieldValue }) => (
                      <Form className="pl-3 pr-3 mt-3 ml-1">
                        <FormGroup className="form-group">
                          <Label className="text-dark"> Product Name </Label>
                          <Field type="text" name="name" id="name" placeholder="Enter product name" className="form-control" />
                          <ErrorMessage name="name" component="div" className="text-danger" />

                          <Label className="text-dark mt-3"> Product Code </Label>
                          <Field type="text" name="code" id="code" placeholder="Enter code" className="form-control" />
                          <ErrorMessage name="code" component="div" className="text-danger" />

                          <Label className="text-dark mt-3"> Amount </Label>
                          <Field type="text" name="amount" id="amount" placeholder="Enter amount"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                let value = e.target.value.replace(/[^0-9.]/g, ""); 
                                value = value.replace(/^(\d*\.\d*)\..*$/, '$1'); 
                                setFieldValue("amount", value);
                                }} className="form-control" />
                          <ErrorMessage name="amount" component="div" className="text-danger" />

                          <Label className="text-dark mt-3"> Sales Amount </Label>
                          <Field type="text" name="salesAmount" id="salesAmount" placeholder="Enter sales amount"
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let value = e.target.value.replace(/[^0-9.]/g, ""); 
                            value = value.replace(/^(\d*\.\d*)\..*$/, '$1'); 
                            setFieldValue("salesAmount", value);
                            }} className="form-control" />
                          <ErrorMessage name="salesAmount" component="div" className="text-danger" />

                           </FormGroup>
                        <div className="d-flex justify-content-end">
                          <Button
                            type="button"
                            className="cancelButton w-25 mt-2 mr-2 rounded"
                            onClick={handleCancel}
                          >Cancel</Button>
                          <Button
                            type="submit"
                            className="signUpBtn w-30 pl-4 pr-4"
                          > Add Item</Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </RightSidebar>
              )}
              </>
            )}
        </div>
    )
}

export default Catalogue;