import moment from "moment";

import axios from "../utils/axios";
import { showAlert, toast, sweetalert } from "../common/alert";
import { useSelector } from "react-redux";

export const previewTemplate = async (templateId: any, business_uid: any) => {
  try {
    const response = await axios.get(
      `template/${templateId}/getTemplate?uid=${business_uid}`
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const ContactsGetAll = (ContactList: any) => {
  try {
    const response = axios.post("contact/getAll", ContactList);

    return response;
  } catch (error: any) {
    toast("error", error.response.data.message);
  }
};

export const groupList = async (channelId: string) => {
  try {
    const response = await axios.get("community/getAll", {
      params: {
        channelId: channelId, // replace with actual channel ID
        page: 0,
        limit: 1000,
      },
    });
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const getAllCommunity = async (
  channelId: string,
  communityPayload: any
) => {
  try {
    const response = await axios.post(
      `community/${channelId}/getAll`,
      communityPayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const CreateContactList = async (
  businessUid: string,
  contactList: any,
  memberUid: string,
  channelId: string
) => {
  try {
    const addContactPayload = contactList.map((item: any) => ({
      name: item.name,
      mobileNumber: item.mobileNumber,
      email: item.email,
      countryCode: item.countryCode,
      optIn: true,
      tagsName: item.tags,
    }));
    const createPayload = {
      memberId: memberUid,
      businessUid: businessUid,
      channelList: [channelId],
      contactsList: addContactPayload,
    };
    const response = await axios.post(
      `contact/createlistofcontact`,
      createPayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.response.data.message);
  }
};

export const CreateCommunity = async (
  community: any,
  createData: any,
  status: string,
  channelId: string,
  memberId: string
) => {
  try {
    const method = status === "MANUAL" ? "Group" : "Excel";
    const communityPayload = {
      name: community,
      createdMemberId: memberId,
      uploadMethod: method,
      contacts: createData,
    };

    const communityData = await axios.post(
      `community/${channelId}/create`,
      communityPayload
    );

    const responseData = communityData.data;
    toast("success", "Community created successfully");
    // await groupList(channelId);
    return { success: status, newCommunityData: responseData };
  } catch (error: any) {
    toast("error", error.response.data.message);
  }
};

export const UpdateCommunity = async (
  communityPayload: any,
  channelId: string
) => {
  try {
    const response = await axios.patch(
      `community/${channelId}/update`,
      communityPayload
    );
    toast("success", "Community updated successfully");
    return response;
  } catch (error: any) {
    toast("error", error.response.data.message);
  }
};

export const GetCommunity = async (communityUid: any, channelId: string) => {
  try {
    const response = axios.get("community/getCommunity", {
      params: {
        channelId: channelId, // replace with actual channel ID
        uid: communityUid, // replace with actual UID
      },
    });
    return response;
  } catch (error: any) {
    toast("error", error);
  }
};

export const getCommunityContacts = async (
  communityUid: string,
  communityPayload: any
) => {
  try {
    const response = axios.post(
      `community/${communityUid}/contacts`,
      communityPayload
    );
    return response;
  } catch (error: any) {
    toast("error", error);
  }
};

export const deleteContactsInCommunity = async (
  channelUid: string,
  payload: any
) => {
  try {
    const response = axios.patch(`community/${channelUid}/update`, payload);
    return response;
  } catch (error: any) {
    toast("error", error);
  }
};

interface CommunityType {
  uid: number;
}
export const DeleteCommunity = async (community_id: CommunityType) => {
  try {
    const { uid } = community_id;

    return new Promise(async (resolve, reject) => {
      sweetalert(
        "warning",
        "Are you sure you want to delete this community?",
        async () => {
          if (uid) {
            try {
              const response = await axios.delete(`/community/${uid}/delete`);
              if (response.data === "SUCCESS") {
                resolve(response.data); // Resolve the promise with the success result
                toast("success", "Community deleted successfully");
              } else {
                resolve("ERROR"); // Resolve with an error message or a specific error value
              }
            } catch (error) {
              console.error("Error deleting member:", error);
              resolve("ERROR"); // Resolve with an error message or a specific error value
            }
          }
        },
        () => {
          resolve("CANCEL"); // Resolve with a cancel result if the user cancels the action
        }
      );
    });
  } catch (error) {
    console.error("Error in DeleteCommunity:", error);
    return "ERROR"; 
  }
};

export const createBroadcast = async (
  broadCastData: any,
  channelId: string,
  props: any,
  showlink: boolean
) => {
  const typeOfPeriod = broadCastData?.check?.selectPeriod;
  const { memberUid, existCommunityData } = props;
  // console.log("checking", broadCastData);

  const format = broadCastData?.selectedTemplate?.components[0]?.format;
  // const uploadFiles = format === 'IMAGE' || format === 'VIDEO' ? broadCastData?.selectedTemplate?.components[0]?.example?.header_handle?.[0] : null
  const uploadMedia =
    format === "IMAGE" || format === "VIDEO"
      ? broadCastData?.selectedTemplate?.media
      : null;

  const selectedPeriodType =
    typeOfPeriod === "date" ? "scheduled" : "immediate";
  const MemberUid = broadCastData?.selectMember?.id;
  const teamId = broadCastData?.selectedTeam?.id;
  const replyAssign =
    broadCastData?.check?.assign === "bot"
      ? "1"
      : broadCastData?.check?.assign === "member"
      ? "2"
      :broadCastData?.check?.assign === "team"?"3":
"0";

  // const marketingOpt = broadCastData?.marketingOpt === true ? false : true;

  let communit_Lists;
  if (existCommunityData && existCommunityData?.length > 0) {
    communit_Lists = existCommunityData;
  }
  else if(broadCastData?.checkExistCommunity.length > 0){
    communit_Lists = broadCastData?.checkExistCommunity
  }
  else if (broadCastData?.selectedSuccess === 'MANUAL') {
    communit_Lists = [broadCastData?.selectedDataManualsCommunity]
  }
  else if (broadCastData?.selectedSuccess === 'EXCEL') {
    communit_Lists = [broadCastData.selectedDataExcelCommunity]
  }

    const urlIndices =  broadCastData?.selectedTemplate?.cards.length===0 && broadCastData?.selectedTemplate?.buttonColumns
      ?.map((button: any, index: number) =>
        button?.includes("URL1") || button?.includes("URL2") ? index : null
      )
      .filter((index: number | null) => index !== null);

      const { components,category, headerColumns } = broadCastData?.selectedTemplate;

      const carouselTextcheck= broadCastData?.tempVariables
      ?.filter((variable: any) => variable.isCarousel === true)
      .map((variable: any, index: number) => ({
        componentType: variable?.isButton || variable?.componentType === "button" ? "button" : "body",
        ownValue: variable.Check ?? variable.ownValue,
        bodyText: variable.value ?? variable.bodyText,
        position: variable?.isButton ? urlIndices.shift() : index + 1,
      })) || [];
      const templateTexts = 
      broadCastData?.tempVariables
        ?.filter((variable: any) => variable.isCarousel === false ||variable.carouselText===null)
        .map((variable: any, index: number) => ({
          componentType: variable?.isButton || variable?.componentType === "button" ? "button" : "body",
          ownValue: variable.Check ?? variable.ownValue,
          bodyText: variable.value ?? variable.bodyText,
          position: variable?.isButton ? urlIndices.shift() : category === "AUTHENTICATION" ? 0 : index + 1,
        })) || [];
        if (broadCastData?.selectedTemplate?.components[1]?.type==="CAROUSEL") {
          templateTexts.push({
            componentType: "carousel",
            carouselText: [broadCastData.selectedTemplate?.components[1]],
          });
        }

        components?.forEach(({ type, buttons, format, limited_time_offer }:any) => {
          const [firstButton] = buttons || [];
          if (
            type === "BUTTONS" &&
            firstButton?.type?.toUpperCase() === "OTP" &&
            firstButton?.otp_type?.toUpperCase() === "COPY_CODE"
            && broadCastData?.repeatCampaign === false
          ) {
            templateTexts.push({
              componentType: 'button',
              ownValue: false,
              bodyText: firstButton.example,
              position: 0,
            });
          } else if(format === "LOCATION") {
            templateTexts.push({
              componentType: "header",
              ownValue: false,
              bodyText: headerColumns?.[0]? headerColumns?.[0]?.label : headerColumns?.label,
              position: 1,
              metaData: {
              latitude: headerColumns?.[0]? headerColumns?.[0]?.latitude : headerColumns?.latitude,
              longitude: headerColumns?.[0]? headerColumns?.[0]?.longitude : headerColumns?.longitude,
              name: headerColumns?.[0]? headerColumns?.[0]?.name : headerColumns?.name,
              address: headerColumns?.[0]? headerColumns?.[0]?.address : headerColumns?.address
              }
          })
          } else if(type === 'LIMITED_TIME_OFFER'){
            templateTexts.push({
              componentType: "limited_time_offer",
              ownValue: false,
              bodyText: limited_time_offer?.example?.[0],
              position: 1
            })
          }
        });
        
        
  try {
    var broadcastPayload: any;
    if (showlink)
      broadcastPayload = {
        name: broadCastData.check.campaign,
        type: selectedPeriodType,
        // creatorId: "oKYl5mrJdVhcUQi2NHVaxPSk6sA2", //memberId
        creatorId: memberUid,
        replyAssignTo: replyAssign,
        replyAssignId:
          replyAssign === "2" ? MemberUid : replyAssign === "3" ? teamId: broadCastData?.selectedBot?.id,
        forceSent: broadCastData?.marketingOpt,
        scheduledAt: broadCastData?.UTCformat,
        templateId: broadCastData.templateId,
        communityList: communit_Lists,
        templateText: templateTexts,
  
        media: uploadMedia,
        // repeatForContacts: broadCastData?.isRepeatContact
      };
    else {
      broadcastPayload = {
        name: broadCastData.check.campaign,
        type: selectedPeriodType,
        // creatorId: "oKYl5mrJdVhcUQi2NHVaxPSk6sA2", //memberId
        creatorId: memberUid,
        replyAssignTo: replyAssign,
        replyAssignId:
        replyAssign === "2" ? MemberUid : replyAssign === "3" ? teamId : broadCastData?.selectedBot?.id,
        forceSent: broadCastData?.marketingOpt,
        scheduledAt: broadCastData?.UTCformat,
        templateId: broadCastData.templateId,
       
        communityList: communit_Lists,
        templateText: templateTexts,
        media: uploadMedia,
        // repeatForContacts: broadCastData?.isRepeatContact
      };
    }
    const response = await axios.post(
      `broadcast/${channelId}/createBroadcast`,
      broadcastPayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.response.data.message);
  }
};

export const campaignCount = async (channelId: string) => {
  try {
    const campaign_count = await axios.get(
      `broadcast/${channelId}/getBroadcastCount`
    );
    return campaign_count.data.totalCount;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const getCampaignList = async (channelId: string) => {
  try {
    const response = await axios.get(`broadcast/${channelId}/getAllBroadcast`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getCampaignOverview = async (
  channelId: string,
  // startTime: number,
  // endTime: number
  dashboradPayload:any
) => {
  if (!channelId) {
    return new Promise(() => {});
  }

  try {
    // const dashboradPayload = {
    //   fromTimeUTC:startTime,
    //   toTimeUTC:endTime,
    //   page:0,
    //   limit:10
    //   }

    const response = await axios.post(
      // `broadcast/${channelId}/getBroadcastOverview?startTime=${startTime}&endTime=${endTime}`
      `broadcast/${channelId}/getBroadcastDashboard`,dashboradPayload

    );
    const data = response.data;
    return data;
  } catch (error:any) {
    // toast('error',error?.response?.data?.message)
    console.error(error);
    // throw error;
  }
};

export const getCampaignOverviewDashboard = async (
  channelId: string,
  timePayload:any
) => {
  try {
    const response = await axios.post(
      `broadcast/${channelId}/getBroadcastOverview2`,timePayload
    );
    const data = response.data;
    return data;
  } catch (error) {

    console.error(error);
    // throw error;
  }
};
export const getBroadcastDetail = async (
  channelId: string,
  detailPayload:any
) => {
  try {
    const response = await axios.post(
      `broadcast/${channelId}/getBroadcastDetail`,detailPayload
    );
    const data = response.data;
    return data;
  } catch (error) {
    
    console.error(error);
    // throw error;
  }
};



export const getCampaignFilter = async (
  channelId: string,
  filterPayload:any

) => {
  if (!channelId) {
    return new Promise(() => {});
  }
  try {
//     "page": 0,
// "limit": 10,
// // "broadcastUid": "91b406a1-a13e-3deb-b03b-81be2a6a0bb0"
// "broadcastName":"2024",
// "status":["Completed"]

    // let url = `broadcast/${channelId}/getBroadcastFilter`;
    // if (startTime && endTime) {
    //   url = `broadcast/${channelId}/getBroadcastFilter?startTime=${startTime}&endTime=${endTime}`;
    // }
    // const response = await axios.post(url, {
    //   broadcastName: search,
    //   status: filters,
    //   page: 0,
    //   limit: 10,
    //   broadcastUid: broadcastUid ? broadcastUid : null,
    // });
    const response = await axios.post(`broadcast/${channelId}/getBroadcastFilterV2`,filterPayload)
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBroadcastCount = async (channelId: string) => {
  if (!channelId) {
    return new Promise(() => {});
  }
  try {
    const response = await axios.get(
      `broadcast/${channelId}/getBroadcastCount`
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const cancelBroadcast = async (
  channelId: string,
  broadcastUid: string
) => {
  if (!channelId) {
    return new Promise(() => {});
  }
  try {
    const response = await axios.post(
      `broadcast/${channelId}/toCancelBroadcast?broadcast_uid=${broadcastUid}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTotalCredits = async (businessId: string) => {
  if (!businessId) {
    return new Promise(() => {});
  }
  try {
    const response = await axios.get<{ totalCredits: number }>(
      `payment/${businessId}/getTotalCredits`
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const repeatCampaign = async (channelUid: string, campaign: any) => {
  if (!channelUid) {
    return new Promise(() => {});
  }
  let scheduledAt = campaign.scheduledAt;
  if (
    campaign.type === "immediate" ||
    (scheduledAt > 0 && moment(scheduledAt).isBefore(moment()))
  ) {
    scheduledAt = parseInt(new Date().getTime().toString());
  }
  const timestamp = moment().format("DD MMM YYYY h:mma");

  function extractCampaignName(inputString: string): string {
    var regex = /\((\d{2} [A-Za-z]{3} \d{4} \d{1,2}:\d{2}[APap][Mm])\)/;
    var match = regex.exec(inputString);

    if (match && match[1]) {
      var name = inputString.replace(match[0], "").trim();

      return name;
    } else {
      return inputString;
    }
  }

  const campaignData = {
    name: extractCampaignName(campaign.name) + " (" + timestamp + ")",
    type: campaign.type,
    replyAssignTo: campaign.replyAssignTo,
    replyAssignId: campaign.replyAssignId,
    forceSent: campaign.forceSent,
    scheduledAt,
    templateId: campaign.template.id,
    communityList: campaign.communityList,
    creatorId: campaign.creatorId,
    // templateText: campaign.templateText
  };
  try {
    const response = await axios.post(
      `/broadcast/${channelUid}/createBroadcast`,
      campaignData
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBroadcastAmount = async (
  channelId: string,
  amountPayload: any
) => {
  try {
    const response = await axios.post(
      `broadcast/${channelId}/getBroadcastAmount`,
      amountPayload
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};
