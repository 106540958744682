import axios from '../../utils/axios';
// import axios from "axios";
import MediaDevicesainStore from '../../redux/store/store';
import { toast, sweetalert } from '../../common/alert';

// const apiUrl = 'http://183.83.189.151:43251/api';
export interface BotData {
    nameInput: string,
    timeoutMsgInput: string,
    sessionTTL: string
};

export const getBots = async (searchQuery: string = '', page: number = 0, limit: number = 100) => {
    const state = MediaDevicesainStore.getState();
    const businessUid = state.cartreducer.business?.business?.uid;

    try {
        const response = await axios.post(`bot/getAll`, {
            uid: businessUid,
            channelId: state.cartreducer.channelUid?.value,
            limit,
            page,
            search: searchQuery === '' ? null : searchQuery
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const createBot = async (data: BotData) => {
    try {
        const { nameInput, timeoutMsgInput, sessionTTL } = data;

        const state = MediaDevicesainStore.getState();
        const businessUid = state.cartreducer.business?.business?.uid;
        const memberUid = state.cartreducer.business?.uid;
        const channelId = state.cartreducer.channelUid.value

        const res = await axios.post(`bot/create`, {
            name: nameInput,
            sessionTimeoutMessage: timeoutMsgInput,
            sessionTTL: parseInt(sessionTTL) * 60000,
            memberUid,
            businessUid,
            assignId: 0,
            assignTo: 0,
            channelUid: channelId
        });
        toast('success', 'Bot created successfully');
        return res.data;
    }
    catch (error: any) {
        toast('error', error.response.data.message)
    }
}

export const updateBot = async (botId: string, data: any) => {
    try {
        const updateRes = await axios.patch(`bot/${botId}/update`, data);
        if (updateRes.data)
            toast('success', 'Bot updated successfully');
    }
    catch (error: any) {
        toast('error', error.response.data.message)
    }
}

export const deleteBot = async (botId: string) => {
    try {
        const deleteRes = await axios.delete(`bot/${botId}/delete`);
        toast('success', 'Bot deleted successfully');
        return deleteRes.data;
    } catch (error: any) {
        toast('error', error.response.data.message)
    }
}

export const verifyBot = async (botUid: string, flowUid: string, mobileNumber: string) => {
    try {
        const response = await axios.post(`bot/testBotFlow`, {
            botUid,
            flowUid,
            mobileNumber
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}