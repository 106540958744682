import React, { useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';
import { useAppSelector } from '../../../../hooks';
import Creatable from 'react-select/creatable';
import styles from "./updateConversationTag.module.scss";
import classes from "./setMember.module.scss";
import { sliceText } from '../../../../../common/sliceText';
import { getAllMembers } from './memberService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

interface FormData {
    memberUids: any;
    memberDTO: [ 
        {
            name: string,
            uid: string
        }
    ]
};

function SetMemberEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({ 
        memberUids: [], 
        memberDTO: [ 
            {
                name: '',
                uid: ''
            }
        ]
    });
    const [members, setMembers] = useState<any>([{ label: '', value: ''}]);
    const [defaultValue, setDefaultValue] = useState<any>(null);

    const { init, saveElementChanges } = useElmentEditor({
        type: 'actions/set_member',
        data: formData
    }, props);

    useEffect(() => init(setFormData), []);

    const businessUid = useAppSelector((state) => state.meta.businessUid);

    const payload = {
        businessUid: businessUid,
        page: 0,
        limit: 500,
        status: ['ACTIVE']
    }


    const fetchMembers = async () => {
        const response  = await getAllMembers(payload);
        const memberList = response?.dataDTO?.map((item: any) => ({
            label: item?.name,
            value: item?.uid
        }));
        setMembers(memberList);
        return response.data;
    }

    useEffect(() => {
        if(formData?.memberUids?.length ===0) {
            fetchMembers();
        }
    },[formData])
   
    const schema = yup.object().shape({
        memberDTO: yup.array().required("required").min(1, "Please select atleast one member")
    });


    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData]);

                const handleSave = () => {
                    if(values?.memberDTO?.length === 1 && !values?.memberDTO[0]?.uid) {
                        setFieldValue('memberDTO', []);
                    } 
                }

                useEffect(() => {
                    if(values?.memberDTO[0]?.name && values?.memberDTO?.length > 0) {
                        setDefaultValue({label : values?.memberDTO[values?.memberDTO?.length-1]?.name, value:  values?.memberDTO[values?.memberDTO?.length-1]?.uid })
                    } else {
                        setDefaultValue(null);
                    }
                }, [values.memberDTO])

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Set Member Appointment' icon={<FontAwesomeIcon style={{color: "#5d8ca6"}} icon={faUsers} />} />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <Form.Label>Members<span className='required'></span></Form.Label>
                                <Creatable
                                    name="memberDTO"
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                                        menu: base => ({ ...base, width: 425 })
                                    }}
                                    maxMenuHeight={180}
                                    menuPlacement='auto'
                                    options={members}
                                    isClearable={true}
                                    value={defaultValue}
                                    onChange={(selectedMember: any) => {

                                        const updatedMembers = [
                                            ...values.memberDTO?.filter((item: any) => item?.uid !== selectedMember?.value && item?.uid?.trim() !== '')
                                        ];

                                        const selected = members?.find((item: any) => item?.value === selectedMember?.value);
                                        if (selected) {
                                            const selectedMemberDTO = {
                                                name: selected.label,
                                                uid: selected.value
                                              };
                                            updatedMembers.push(selectedMemberDTO);
                                        }
                                        setFieldValue(`memberDTO`, updatedMembers);
                                    }}
                                />
                                <ErrorMessage name="memberDTO" component="div" className='text-danger' />
                            </Form.Group>

                            <div className={`${styles.tagsContainer} ${classes.tagsContainer}`}>
                                {
                                    values?.memberDTO[0]?.name  && values?.memberDTO?.map((member: any) => {
                                        return (
                                            <div className={`${styles.contactTag} ${classes.contactTag}`} key={member?.uid}>
                                                {sliceText(member?.name,20)}
                                                <button
                                                    type='button'
                                                    onClick={() => {
                                                        if (values?.memberDTO) {
                                                            const updatedMembers = [
                                                                ...values?.memberDTO?.filter((p: any) => p?.uid !== member?.uid)
                                                            ];
                                                            setFieldValue(`memberDTO`, updatedMembers);
                                                        }
                                                    }}
                                                >&times;</button>
                                            </div>
                                        );
                                    })}
                            </div>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' onClick={handleSave} disabled={props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
export default SetMemberEditor;