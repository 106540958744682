
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Label, Input, Button } from "reactstrap";
import classnames from "classnames";
import "./otpConfiguration.scss";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import Modall from "../../common/modall";
import TemplateList from "../Template/templateList";
import { FormControl, Offcanvas, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { createOTP, deleteOTP, getAllOTP, updateOTP } from "../../services/otpService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { showAlertYesNo } from "../../common/alertYesNo";
import { faCopy, faInfoCircle } from "@fortawesome/free-solid-svg-icons";


interface FormData {
    id: string,
    name: string,
    templateId: string,
    templateName: string,
    memberId: string,
    otpRange: number,
    expiryTime: number,
    attempt: number,
    uid: string,
    uniqueId: number
}

const OTP = () => {
    const [activeTab, setActiveTab] = useState("1");
    const memberUid = useSelector((state: any) => state.cartreducer.business?.id);
    const [tabs, setTabs] = useState<FormData[]>([{ id: "1", name: "Configuration 1", templateId: '', templateName: '', memberId: memberUid, otpRange: 4, expiryTime: 5, attempt: 1, uid: '', uniqueId: 1 }]);
    const [openModal, setOpenModal] = useState(false);
    const [modalHead, setModalHead] = useState('Select Template');
    const [modalSize, setModalSize] = useState("modal-xxl");
    const channelData = useSelector((state: any) => state.cartreducer.channelUid);
    const channelUid = channelData?.value;
    const [isCreate, setCreate] = useState(false);
    const [tooltipText, setTooltipText] = useState("Copy");
    const [isLoading, setLoading] = useState(false);
    const [isInvalidAccess, setIsInvalidAccess] = useState(false);
    const roleRules = useSelector((state: any) => state.cartreducer.roleRules);

    useEffect(() => {  
      if(roleRules) {
        const isInvalidAccess = roleRules.canViewWhatsappOTPs === true && roleRules.canManageWhatsappOTPs === false;
        setIsInvalidAccess(isInvalidAccess);
      }
    }, [roleRules]);


    const toggle = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const addNewTab = () => {
        const newTabId = tabs?.length > 0 ? (Number(tabs[tabs?.length - 1]?.id) + 1).toString() : "1";
        const newTab = { id: newTabId, name: `Configuration ${newTabId}`, templateId: '', templateName: '', memberId: memberUid, otpRange: 4, expiryTime: 5, attempt: 1, uid: '', uniqueId: 1 };
        setTabs([...tabs, newTab]);
        setActiveTab(newTabId);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleCopy = (uids: any) => {
        navigator.clipboard.writeText(uids || '');
        setTooltipText("Copied!");
        setTimeout(() => setTooltipText("Copy"), 1500); 
    };

    const getAllConfiguration = async () => {
        if (channelUid) {
            const allConfig = await getAllOTP(channelUid);
            const otpConfig = allConfig?.map((otp: any, index: number) => ({
                id: (index + 1).toString(),
                name: otp?.name || '',
                templateId: otp?.templateId || '',
                templateName: otp?.template?.name || '',
                memberId: otp?.memberId || '',
                otpRange: otp?.otpRange || 4,
                expiryTime: (Number(otp?.expiryTime) / 60) || 5,
                attempt: otp?.attempt || 1,
                uid: otp?.uid || '',
                uniqueId: otp?.id || 1
            }));
            otpConfig?.length > 0 ? setTabs(otpConfig) : setTabs([{ id: "1", name: "Configuration 1", templateId: '', templateName: '', memberId: memberUid, otpRange: 4, expiryTime: 5, attempt: 1, uid: '', uniqueId: 1 }]);
        }
    }

    useEffect(() => {
        getAllConfiguration();
    }, [])


    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        otpRange: Yup.number()
            .required("OTP Range is required")
            .test(
                "is-greater-than-zero",
                "OTP Range must be greater than or equal to 4",
                (value: any) => value >= 4
            ),
        expiryTime: Yup.number().required("Expiry Time is required").test(
            "is-greater-than-zero",
            "Expiry must be greater than zero",
            (value: any) => value > 0
        ),
        attempt: Yup.number().required("Attempt is required").test(
            "is-greater-than-zero",
            "Attempt must be greater than zero",
            (value: any) => value > 0
        ),
        templateId: Yup.string().required("Template is required")
    });

    async function onSubmitHandler({ name, otpRange, templateId, memberId, expiryTime, attempt, uid, uniqueId }: any) {
        setLoading(true)
        const payload = {
            name,
            otpRange,
            templateId,
            memberId,
            expiryTime: expiryTime * 60,
            attempt
        }

        if (isCreate) {
            await createOTP(channelUid, payload);
            getAllConfiguration();
            setLoading(false)
        }
        else {
            const updatedPayload = {
                ...payload,
                id: uniqueId
            };

            await updateOTP(channelUid, uid, updatedPayload);
            getAllConfiguration();
            setLoading(false)
        }
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h5 className="pt-4">WhatsApp OTP</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Nav tabs>
                        {tabs.map((tab) => (
                            <NavItem key={tab.id}>
                                <NavLink
                                    className={classnames({ active: activeTab === tab.id })}
                                    onClick={() => toggle(tab.id)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {tab.name}
                                </NavLink>
                            </NavItem>
                        ))}

                        <NavItem>
                            <NavLink onClick={addNewTab} style={{ cursor: "pointer" }}>
                                +
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent className="tabContent" activeTab={activeTab}>
                        {tabs.map((tab, index) => (
                            <TabPane tabId={tab.id} key={tab.id} className="mt-4">
                                <Formik
                                    initialValues={tab}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmitHandler}
                                    enableReinitialize={true}
                                >
                                    {({ values, setFieldValue, handleSubmit, handleChange, touched, errors, setValues }) => {

                                        const deleteOTPConfig = async () => {
                                            let activeIndex = tabs?.findIndex((tab: any) => tab.id === values?.id);
                                            const nextTab = activeIndex !== 0 ?  tabs[activeIndex-1]?.id : tabs[activeIndex+1]?.id;
                                            if (values?.uid) {
                                                const alertResponse = await showAlertYesNo(
                                                    "question",
                                                    "Are you sure you want to delete your OTP Configuration ?"
                                                );
                                                if (alertResponse.isConfirmed) {
                                                    await deleteOTP(channelUid, values?.uid);
                                                    const newTabs = tabs?.filter((tab: any) => tab.id !== values?.id);
                                                    setActiveTab(nextTab);
                                                    setTabs(newTabs);
                                                }
                                            }
                                            else {
                                                const newTabs = tabs?.filter((tab: any) => tab.id !== values?.id);
                                                setActiveTab(nextTab);
                                                setTabs(newTabs);
                                            }
                                        }

                                        const handleSetSelectTemplateId = (templateId: string, name: string) => {
                                            setFieldValue("templateId", templateId);
                                            setFieldValue("templateName", name);
                                        }
                                        return (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <Offcanvas.Body className="offCanvas">
                                                    <Row className="offCanvas">
                                                        <Col className="md-6">
                                                            <Form.Group className='mb-5'>
                                                                <Label for={`nameInput-${tab.id}`}>Name <span className='required'></span></Label>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={<Tooltip id="tooltip-name">This is the name of the configuration.</Tooltip>}
                                                                >
                                                                    <FontAwesomeIcon icon={faInfoCircle} className="infoIcon" />
                                                                </OverlayTrigger>
                                                                <FormControl
                                                                    name="name"
                                                                    id={`nameInput-${tab.id}`}
                                                                    placeholder="Enter name"
                                                                    className="name"
                                                                    value={values?.name}
                                                                    onChange={handleChange}
                                                                />
                                                                <ErrorMessage name="name" component="div" className="invalid-feedback d-block" />
                                                            </Form.Group>

                                                            <Form.Group className="mb-5">
                                                                <Label>OTP Range <span className='required'></span></Label>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={<Tooltip id="tooltip-otpRange">Define the range for OTP values.</Tooltip>}
                                                                >
                                                                    <FontAwesomeIcon icon={faInfoCircle} className="infoIcon" />
                                                                </OverlayTrigger>
                                                                <FormControl
                                                                    name='otpRange'
                                                                    value={values?.otpRange}
                                                                    id={`otpRange-${tab.id}`}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter OTP Range"
                                                                    className="name"
                                                                    type="number"
                                                                    min="0"
                                                                    onWheel={(e) => (e.target as HTMLInputElement).blur()}
                                                                />
                                                                <ErrorMessage name="otpRange" component="div" className="invalid-feedback d-block" />
                                                            </Form.Group>

                                                            <Form.Group id={`expiryTime-${tab.id}`} className="expiryTime mb-5">
                                                                <Label>Expiry Time (Mins) <span className="required"></span></Label>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={<Tooltip id="tooltip-expiryTime">Set the expiry time for the OTP in minutes.</Tooltip>}
                                                                >
                                                                    <FontAwesomeIcon icon={faInfoCircle} className="infoIcon" />
                                                                </OverlayTrigger>
                                                                <FormControl
                                                                    name="expiryTime"
                                                                    placeholder="Enter Expiry time in minutes"
                                                                    value={values?.expiryTime}
                                                                    onChange={handleChange}
                                                                />
                                                                <ErrorMessage name="expiryTime" component="div" className="invalid-feedback d-block" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="md-6">
                                                            <div className='d-flex align-items-center'>
                                                                <Form.Group className="mb-5 w-100">
                                                                    <Label> Attempt <span className="required"></span></Label>
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={<Tooltip id="tooltip-attempt">Number of attempts allowed.</Tooltip>}
                                                                    >
                                                                        <FontAwesomeIcon icon={faInfoCircle} className="infoIcon" />
                                                                    </OverlayTrigger>
                                                                    <FormControl
                                                                        name="attempt"
                                                                        id={`attempt-${tab.id}`}
                                                                        placeholder="Enter attempt"
                                                                        className="name"
                                                                        value={values?.attempt}
                                                                        onChange={handleChange}
                                                                    />
                                                                    <ErrorMessage name="attempt" component="div" className="invalid-feedback d-block" />
                                                                </Form.Group>
                                                                <Form.Group className={`trashIcon ${isInvalidAccess ? "isDisabled" : ""}`}>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id="tooltip-trash">Delete OTP Config</Tooltip>}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={faTrashAlt}
                                                                            onClick={deleteOTPConfig}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </Form.Group>
                                                            </div>

                                                            <Form.Group className="mb-5">
                                                                <Label>Template <span className="required"></span></Label>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={<Tooltip id="tooltip-template">Select the template for the OTP.</Tooltip>}
                                                                >
                                                                    <FontAwesomeIcon icon={faInfoCircle} className="infoIcon" />
                                                                </OverlayTrigger>
                                                                <br />
                                                                <Button
                                                                    className='mr-2 signUpBtn mb-1'
                                                                    id={`template-${tab.id}`}
                                                                    type="button"
                                                                    onClick={() => { setOpenModal(true); }}
                                                                    disabled={isInvalidAccess}
                                                                > Select Template
                                                                </Button>
                                                                {values?.templateId && (
                                                                    <>
                                                                        {values?.templateName}
                                                                    </>
                                                                )}
                                                                {openModal && (
                                                                    <Modall
                                                                        isOpen={openModal}
                                                                        onClose={handleClose}
                                                                        title={modalHead}
                                                                        size="modal-xxl"
                                                                    >
                                                                        <TemplateList
                                                                            component='OTP'
                                                                            handleClose={handleClose}
                                                                            modalTitle={setModalHead}
                                                                            setModalSize={setModalSize}
                                                                            setSelectTemplateId={(templateId: string, name: string) => handleSetSelectTemplateId(templateId, name) as unknown as string}
                                                                            type="start"
                                                                        />
                                                                    </Modall>
                                                                )}
                                                                {errors?.templateId && !values?.templateId &&
                                                                    <ErrorMessage name="templateId" component="div" className="invalid-feedback d-block" />
                                                                }
                                                            </Form.Group>

                                                            {values?.uid && (
                                                                <Form.Group id={`configId-${tab.id}`}>
                                                                    <Label>Config Id</Label>
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={<Tooltip id="tooltip-configId">This is the unique config ID.</Tooltip>}
                                                                    >
                                                                        <FontAwesomeIcon icon={faInfoCircle} className="infoIcon" />
                                                                    </OverlayTrigger>
                                                                    <br />
                                                                    <div className="configDiv">
                                                                        <span className="configId">
                                                                            {values?.uid}
                                                                        </span>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip id="tooltip-copy">{tooltipText}</Tooltip>}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={faCopy}
                                                                                className="copyIcon"
                                                                                onClick={() => { handleCopy(values?.uid) }}
                                                                            />
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Form.Group>
                                                            )}
                                                        </Col>
                                                    </Row>

                                                    <Row className="text-center">
                                                        <Col className="d-flex justify-content-center">
                                                            <Button type="submit" color="primary" className="sendButton" 
                                                            onClick={() => {
                                                                !tab?.uid ? setCreate(true) : setCreate(false)
                                                            }}
                                                            disabled={isLoading || isInvalidAccess}
                                                            >
                                                                { isLoading ? "Loading..." : !tab?.uid ? "Create" : "Update"}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Offcanvas.Body>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </TabPane>
                        ))}
                    </TabContent>
                </Col>
            </Row>
        </Container>
    );
};

export default OTP;