import React, { useState, useEffect } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import "../main.scss";
import { WorkFlowTypes } from "../assets";
import { FiChevronRight } from "react-icons/fi";
import { Input } from "reactstrap";
import {
  updateConnection,
  updateConnectionDisableStatus,
} from "../../../services/integrationService";
import { useParams } from "react-router-dom";
import { TbCalendarTime } from "react-icons/tb";

interface WorkFlowHeaderProps extends WorkFlowTypes {
  setActiveWorkflowId: React.Dispatch<React.SetStateAction<number | null>>;
  workflowData: any; // Add this prop to accept workflow data;
  connectionUid: string;
  channelDtoUid: string | undefined;
  integrationName: string | null | undefined;
  setCallDb: React.Dispatch<React.SetStateAction<boolean>>;
  isInvalidAccess?: boolean;
}

const WorkFlowHeader: React.FC<WorkFlowHeaderProps> = (props) => {

  const [checked, setChecked] = useState(props.workflowData?.disabled);

  const { name, integrationUid } = useParams<{
    name: string;
    integrationUid: string;
  }>();

  useEffect(() => {
    setChecked(props.workflowData?.disabled);
  }, [props.workflowData]);

  function toCamelCase(str: string | undefined): string {
    return (
      str?.split(" ").reduce((acc, word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        } else {
          return (
            acc + word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          );
        }
      }, "") || ""
    );
  }

  const onChangeSubmit = () => {
    updateConnectionDisableStatus(props.workflowData.uid, !checked).then(
      (response: any) => {
        props.setCallDb(true);
      }
    );
    // const payload = {
    //   integrationConfigurationDTO: {
    //     channelDto: {
    //       uid: props.channelDtoUid,
    //     },
    //     integrationName: props.integrationName,
    //   },
    //   workFlow: {
    //     workFlowType: toCamelCase(props.title),
    //     uid: props.workflowData?.uid || null,
    //     disabled: !checked,
    //     template: props.workflowData.template,
    //     templateText: props.workflowData.templateText,
    //     mediaDTO: props.workflowData.messageDTO,
    //     actions: props.workflowData.actions.map((action: any) => ({
    //       actionType: action.actionType,
    //       operationType: action.operationType,
    //       value: action.value || null,
    //       optIn: action.optIn,
    //     })),
    //     templateId: props.workflowData.templateId || null,
    //   },
    // };

    // updateConnection(props.connectionUid, payload);
    // props.setCallDb(true);
  };

  return (
    <div className="workFlowComponent" key={props.id}>
      <div className="workFlow-header">
        <div
          className="d-flex align-items-center"
          onClick={(e: any) => {
            if (props.workflowData) {
              props.setActiveWorkflowId(props.id);
            } else {
              e.preventDefault();
            }
          }}
          style={{ cursor: props.workflowData ? "pointer" : "default" }}
        >
          <div className="p-2">
            <div
              className="cart-icon"
              style={{
                color: "rgb(47, 133, 90)",
                background: "rgb(198, 246, 213)",
                // color: props.id !== 6 ? "rgb(47, 133, 90)" : "#e552b3",
                // background: props.id !== 6 ? "rgb(198, 246, 213)" : "#ffe7f5",
              }}
            >
              {name === "zohoBooks" ? (
                <TbCalendarTime
                  size={20}
                  color={"rgb(47, 133, 90)"}
                  // color={props.id !== 6 ? "rgb(47, 133, 90)" : "#e552b3"}
                />
              ) : (
                <AiOutlineShoppingCart
                  size={20}
                  color={"rgb(47, 133, 90)"}
                  // color={props.id !== 6 ? "rgb(47, 133, 90)" : "#e552b3"}
                />
              )}
            </div>
          </div>
          <div>
            <div className="flow-title">{props.title}</div>
            <div className="flow-description">{props.description}</div>
          </div>
        </div>
        <div>
          {props.workflowData ? (
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer", zIndex: 1000 }}
              onClick={(e: any) => {
                e.preventDefault();
              }}
            >
              <div className={`form-check form-switch custom-switch ${props?.isInvalidAccess ? "notAllowed" : "pointer"} `}>
                <Input
                  type="checkbox"
                  className={`form-check-input ${props?.isInvalidAccess ? "disabledState" : ""} `}
                  id={`switch-${props.id}`}
                  checked={!checked}
                  onChange={(e) => {
                    setChecked(!e.target.checked);
                    onChangeSubmit();
                  }}
                />
              </div>
              <FiChevronRight
                size={20}
                onClick={() => {
                  props.setActiveWorkflowId(props.id);
                }}
              />
            </div>
          ) : (
            <div
              className="use-btn"
              onClick={() => {
                props.setActiveWorkflowId(props.id);
              }}
            >
              {name === "sprocket"
                ? "Configure"
                : name === "zohoBooks"
                ? "Use"
                : "Use"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkFlowHeader;
