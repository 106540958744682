import React, { Component, MouseEvent } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import {
  Button,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers } from "formik";
import axios from "./../../utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SelectRow,
} from "react-bootstrap-table";
import {
  faTrashCan,
  faPlus,
  faPaperPlane,
  faInfoCircle,
  faArrowLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
// import SwitchButton from "./SwitchButton";
import { connect } from "react-redux";
import "./contact.scss";
import Edit from "./EditContact";
// import FlagInput from "../../common/flagInput";
import { sweetalert, toastAlert, toast } from "../../common/alert";
// import { countryCode } from "../../services/auth/actions";
import * as XLSX from "xlsx";
import RightSidebar from "../../common/rightSidebar";
import AddContact from "./addContacts";
import { sliceText } from "../../common/sliceText";
import TooltipReference from "../../common/tooltip";
import {
  contactDelete,
  contactGetALL,
  getAllSequence,
  subscribeToSequence,
} from "../../services/contactMemberService";
import { contactFilters as contactFilter } from "../../services/contactMemberService";
import ChangeComponent from "../../common/routeFile";
// import { useTable, useSortBy, usePagination } from 'react-table';
import Loader from "../../shade/Loaders/smallLoader";
import Select from "react-select";
import { getTag } from "../Tag/tagApi";
import { Link } from "react-router-dom";
import Modall from "../../common/modall";
import { ContactsGetAll } from "../../services/campaignService";
import SearchInput from "../../common/searchComponent";
import BizTable from "../../common/BizTable";
import {
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ContactFilter from "./ContactFilter";
import { editCompany, getAll } from "../../services/companyService";
import { LuFilter } from "react-icons/lu";
import moment from "moment";
import { faTags } from '@fortawesome/free-solid-svg-icons'
import './contact.scss'
import { tableReset } from "../../redux/actions/action";
import { FiTag } from "react-icons/fi";
import AddTags from "./AddTags";

interface ContactsState {
  showContent: boolean;
  checkBoxShow: boolean;
  showEdit: boolean;
  ContactLists: any;
  ContactEdit: any;
  isSelected: boolean;
  selectedUids: any;
  code: string;
  whatsappOpt: boolean;
  whatsappNumber: number | null;
  MemberName: string;
  isLoading: boolean;
  tagsList: [{ label: string; value: string; tagAll: any }];
  getTag: [];
  isOpen: boolean;
  selectedType: any;
  tag: any;
  searchTerm: string | null;
  // paginationLimit:number;
  currentPageCount: number;
  ContactCount: number;
  allChecked: boolean;
  data: [];
  selectedRows: any;
  selectedRowsUid: any;
  paginationPaload: {
    pageIndex: number;
    pageSize: number;
    searchText: string;
    currentPage: number;
  };
  isPublishSequence: boolean;
  sequenceList: [];
  isCustomFilter: boolean;
  isCompanyContacts: boolean;
  companyList: [];
  filterData: any;
  CompanyId: number | null;
  isFilter: boolean;
  tagsCount: number;
  isExcelLoading: boolean;
  editCompany: [] | null;
  filterCount: any;
  filterPayload: any;
  companyCount: any;
  isAssignTag: boolean;
  isClearSelected: boolean;
  isInvalidAccess: boolean;
  roleRules: any;
}
type DataType = {
  // id: number;
  name: string;
  mobileNumber: number;
  email: string;
  source: string;
  contactType: string;
  actions: any;
  tags: any;
};
// const columns: Column<DataType>[] = [
//   // {
//   //   Header: 'ID',
//   //   accessor: 'id' // The attribute of the DataType object to display in this column
//   // },

// ];

const initialValues: FormValues = {
  firstname: "",
  email: "",
  whatsappNum: "",
};
interface FormValues {
  firstname: string;
  email: string;
  whatsappNum: string;
}

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  whatsappNum: Yup.string()
    .required("Whatsapp number is required")
    .max(10, "Whatsapp number cannot exceed 10 characters")
    .min(10, "Whatsapp number must be at least 10 numbers"),
});
interface Props {
  businessId: { id: number } | null;
  memberData: { name: string } | null;
  profileDetail: any;
  channelId: string;
  channelData: any;
  selectedTableData: any;
  tableReset?: any;
  selectedData?: any;
  roleRules: any;
}

class Contacts extends Component<Props, ContactsState> {
  paginationLimit = 20;
  currentPage = 0;
  columns: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      showContent: false, // Initially hide the content
      checkBoxShow: false,
      showEdit: false,
      ContactLists: [],
      ContactEdit: null,
      isSelected: false,
      selectedUids: [],
      code: "+91",
      whatsappOpt: false,
      whatsappNumber: null,
      MemberName: "",
      isLoading: true,
      tagsList: [{ label: "", value: "", tagAll: [] }],
      getTag: [],
      isOpen: false,
      selectedType: "ACCOUNT",
      tag: "",
      searchTerm: null,
      currentPageCount: 0,
      ContactCount: 0,
      allChecked: false,
      data: [],
      selectedRowsUid: new Set(),
      selectedRows: [],
      paginationPaload: {
        pageIndex: 0,
        pageSize: 10,
        searchText: "",
        currentPage: 0,
      },
      isPublishSequence: false,
      sequenceList: [],
      isCustomFilter: false,
      isCompanyContacts: false,
      companyList: [],
      filterData: "",
      CompanyId: null,
      isFilter: false,
      tagsCount: 0,
      isExcelLoading: false,
      editCompany: null,
      filterCount: 0,
      filterPayload: null,
      companyCount: 0,
      isAssignTag: false,
      isClearSelected: false,
      isInvalidAccess: false,
      roleRules: []
    };
    // this.toggleAllCheckboxes = this.toggleAllCheckboxes.bind(this);
  }

  // toggleAllCheckboxes = () => {
  //   if (this.state.allChecked) {
  //     this.setState({
  //       selectedRowsUid: new Set(),
  //       selectedRows: [],
  //       allChecked: false,
  //       checkBoxShow: false,
  //     });
  //   } else {
  //     const allRowIds = this.state.ContactLists.map((row: any) => row.uid); // Assuming each row has a unique 'uid'
  //     const allRows = this.state.ContactLists.map((row: any) => row);
  //     this.setState({
  //       selectedRowsUid: new Set(allRowIds),
  //       selectedRows: allRows,
  //       allChecked: true,
  //       checkBoxShow: true,
  //     });
  //     this.checkboxCellRenderer(allRows);
  //   }
  // };

  // toggleRowCheckbox = (row: any) => {
  //   const rowId = row.original.uid;
  //   const rowOrg = row.original; // Direct reference to the row data
  //   const newSelectedRowsUid = new Set(this.state.selectedRowsUid);
  //   let newSelectedRows = [];

  //   if (newSelectedRowsUid.has(rowId)) {
  //     // If the UID is already selected, remove it and the corresponding row data
  //     newSelectedRowsUid.delete(rowId);
  //     newSelectedRows = this.state.selectedRows.filter(
  //       (row: any) => row.uid !== rowId
  //     );
  //   } else {
  //     // If the UID is not already selected, add it and the corresponding row data
  //     newSelectedRowsUid.add(rowId);
  //     newSelectedRows = [...this.state.selectedRows, rowOrg]; // Use rowOrg directly
  //   }

  //   this.setState({
  //     selectedRowsUid: newSelectedRowsUid,
  //     selectedRows: newSelectedRows,
  //   });
  // };

  // checkboxCellRenderer = ({ row }: any) => {
  //   if (row?.original !== undefined) {
  //     return (
  //       <input
  //         type="checkbox"
  //         checked={this.state.selectedRowsUid?.has(row?.original?.uid)} // Assuming each row data has an 'id'
  //         onChange={() => this.toggleRowCheckbox(row)}
  //       />
  //     );
  //   }
  // };

  fetchData = async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: DataType[]; totalPages: number }> => {
    const { isCompanyContacts, searchTerm, CompanyId } = this.state;

    this.paginationLimit = pageSize;
    if (this.state.filterCount > 0) {
      this.state.filterPayload.page = currentPage;
      this.state.filterPayload.limit = pageSize;
      this.paginationLimit = pageSize;
      const response = await contactFilter(this.state.filterPayload);
      this.filterContacts(response, this.state.filterData, this.state.filterPayload);

      return { data: this.state.ContactLists, totalPages: this.state.filterCount };
    }

    if (isCompanyContacts) {
      const response = await this.fetchCompanyData(
        pageIndex,
        pageSize,
        searchText,
        currentPage
      );
      if (response) {
        return response;
      } else {
        throw new Error("Failed to fetch data");
      }
    } else {
      const response = await this.fetchMemberData(
        pageIndex,
        pageSize,
        searchText,
        currentPage
      );
      return response;
    }
  };

  fetchCompanyData = async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ) => {
    const { isCompanyContacts } = this.state;
    const { searchTerm, CompanyId } = this.state;
    if (isCompanyContacts) {
      const pathParts = window.location.pathname.split("/");
      const id = pathParts[2];

      if (id) {
        const { businessId } = this.props;
        if (businessId) {
          const pageData = this.currentPage > 0 ? this.currentPage - 1 : 0;
          const ContactData = {
            uid: businessId,
            limit: this.paginationLimit,
            page: searchTerm ? 0 : pageData,
            search: searchTerm,
            companyId: CompanyId,
          };
          if (searchTerm) {
            try {
              const response = await ContactsGetAll(ContactData as any);
              const list = response?.data?.list;
              const count = response?.data?.count;
              list?.map((data: any) => {
                const codePlusRem = data?.countryCode;
                const countryCode = codePlusRem?.length;
                const mobileNumber = data?.mobileNumber?.slice(countryCode);
                data.mobileNumber = mobileNumber;
              });

              this.setState({
                ContactLists: list,
                isLoading: false,
                ContactCount: searchTerm !== null ? list.length : count,
              });

              return { data: list, totalPages: count };
            } catch (error: any) {
              toast("error", error.response.data.message);
              this.setState({ isLoading: false });
            }
          } else {
            const response = await this.getCompanyContacts(id);
            if (response) {
              return { data: response, totalPages: response.length };
            }
          }
        }
      }
    }
  };

  fetchMemberData = async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ) => {
    const { businessId } = this.props;

    const pageData =
      this.state.paginationPaload.pageSize !== pageSize ? 0 : currentPage;
    const contactPayload = {
      uid: businessId,
      limit: pageSize,
      page: pageData,
    };

    const currentData = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchText: searchText,
      currentPage: pageData,
    };

    if (currentData) {
      this.setState({ paginationPaload: currentData });
    }

    const data = await contactGetALL(businessId, contactPayload);

    const { list, count } = data;

    // Modify list items
    list?.forEach((data: any) => {
      const codePlusRem = data?.countryCode;
      const countryCode = codePlusRem?.length;
      const mobileNumber = data?.mobileNumber?.slice(countryCode);
      data.mobileNumber = mobileNumber;
    });

    this.setState({ ContactLists: list, ContactCount: count });

    if (pageSize) {
      this.setState({
        selectedRowsUid: new Set(),
        // selectedRows: [],
        allChecked: false,
      });
    }
    if (currentPage) {
      this.setState({ currentPageCount: currentPage });
    }

    const filteredData = searchText
      ? list?.filter((item: any) => item.name.includes(searchText))
      : list;
    const totalPages = Math.ceil(list?.length / pageSize);
    const paginatedData = filteredData?.slice(
      pageIndex * pageSize,
      (pageIndex + 1) * pageSize
    );

    return { data: paginatedData, totalPages };
  };

  handleClickAdd = (event: MouseEvent<HTMLButtonElement>) => {
    this.setState((prevState) => ({
      showContent: !prevState.showContent,
      showEdit: false, // Toggle the visibility
    }));
  };

  onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    this.setState({ searchTerm: searchTermCheck });
  };

  handleClickEdit = (event: MouseEvent<HTMLButtonElement>, rowData: object) => {
    const numberMaskString = localStorage.getItem('numberMask');
    const numberMask = numberMaskString === 'true';
    if (!numberMask) {
      this.assignMember();
      if ("uid" in rowData) {
        const uid = rowData.uid;
        axios
          .get(`contact/${uid}`)
          .then((res) => {
            if (res?.data) {
              const responseData = res?.data;
              this.codeChange(responseData.countryCode);
              const codePlusRem = responseData?.countryCode;
              const countryCode = codePlusRem?.length;
              const mobileNumber = responseData?.mobileNumber?.slice(countryCode);
              responseData.mobileNumber = mobileNumber;
              const { MemberName } = this.state;
              if (responseData.MemberName) {
                responseData.MemberName = responseData?.member?.name;
              } else {
                responseData.MemberName = MemberName;
              }
              this.setState({ ContactEdit: responseData });
              this.setState((prevState) => ({
                showContent: !prevState.showContent,
                showEdit: true,
              }));
            }
          })
          .catch((error) => {
            toastAlert("top-end", error.response.data.message, "error");
          });
      }
    }
  };

  assignMember = () => {
    const { businessId, memberData } = this.props;
    if (businessId) {
      this.setState({ MemberName: memberData as any });
    }
  };

  conversationNavigate = () => {
    ChangeComponent({ route: "conversations" });
  };

  renderEditColumn = (cell: any, rowData: object) => {

    return (
      <div>
        <svg
          width="16"
          height="16"
          onClick={() => this.handleClickEdit(cell, rowData)}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {/* <Button 
          onClick={this.conversationNavigate}        
        >
          c
        </Button> */}
      </div>
    );
  };

  nameData = (cell: any, row: any) => {
    return (
      <div>
        {cell.length > 12 ? (
          <TooltipReference
            placement="right"
            content={cell}
            tooltipId={`nameData_${row.id}`}
          >
            {sliceText(cell, 12)}
          </TooltipReference>
        ) : (
          <span>{cell}</span>
        )}
      </div>
    );
  };

  mobileData = (cell: any, rowData: any) => {
    const { countryCode } = rowData;
    const numberMaskString = localStorage.getItem('numberMask');
    const numberMask = numberMaskString === 'true';
    return (
      // <div className="d-flex align-items-center justify-content-between w-100">
      <Row className={`${numberMask ? "numberMasking_Blur" : ''}`}>
        <Col md={10} className="pe-0">
          <span>{"+" + countryCode + " " + cell}</span>
        </Col>
        <Col md={1} className={`p-0 ${this.state.isInvalidAccess ? "notAllowed" : ""} `}>
          {rowData.contactType === "LEAD" ? (
            <span className={`cursor-pointer contactToConv ${this.state.isInvalidAccess ? "disabledState" : ""} `}>
              <Link
                to={`${process.env.PUBLIC_URL}/conversations?contactUid=${rowData?.uid}`}
                target="_blank"
                className="icon-hover"
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </Link>
            </span>
          ) : (
            <span className={`cursor-pointer contactToConv ${this.state.isInvalidAccess ? "disabledState" : ""} `}>
              <Link
                to={`${process.env.PUBLIC_URL}/supportInbox?contactUid=${rowData?.uid}`}
                target="_blank"
                className="icon-hover"
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </Link>
            </span>
          )}
        </Col>
      </Row>
    );
  };

  emailData = (cell: any, row: any) => {
    if (cell) {
      return (
        <div>
          <TooltipReference
            placement="right"
            content={cell}
            tooltipId={`emailData_${row.id}`}
          >
            {sliceText(cell, 15)}
          </TooltipReference>
        </div>
      );
    } else {
      return <div style={{ paddingLeft: "26px" }}>-</div>;
    }
  };

  tagsData = (cell: any, row: any) => {
    let title;
    if (!cell || cell.length === 0) {
      return <span style={{ paddingLeft: "23px" }}>-</span>;
    } else {
      const tagNames = cell.map((tag: any) => tag.name);
      title = tagNames.join(", ");
    }

    return (
      <div className="d-flex">
        <div className="d-flex justify-content-end p-0">
          {cell?.slice(0, 1).map((item: any) =>
            item.name ? (
              <span key={item.id} className="ms-1 tag-list-view inlinetag">
                {sliceText(item.name, 10)}
              </span>
            ) : (
              <span key={item.id} style={{ paddingLeft: "23px" }}>
                -
              </span>
            )
          )}
        </div>
        <div className="d-flex justify-content-start tag-count">
          {cell.length > 1 && (
            <TooltipReference
              placement="bottom"
              content={title}
              tooltipId={`tagData_${row.id}`}
            >
              +{cell.length - 1}
            </TooltipReference>
          )}
        </div>
      </div>
    );
  };

  sourceData = (cell: any, row: any) => {
    if (cell) {
      return cell;
    } else {
      return "-";
    }
  };

  handleCancel = () => {
    this.setState({ showContent: false, whatsappNumber: null, code: "+91" });
    // this.fetchContacts()
  };
  getTags = async (businessId: number) => {
    const payload = {
      businessUid: businessId,
      limit: 1000,
      page: 0,
      search: null,
    };

    const tagsData = await getTag(payload, "contact");
    const tags = tagsData?.data?.tagsDto?.map((tag: any) => ({
      label: tag.name,
      value: tag.name,
      tagAll: tag,
    }));

    const count = tagsData?.data?.count;
    this.setState({ tagsList: tags, tagsCount: count });
  };

  getAllCompany = async () => {
    // this.setState({ isLoading: true });
    const { businessId } = this.props;
    const { searchTerm } = this.state;

    const payload = {
      businessUid: businessId,
      limit: 10,
      page: 0,
      // search: searchTerm,
    };

    if (businessId) {
      const response = await getAll(payload);
      if (response?.data) {
        this.setState({ companyList: response?.data });
      }
    }
  };


  checkInvalidAccess() {
    const { roleRules } = this.props;
    if (roleRules) {
      const isInvalidAccess = roleRules.canViewContacts === true && roleRules.canManageContactsAndDeleteContactList === false;
      this.setState({ isInvalidAccess });
      this.setState({ roleRules });
    }
  }

  componentDidMount = async () => {
    const { businessId } = this.props;
    if (businessId) {
      const pathParts = window.location.pathname.split("/");
      const id = pathParts[2];
      if (id) {
        this.getCompanyContacts(id);
      } else {
        this.fetchContacts();
        this.getAllCompany();
        // this.setState({ isLoading: false });
      }
      this.getTags(businessId as any);
    }
  };


  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<ContactsState>,
    snapshot?: any
  ): void {
    if (this.state.isCompanyContacts) {
      if (prevState.searchTerm !== this.state.searchTerm) {
        this.fetchContacts();
      }
    } else {
      const { businessId } = this.props;
      if (businessId !== prevProps.businessId) {
        this.fetchContacts();
      }
      if (prevState.searchTerm !== this.state.searchTerm) {
        this.filterContacts([], null, null);
        this.fetchContacts();
      }
      if (prevState.isFilter !== this.state.isFilter) {
        this.setState({ ContactLists: this.state.ContactLists });
      }
      // this.fetchContacts();
      // }
      if (prevState.selectedRows !== this.state.selectedRows) {
        if (this.state?.selectedRows?.length > 0) {
          this.setState({ checkBoxShow: true });
        } else {
          this.setState({ checkBoxShow: false });
        }
      }
    }
    if (prevProps.selectedTableData !== this.props.selectedTableData) {
      const { selectedTableData } = this.props;
      const totalcount = Object.values(selectedTableData).reduce((acc, arr: any) => acc + arr?.length, 0);
      const initialSelected = Object.values(selectedTableData).flat();
      this.setState({ selectedRows: initialSelected })
    }

    if (this.state.roleRules !== this.props.roleRules) {
      this.checkInvalidAccess();
    }
  }


  getCompanyContacts = async (companyUid: string) => {
    const response = await editCompany(companyUid);

    const { contacts, id } = response;
    if (id) {
      this.setState({ CompanyId: id });
    }
    contacts?.map((data: any) => {
      const codePlusRem = data?.countryCode;
      const countryCode = codePlusRem?.length;
      const mobileNumber = data?.mobileNumber?.slice(countryCode);
      data.mobileNumber = mobileNumber;
    });
    if (contacts) {
      this.setState({
        ContactLists: contacts,
        ContactCount: contacts?.length,
        isCompanyContacts: true,
        isLoading: false,
        editCompany: contacts,
        companyCount: contacts?.length
      });
      return contacts;
    }
  };

  fetchContacts = async () => {
    const { isCompanyContacts } = this.state;
    const { searchTerm, CompanyId } = this.state;
    if (isCompanyContacts) {
      const pathParts = window.location.pathname.split("/");
      const id = pathParts[2];
      if (id) {
        const { businessId } = this.props;
        if (businessId) {
          const pageData = this.currentPage > 0 ? this.currentPage - 1 : 0;
          const ContactData = {
            uid: businessId,
            limit: this.paginationLimit,
            page: searchTerm ? 0 : pageData,
            search: searchTerm,
            companyId: CompanyId,
          };
          if (searchTerm) {
            try {
              const response = await ContactsGetAll(ContactData as any);
              const list = response?.data?.list;
              const count = response?.data?.count;
              list?.map((data: any) => {
                const codePlusRem = data?.countryCode;
                const countryCode = codePlusRem?.length;
                const mobileNumber = data?.mobileNumber?.slice(countryCode);
                data.mobileNumber = mobileNumber;
              });

              this.setState({
                ContactLists: list,
                isLoading: false,
                ContactCount: searchTerm !== null ? list.length : count,
              });
            } catch (error: any) {
              toast("error", error.response.data.message);
              this.setState({ isLoading: false });
            }
          } else {
            this.getCompanyContacts(id);
          }
        }
      }
    }

    if (!isCompanyContacts) {
      const { businessId } = this.props;
      const { searchTerm } = this.state;
      if (businessId) {
        const pageData = this.currentPage > 0 ? this.currentPage - 1 : 0;
        const ContactData = {
          uid: businessId,
          limit: this.paginationLimit,
          page: searchTerm ? 0 : pageData,
          search: searchTerm,
        };

        try {
          const response = await ContactsGetAll(ContactData as any);
          const list = response?.data?.list;
          const count = response?.data?.count;
          list?.map((data: any) => {
            const codePlusRem = data?.countryCode;
            const countryCode = codePlusRem?.length;
            const mobileNumber = data?.mobileNumber?.slice(countryCode);
            data.mobileNumber = mobileNumber;
          });
          this.setState({
            ContactLists: list,
            isLoading: false,
            ContactCount: searchTerm !== null ? list.length : count,
          });
        } catch (error: any) {
          toast("error", error.response?.data?.message);
          this.setState({ isLoading: false });
        }
      }
    }
  };

  tagContacts = async (Tag: any) => {
    // this.setState({ isLoading: true });
    const { searchTerm } = this.state;
    const { businessId } = this.props;
    const ContactList = {
      uid: businessId,
      tags: [Tag],
      // search: searchTerm,
      // "channelId": "5ea94d9f-5151-381c-a2d0-38d7d839ab3e",
      limit: 100000,
      page: 0,
    };

    // const contactPayload = {
    //   uid: businessId,
    //   tags: [Tag],
    //   limit: 100000,
    //   page: 0,
    // };
    // const data = await contactGetALL(businessId, contactPayload);

    // const { list, count } = data;
    // this.setState({ ContactLists: list });
    // this.setState({ ContactCount: list.length });

    axios
      .post("contact/getAll", ContactList)
      .then((res) => {
        // const tags = res?.data?.tags;
        const list = res?.data?.list;
        const count = res?.data?.count;
        list &&
          list.map((data: any) => {
            const codePlusRem = data?.countryCode;
            const countryCode = codePlusRem?.length;
            const mobileNumber = data?.mobileNumber?.slice(countryCode);
            data.mobileNumber = mobileNumber;
          });
        this.setState({ ContactLists: list });
        this.setState({ ContactCount: list.length });
        // this.setState({ isLoading: false });
      })
      .catch((error) => {
        toast("error", error.response.data.message);
      });
  };

  tagContact = async (tagList: any) => {
    const ContactList = {
      uid: this.props.businessId,
      tags: [tagList],
      limit: 1000,
      page: 0,
    };
    const response = await ContactsGetAll(ContactList as any);
    const list = response?.data?.list;
    if (response) {
      this.setState({ ContactLists: response.data.list });
    }
  };

  selectedTags = async (Tag: any) => {
    if (Tag !== null) {
      this.setState({ tag: Tag });
      const searchTag = Tag?.tagAll;
      this.tagContacts(searchTag);
    } else {
      this.setState({ tag: "" });
      this.fetchContacts();
    }
  };

  handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const { businessId } = this.props;
    const { whatsappOpt, code } = this.state;
    const codePlusRem = code?.slice(1);
    const addContactPayload = {
      uid: businessId,
      name: values.firstname,
      mobileNumber: values.whatsappNum,
      countryCode: codePlusRem,
      email: values.email,
      optIn: whatsappOpt,
    };
    axios
      .post("contact/create", addContactPayload)
      .then((res) => {
        toastAlert("top-end", "Contact created successfully", "success");
        this.fetchContacts();
        this.handleCancel();
      })
      .catch((error) => {
        toastAlert("top-end", error.response.data.message, "error");
      });

    setSubmitting(false);
  };

  handleRowSelect = (row: any, isSelected: any) => {
    if (isSelected) {
      const uid = row.uid;
      this.setState((prevState) => ({
        selectedUids: [...prevState.selectedUids, uid],
      }));
    } else {
      this.setState((prevState) => ({
        selectedUids: prevState.selectedUids.filter(
          (uid: string) => uid !== row.uid
        ),
      }));
    }

    this.setState((prevState) => ({
      checkBoxShow: prevState.selectedUids.length > 0,
    }));
  };

  handleRowSelectAll = (isSelected: boolean, rows: any) => {
    const newUids = isSelected ? rows.map((row: any) => row.uid) : [];
    this.setState({
      selectedUids: newUids,
    });
    this.setState((prevState) => ({
      checkBoxShow: prevState.selectedUids.length > 0,
    }));
    return true;
  };

  swapAccountModal = () => {
    this.setState({ isOpen: true });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  closeAssignModal = () => {
    this.setState({ isAssignTag: false })
  }

  handleRadioChange = (event: any) => {
    this.setState({ selectedType: event.target.value });
  };

  swapAccount = async (role: any) => {
    const { selectedUids, selectedRows } = this.state;
    const { businessId } = this.props;
    const uidsArray = selectedRows?.map((row: any) => row.uid);

    try {
      const payload = {
        uid: businessId,
        contactIds: uidsArray,
        // contactIds: selectedUids,
        contactType: role,
      };
      await axios.post(`/contact/contactBulkUpdate`, payload).then((res) => {
        this.setState({ isOpen: false });
        // this.clearSelect();
        const { tableReset } = this.props;
        if (tableReset) {
          tableReset()
        }
        toastAlert("top-end", "Contact updated successfully", "success");
        this.fetchContacts();
      });
    } catch (error: any) {
      toastAlert("top-end", error.response.data.message, "error");
    }
  };

  clearSelect = () => {

    // this.setState({
    //   selectedUids: [],
    //   checkBoxShow: false,
    //   selectedRows: [],
    //   selectedRowsUid: new Set(),
    // });
    const { tableReset } = this.props;
    if (tableReset) {
      tableReset();
      this.setState({ isClearSelected: true });
      const checkboxes = document.querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"]'
      );
      checkboxes?.forEach((checkbox) => {
        if (checkbox.checked) {
          checkbox.click();
        }
        setTimeout(() => {
          this.setState({ isClearSelected: false });
        }, 3000);
      });
    }
    else {
      this.setState({ isClearSelected: false });
    }

  };

  DeleteSelect = async () => {
    const { selectedUids, selectedRowsUid, paginationPaload, selectedRows } = this.state;
    const { businessId } = this.props;
    const uidsArray = Array.from(selectedRowsUid) as any;
    const { currentPage, pageIndex, pageSize, searchText } = paginationPaload;

    sweetalert(
      "warning",
      " Are you sure you want to delete this contact?",
      async () => {
        if (selectedRows) {
          const uids = selectedRows.map((row: any) => row.uid);
          const response = await contactDelete(uids, businessId);
          if (response) {
            // const contactList = await contactGetALL(businessId);
            toast("success", "Contact deleted successfully");
            this.setState({ selectedUids: [] });
            this.fetchData(0, 20, "", 0);
            this.setState({ searchTerm: null });
            // this.setState({ checkBoxShow: false });
            // this.fetchContacts();
            // const contactData = contactList ? contactList?.data?.list : "";
            // if (contactData != "") {
            //   this.setState({ ContactLists: contactData });
            //   if (this.state.checkBoxShow == true) {
            //   }
            // }
          }
        }

        if (uidsArray) {
          const response = await contactDelete(uidsArray, businessId);
          if (response) {
            toast("success", "Contact deleted successfully");

            const contactPayload = {
              uid: businessId,
              limit: pageSize,
              page: currentPage,
            };
            const data = await contactGetALL(businessId, contactPayload);
            const { list, count } = data;
            if (list) {
              this.setState({ ContactLists: list, ContactCount: count });
            }

            this.setState({
              selectedRowsUid: new Set(),
              selectedRows: [],
              checkBoxShow: false,
            });
          }
        }
      },
      () => {
        this.clearSelect();
      }
    );
  };

  codeChange = (e: string) => {
    this.setState({ code: e });
  };

  whatsappOptState = (check: boolean) => {
    this.setState({ whatsappOpt: check });
  };

  exportToExcel = (data: any, filename: any) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, filename + ".xlsx");
  };

  exportExcel = async () => {
    const { businessId } = this.props;
    const {
      ContactCount,
      isCompanyContacts,
      CompanyId,
      isCustomFilter,
      ContactLists,
    } = this.state;
    const load = ContactCount === 0 ? false : true;
    this.setState({ isExcelLoading: load });
    // Access instance variables directly
    if (businessId) {
      if (isCompanyContacts) {
        const pathParts = window.location.pathname.split("/");
        const id = pathParts[2];
        if (id) {
          const ContactData = {
            uid: businessId,
            limit: ContactCount,
            page: 0,
            // include search term if needed
            search: null,
            companyId: CompanyId,
          };
          try {
            const response = isCustomFilter
              ? { contacts: ContactLists, name: ContactLists[0]?.name }
              : await editCompany(id);
            const { contacts, name } = response;

            const headers = [
              "Name",
              "Country Code",
              "Mobile Number",
              "Email",
              "source",
              "Company Name",
              "tags",
            ];
            const dataToExport = contacts?.map((item: any) => {
              const codePlusRem = item?.countryCode;
              const countryCode = codePlusRem?.length;
              const mobileNumber = isCustomFilter ? item.mobileNumber : item?.mobileNumber?.slice(countryCode);

              // Assuming each tag object has a property like 'name' that you want to join
              const tagsString = item.tags
                .map((tag: any) => tag.name)
                .join(", ");
              // const company = item?.companies.map((company:any)=>company?.name)
              return [
                item.name,
                item.countryCode,
                mobileNumber,
                item.email,
                item.source,
                name,
                tagsString, // Use the joined string of tag names
              ];
            });

            const dataWithHeaders = [headers, ...dataToExport];
            this.exportToExcel(dataWithHeaders, "contacts_data");
          } catch (error: any) {
            toast("error", error.response.data.message);
          }
        }
      } else {
        const { searchTerm } = this.state;
        const ContactData = {
          uid: businessId,
          limit: ContactCount,
          page: 0,
          // include search term if needed
          search: searchTerm || null,
        };
        try {
          let list: any;
          if (isCustomFilter) {
            // list = ContactLists;
            this.state.filterPayload.page = 0;
            this.state.filterPayload.limit = this.state.filterCount;
            const response = await contactFilter(this.state.filterPayload);
            list = response?.list;
          } else {
            const response = await ContactsGetAll(ContactData as any);
            list = response?.data?.list;
          }
          const headers = [
            "Name",
            "Country Code",
            "Mobile Number",
            "Email",
            "source",
            "Company Name",
            "tags",
          ];

          const dataToExport = list.map((item: any) => {
            const codePlusRem = item?.countryCode;
            const countryCode = codePlusRem?.length;
            const mobileNumber = item?.mobileNumber?.slice(countryCode);

            // Assuming each tag object has a property like 'name' that you want to join
            const tagsString = item.tags.map((tag: any) => tag.name).join(", ");
            // const company = item?.companies.map((company:any)=>company?.name)
            return [
              item.name,
              item.countryCode,
              mobileNumber,
              item.email,
              item.source,
              item.companyNames,
              tagsString, // Use the joined string of tag names
            ];
          });

          const dataWithHeaders = [headers, ...dataToExport];
          this.exportToExcel(dataWithHeaders, "contacts_data");
          this.setState({ isExcelLoading: false });
        } catch (error: any) {
          toast("error", error.response.data.message);
        }
      }
    }
  };

  tagsChange = (Tag: any) => {
    this.setState({ getTag: Tag });
    const searchTag = Tag?.tagAll;
    this.tagContacts(searchTag);
  };

  pageChange = (page: any, sizePerPage: any) => {
    const newPaginationLimit =
      sizePerPage !== this.paginationLimit ? sizePerPage : this.paginationLimit;
    const newCurrentPage =
      sizePerPage !== this.paginationLimit
        ? 0
        : page !== this.currentPage
          ? page
          : this.currentPage;

    this.paginationLimit = newPaginationLimit;
    this.currentPage = newCurrentPage;
    if (!this.state.filterData) {
      this.fetchContacts();
    }
    // Call fetchContacts or any other method that needs these values
  };

  closePublishModal = () => {
    this.setState({ isPublishSequence: false });
  };

  listOfSequence = async () => {
    this.setState({ isPublishSequence: true });
    const { channelId } = this.props;
    if (channelId) {
      const response = await getAllSequence(channelId);
      const filteredData = response?.filter((data: any) => data?.triggerOption === 'Trigger');
      this.setState({ sequenceList: filteredData });
    }
  };

  sequenceOptions = () => {
    const { sequenceList } = this.state;
    if (!Array.isArray(sequenceList)) {
      return []; // Return an empty array if sequenceList is not an array
    }
    return sequenceList.map((item: any) => ({
      value: item.id,
      label: item.sequenceName,
    }));
  };

  toggleQuickPopover = () => {
    // this.setState({ isCustomFilter: false });
  };

  //   tagsAddRemove = (id: number, type: string) => {
  //     const { tagsList } = this.state;
  //     const existingTagIndex = tagsList.findIndex((tag:any) => tag.id === id);

  //     if (type === 'ADD') {
  //       if (existingTagIndex !== -1) {
  //         // Tag already exists, update the tag
  //         const updatedTag = { ...tagsList[existingTagIndex], label: 'tag', value: 'tag', tagAll: {} };
  //         const updatedTagsList = [...tagsList];
  //         updatedTagsList[existingTagIndex] = updatedTag;
  //         this.setState({ tagsList: updatedTagsList } as any);
  //       } else {
  //         // Tag does not exist, add the tag
  //         const newTag = { id, label: 'tag', value: 'tag', tagAll: {} };
  //         this.setState({ tagsList: [...tagsList, newTag] } as any);
  //       }
  //     }

  //     if (type === 'REMOVE' && existingTagIndex !== -1) {
  //       // Remove the tag from the list
  //       const updatedTagsList = [...tagsList.slice(0, existingTagIndex), ...tagsList.slice(existingTagIndex + 1)];
  //       this.setState({ tagsList: updatedTagsList }  as any);
  //     }
  // };

  filterContacts = (filterData: any, filter: any, filterPayloads: any) => {
    if (filterData?.list?.length === 0) {
      filterData?.list?.map((data: any) => {
        const codePlusRem = data?.countryCode;
        const countryCode = codePlusRem?.length;
        const mobileNumber = data?.mobileNumber?.slice(countryCode);
        data.mobileNumber = mobileNumber;
      });
      this.setState({
        ContactLists: filterData?.list,
        filterData: filter,
        ContactCount: filterData?.count,
        isFilter: false,
        filterCount: 0,
        filterPayload: null
      });
    } else {
      filterData?.list?.map((data: any) => {
        const codePlusRem = data?.countryCode;
        const countryCode = codePlusRem?.length;
        const mobileNumber = data?.mobileNumber?.slice(countryCode);
        data.mobileNumber = mobileNumber;
      });
      this.setState({
        ContactLists: filterData?.list,
        filterData: filter,
        ContactCount: filterData?.count,
        isFilter: true,
        filterCount: filterData?.count,
        filterPayload: filterPayloads
      });
    }
  };

  render() {
    const {
      selectedUids,
      checkBoxShow,
      whatsappNumber,
      tagsList,
      ContactCount,
      currentPageCount,
      selectedRowsUid,
      selectedRows,
      isPublishSequence,
      sequenceList,
      isCustomFilter,
      tag,
      isCompanyContacts,
      companyList,
      filterData,
      isFilter,
      isAssignTag,
      filterPayload,
      filterCount
    } = this.state;
    const numberMaskString = localStorage.getItem('numberMask');
    const numberMask = numberMaskString === 'true';

    const selectRowProp: SelectRow = {
      mode: "checkbox",
      clickToSelect: false,
      clickToSelectAndEditCell: false,
      clickToExpand: false,
      onSelect: this.handleRowSelect,
      onSelectAll: this.handleRowSelectAll,
      // selected: selectedUids.length > 0 ? true : false,
    };
    this.columns = [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       checked={this.state.allChecked}
      //       onChange={this.toggleAllCheckboxes}
      //     />
      //   ),
      //   accessor: "checkbox",
      //   Cell: this.checkboxCellRenderer,
      //   sticky: "left",
      // },
      {
        id: "selection",
        Header: ({
          getToggleAllRowsSelectedProps,
        }: {
          getToggleAllRowsSelectedProps: any;
        }) => (this.state.isInvalidAccess ? <></> :
          <div>
            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: { row: any }) => (this.state.isInvalidAccess ? <></> :
          <div>
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
          </div>
        ),
        accessor: "checkbox",
      },
      {
        Header: "Name",
        accessor: "name",
        sticky: "left",
        Cell: ({ cell, row }: any) => this.nameData(cell.value, row.original),
      },
      {
        Header: "Mobile Number",
        accessor: "mobileNumber",
        Cell: ({ cell, row }: any) => this.mobileData(cell.value, row.original),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ cell, row }: any) => this.emailData(cell.value, row.original),
        grid: "third-column",
      },
      {
        Header: "Contact Type",
        accessor: "contactType",
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ cell, row }: any) => this.tagsData(cell.value, row.original),
      },
      {
        Header: "Source",
        accessor: "source",
        Cell: ({ cell, row }: any) => this.sourceData(cell.value, row.original),
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ cell, row }: any) =>
          moment(cell.value).format("DD MMM hh.mm A"),
      },
      {
        Header: "Last Updated",
        accessor: "updatedAt",
        Cell: ({ cell, row }: any) =>
          moment(cell.value).format("DD MMM hh.mm A"),
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: ({ cell, row }: any) => (
          <div>
            <svg
              width="16"
              height="16"
              onClick={() => this.handleClickEdit(cell, row.original)}
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{cursor:"pointer"}}
            >
              <path
                d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
                stroke="#666E7D"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
                stroke="#666E7D"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        ),
        grid: "col-md-1",
      },
    ] as any;
    const { showContent, showEdit, ContactLists, ContactEdit } = this.state;
    const ContactDatas = ContactLists;

    const totalPages = Math.ceil(ContactCount / this.paginationLimit);
    const options = {
      headerAlign: "center",
      noDataText: "No records found",
      sortIndicator: true,
      hidePageListOnlyOnePage: true,
      clearSearch: false,
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
      sizePerPage: this.paginationLimit,
      onPageChange: this.pageChange,
      // pagination: false,
      sizePerPageList: [10, 25, 50, 100, { text: "All", value: ContactCount }],
      paginationPosition: "bottom",
    };

    const { tagsCount } = this.state;

    return (
      <div className="contact-container">
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <div>
              <Row>
                <Col className="global-table-title">
                  <h5>
                    {isCompanyContacts && (
                      <Link
                        to={`${process.env.PUBLIC_URL}/companies/`}
                        style={{ color: "black" }}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                      </Link>
                    )}
                    <span>Contacts</span>
                  </h5>
                </Col>
              </Row>
              <Row className="align-items-center pt-4 pb-3">
                <Col md="4" className="p-0 d-flex align-items-center">
                  <div className="ms-2">
                    <SearchInput
                      component="community"
                      onSearchChange={this.onSearchChange}
                      placeHolder="Search Contacts"
                      isSearch={this.state.searchTerm ? true : false}
                    />
                  </div>
                  <div
                    className={`${isCustomFilter ? "filterIcon-active" : ""
                      } filterIcon`}
                    id="contact"
                    style={{ padding: "9px" }}
                    onClick={() =>
                      this.setState({ isCustomFilter: !isCustomFilter })
                    }
                  >
                    {/* {
                     isCustomFilter ? (
                      <FaFilter color="#9898a1" size={16}  />
                    ) : ( */}
                    <LuFilter
                      color="#666E7D"
                      size={20}
                      fill={isCustomFilter ? "#666E7D" : "none"}
                    />
                    {/* )
                  } */}
                  </div>
                </Col>
                {/* <Col></Col> */}
                {/* <Col  className="d-flex justify-content-end"> */}
                {/* <Select
                    className="w-100 "
                    placeholder="Select tag"
                    value={this.state.tag}
                    onChange={this.selectedTags}
                    options={tagsList as any}
                    isClearable={true}
                  />
                    </Col> */}
                {isCompanyContacts && (
                  <Col md='8'>
                    <>
                      <Row>
                        <Col md='4'></Col>
                        {selectedRows?.length > 0 && (<Col className="pe-0">
                          <Button
                            variant=""
                            type="button"
                            onClick={this.clearSelect}
                            className="clearSelection "
                          >
                            Clear
                          </Button>
                        </Col>)}
                        <Col className={`${selectedRows?.length > 0 ? 'pe-0' : "d-flex justify-content-end"}`}>
                          <Button
                            variant=""
                            type="button"
                            className="clearSelection "
                            onClick={this.exportExcel}
                            disabled={this.state.ContactCount === 0 ? true : false}
                          >
                            <svg
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="me-1"
                            >
                              <path
                                d="M3.75 3H11.25M7.5 13V5.5M7.5 5.5L9.6875 7.6875M7.5 5.5L5.3125 7.6875"
                                stroke="#423B3B"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Export
                          </Button>
                        </Col>
                        {selectedRows?.length > 0 && (
                          <>
                            <Col md={3}>
                              <Button
                                className="sendButton w-100"
                                onClick={this.listOfSequence}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="side-menu__icon"
                                  fill="white"
                                  height="1em"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z" />
                                </svg>
                                Trigger Sequence
                              </Button>
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  </Col>
                )}
                <Col>
                  <Row>
                    {!isCompanyContacts && (
                      <>{checkBoxShow ? (
                        <Col className="pe-0">
                          <Button
                            variant=""
                            type="button"
                            onClick={this.clearSelect}
                            className="clearSelection "
                          >
                            Clear
                          </Button>
                        </Col>
                      ) : <Col md='4'></Col>}
                        <Col className="pe-0">
                          {!checkBoxShow ? (
                            !numberMask && (
                              <Link
                                to={
                                  this.props?.channelData?.length > 0
                                    ? "./import"
                                    : ""
                                }
                              >
                                <Button
                                  variant=""
                                  type="button"
                                  className="clearSelection "
                                >
                                  <svg
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="me-1"
                                  >
                                    <path
                                      d="M11.25 13L3.75 13M7.5 3L7.5 10.5M7.5 10.5L5.3125 8.3125M7.5 10.5L9.6875 8.3125"
                                      stroke="#423B3B"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  Import
                                </Button>
                              </Link>
                            )
                          ) : (
                            <Button
                              variant=""
                              type="button"
                              className="sendButton"
                              onClick={this.swapAccountModal}
                            >
                              Account/Lead
                            </Button>
                          )}
                        </Col>
                        <Col className="pe-0">
                          {!checkBoxShow ? (
                            <Button
                              variant=""
                              type="button"
                              className="clearSelection"
                              disabled={this.state.ContactCount === 0 ? true : false}
                              onClick={this.exportExcel}
                            >
                              {!this.state.isExcelLoading && (
                                <svg
                                  width="15"
                                  height="16"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="me-1"
                                >
                                  <path
                                    d="M3.75 3H11.25M7.5 13V5.5M7.5 5.5L9.6875 7.6875M7.5 5.5L5.3125 7.6875"
                                    stroke="#423B3B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              )}
                              <span className="ml-1">
                                {this.state.isExcelLoading ? "Loading..." : "Export"}
                              </span>
                            </Button>
                          ) : (
                            <>

                              <Button
                                variant=""
                                type="button"
                                onClick={() => { this.setState({ isAssignTag: true }) }}
                                className="sendButton"
                              >
                                <FiTag /> Assign Tag
                              </Button>
                            </>
                          )}
                        </Col>
                        {!checkBoxShow ? (
                          <Col className={`pe-2`}>
                            <OverlayTrigger 
                               placement="bottom"
                               overlay={this.state.isInvalidAccess ? <Tooltip> you don't have permission </Tooltip> : <></>}
                            >
                            <span className={`${this.state.isInvalidAccess ? "notAllowed" : ""}`}>
                              <Button
                                onClick={this.handleClickAdd}
                                variant=""
                                type="button"
                                className="sendButton w-100"
                                disabled={numberMask || this.state.isInvalidAccess}
                              >
                                <FontAwesomeIcon icon={faPlus} /> Add New Contact
                              </Button>
                            </ span>
                            </OverlayTrigger>
                          </Col>
                        ) : (
                          this.props?.profileDetail?.role?.name !== 'MEMBER' && (
                            <Col className={'pe-2'}>
                              <Button
                                variant=""
                                type="button"
                                className="sendButton w-100 p-2"
                                onClick={this.DeleteSelect}
                              >
                                <FontAwesomeIcon icon={faTrashCan} className="me-1" />
                                {/* testing */}
                                Delete ({selectedRows?.length})
                                {/* production */}
                                {/* Delete selected ({selectedUids.length}) */}
                              </Button>
                            </Col>
                          )
                        )}
                        {checkBoxShow && (
                          <Col>
                            <Button
                              className="sendButton w-100"
                              onClick={this.listOfSequence}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="side-menu__icon"
                                fill="white"
                                height="1em"
                                viewBox="0 0 512 512"
                              >
                                <path d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z" />
                              </svg>
                              Sequence
                            </Button>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
              {isAssignTag && (
                <>
                  <Modall
                    isOpen={isAssignTag}
                    onClose={this.closeAssignModal}
                    title={"Assign Tag"}
                    size="md"
                    component='ADDTAG_CONTACT'
                  >
                    <AddTags
                      selectedContact={selectedRows}
                      fetchContacts={this.fetchContacts}
                      closeAssignModal={this.closeAssignModal}
                      clearSelect={this.clearSelect}
                    />
                  </Modall>
                </>
              )}
              {showContent && (
                <RightSidebar
                  handleCancel={this.handleCancel}
                  title={!showEdit ? "Add Contact" : "Edit Contact"}
                  width="30vw"
                >
                  {!showEdit ? (
                    <AddContact
                      fetchContacts={this.fetchData}
                      handleCancel={this.handleCancel}
                    ></AddContact>
                  ) : (
                    <>
                      {ContactEdit !== null && (
                        <Edit
                          contactEdit={ContactEdit}
                          // filterCount = {filterCount}
                          // filterPayload = {filterPayload}
                          handleCancel={this.handleCancel}
                          fetchContacts={this.fetchData}
                          companyLists={companyList}
                          getCompanyContacts={this.getCompanyContacts}
                          setTagsCount={(count: number) => this.setState({ tagsCount: count })}
                          isInvalidAccess={this.state.isInvalidAccess}
                        />
                      )}
                    </>
                  )}
                </RightSidebar>
              )}
              <Row className="pb-4 d-flex align-items-center">
                <Col md="10">
                  <div>
                    Selected :{" "}
                    <span style={{ color: "#007EC3" }}>
                      {/* testing */}
                      {selectedRows?.length} {/* production */}
                      {/* {selectedUids.length}  */}
                      of {ContactCount} Contacts{" "}
                    </span>
                  </div>
                </Col>
                <Col md="2" className="d-flex justify-content-end">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon icon={faTags} style={{ marginRight: "7px" }} />
                    <div style={{ marginTop: "2px" }}>{tagsCount} tags</div>
                  </div>
                </Col>
                {/* PRODUCTION---- */}
                {/* {checkBoxShow && (
                  <Col>
                     <Button
                      className="sendButton w-100"
                      onClick={this.listOfSequence}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="side-menu__icon"
                        fill="white"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z" />
                      </svg>
                      Trigger Sequence
                    </Button>
                  </Col>
                )} */}
              </Row>
              {isPublishSequence && (
                <>
                  <Modal
                    isOpen={isPublishSequence}
                    onClose={this.closePublishModal}
                    toggle={this.closePublishModal}
                    title={"Push contacts to sequence"}
                    size="lg"
                  >
                    {/* <ModalHeader toggle={this.closePublishModal}> */}
                    <Row className="p-3">
                      <Col md={11}>
                        <h6 className="title">Trigger Sequence</h6>
                      </Col>
                      <Col>
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          icon={faTimes}
                          className="ms-2"
                          onClick={this.closePublishModal}
                        />
                      </Col>
                    </Row>
                    <hr />
                    {/* </ModalHeader> */}

                    <Formik
                      initialValues={{ sequence: "" }}
                      onSubmit={async (values) => {
                        const { sequence } = values;
                        const { selectedUids, selectedRows } = this.state;
                        const { channelId } = this.props;
                        const selectedRow = selectedRows.map((item: any) => item.uid)
                        const uidsArray = Array.from(selectedUids) as any;
                        const payload = {
                          sequenceUid: sequence,
                          contactUids: selectedRow,
                        };
                        const response = await subscribeToSequence(payload);
                        if (response) {
                          this.setState({
                            isPublishSequence: false,
                            selectedRowsUid: new Set(),
                            selectedRows: [],
                            checkBoxShow: false,
                            allChecked: false,
                          }, () => {
                            toast(
                              "success",
                              `${selectedRows?.length} contacts has been pushed to sequence successfully`
                            );
                            // this.fetchContacts();
                          });
                          this.fetchData(0, 20, '', 0)
                          const { tableReset } = this.props;
                          if (tableReset) {
                            tableReset()
                          }
                          // this.clearSelect();

                        }
                      }}
                      validationSchema={Yup.object({
                        sequence: Yup.string().required("Sequence is required"),
                      })}
                    >
                      {({ setFieldValue, handleSubmit, values }) => (
                        <Form>
                          <ModalBody>
                            <div className="col-10 waring-alert">
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="mr-2"
                              />
                              The chosen contacts will be added to the sequence
                              regardless of the triggering conditions.
                            </div>
                            <Row className="d-flex align-items-center pt-2">
                              <Col md={5}>
                                <div>
                                  Sequence{" "}
                                  <span className="redstar_required"></span>
                                </div>
                              </Col>
                              <Col md={5}>
                                <Field name="sequence">
                                  {({ field, form }: any) => (
                                    <Select
                                      options={
                                        sequenceList &&
                                        sequenceList?.map(
                                          (item: any) =>
                                          ({
                                            label: item?.sequenceName,
                                            value: item.uid,
                                          } as any)
                                        )
                                      }
                                      className="w-100 "
                                      value={
                                        sequenceList &&
                                        sequenceList.find(
                                          (option: any) =>
                                            option?.value === field?.value
                                        )
                                      }
                                      placeholder="Select Sequence"
                                      onChange={(option: any) => {
                                        form.setFieldValue(
                                          field.name,
                                          option.value
                                        );
                                      }}
                                    // isClearable={true}
                                    // styles={customStyles}
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  className="text-danger pt-1"
                                  name="sequence"
                                  component="div"
                                />
                              </Col>
                            </Row>
                          </ModalBody>
                          <Row className="d-flex align-items-center pt-2">
                            <ModalFooter>
                              <Col>
                                {selectedRows?.length}{" "}
                                {/* {selectedUids.length} */}
                                Contacts selected
                              </Col>
                              <Col className="d-flex justify-content-end">
                                <Button
                                  className="cancelButton me-2"
                                  onClick={() =>
                                    this.setState({ isPublishSequence: false })
                                  }
                                >
                                  Cancel
                                </Button>
                                <Button className="sendButton" type="submit">
                                  Confirm
                                </Button>
                              </Col>
                            </ModalFooter>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </Modal>
                </>
              )}
              <Modall
                isOpen={this.state.isOpen}
                onClose={this.closeModal}
                title={"Change into Account/Lead"}
                size="md"
              >
                <div className="text-center">
                  <Formik
                    initialValues={{ type: "Account" }}
                    onSubmit={() => { }}
                  >
                    {({ setFieldValue, handleSubmit }) => (
                      <Form>
                        <div role="group" aria-labelledby="my-radio-group">
                          <label className="mr-5 h6 text-muted">
                            <Field
                              type="radio"
                              name="type"
                              value="ACCOUNT"
                              checked={this.state.selectedType === "ACCOUNT"}
                              onChange={this.handleRadioChange}
                              className="mr-1"
                            />
                            Account
                          </label>
                          <label className="h6 text-muted ml-5">
                            <Field
                              type="radio"
                              name="type"
                              value="LEAD"
                              checked={this.state.selectedType === "LEAD"}
                              onChange={this.handleRadioChange}
                              className="mr-1"
                            />
                            Lead
                          </label>
                        </div>
                        <Button
                          type="button"
                          className="sendbutton w-25 p-0 mt-3 ml-2"
                          onClick={() =>
                            this.swapAccount(this.state.selectedType)
                          }
                        >
                          Submit
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Modall>
            </div>

            <Row>
              {/* {isCustomFilter &&( */}
              {this.state.isCustomFilter && (
                <Col
                  md={2}
                  className={`contact-filter-container   p-1  ${this.state.isCustomFilter ? "move-left" : ""
                    }`}
                >
                  <ContactFilter
                    tagsList={tagsList}
                    toggleQuickPopover={this.toggleQuickPopover}
                    filterContacts={this.filterContacts}
                    filterData={filterData}
                    fetchContacts={this.fetchContacts}
                    isCompanyContacts={isCompanyContacts}
                    getCompanyContacts={this.getCompanyContacts}
                    companyCount={this.state.companyCount}
                    pageSize={this.state.paginationPaload?.pageSize}
                  // tagsAddRemove={this.tagsAddRemove}
                  />
                </Col>
              )}
              {/* )} */}
              <Col
                className={`${this.state.isCustomFilter ? "p-0 col-md-10" : ""
                  }`}
              >
                {/* <div className="contactTable"> */}
                {/* <BootstrapTable
                data={ContactDatas}
                selectRow={
                  this.props?.profileDetail?.role?.name === "OWNER"
                    ? selectRowProp
                    : undefined
                }
                // search
                pagination
                remote={isCompanyContacts || isFilter ? false : true}
                fetchInfo={{
                  dataTotalSize: this.paginationLimit * totalPages,
                }}
                version="4"
                striped
                hover
                containerStyle={{ textAlign: "left" }}
                options={options as any}
              >
                <TableHeaderColumn dataField="id" isKey hidden>
                  #
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="name"
                  dataAlign="left"
                  dataFormat={this.nameData}
                  width="140"
                >
                  Name
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="mobileNumber"
                  dataAlign="left"
                  dataFormat={this.mobileData}
                  width="150"
                >
                  Mobile Number
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="email"
                  dataFormat={this.emailData}
                  dataAlign="left"
                  width="220"
                >
                  Email
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="contactType"
                  dataAlign="center"
                  width="150"
                >
                  Contact Type
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="tags"
                  dataFormat={this.tagsData}
                  dataAlign="left"
                  width="150"
                >
                  Tags
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="source"
                  dataFormat={this.sourceData}
                  dataAlign="center"
                  width="100"
                >
                  Source
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="edit"
                  dataFormat={this.renderEditColumn}
                  dataAlign="center"
                  width="70"
                >
                  Actions
                </TableHeaderColumn>
              </BootstrapTable> */}
                {/* </div>  */}
                <BizTable
                  columns={this.columns}
                  fetchData={this.fetchData}
                  counts={this.state.editCompany ? 0 : this.state.ContactCount}
                  refetchData={this.state.ContactLists}
                  tableName='CONTACTS'
                  searchTerm={this.state.searchTerm ?? ''}
                  isClear={this.state.isClearSelected}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    businessId: state.cartreducer.business?.business?.uid,
    profileDetail: state.cartreducer.business,
    memberData: state.cartreducer.memberData,
    channelId: state.cartreducer.channelUid.value,
    channelData: state.cartreducer.channelData,
    selectedTableData: state.tableReducer.selectedUids,
    roleRules: state.cartreducer.roleRules,
  };
};

const mapDispatchToProps = {
  tableReset,
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
