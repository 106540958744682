import { toast } from "../common/alert";
import axios from "../utils/axios";


interface TemplateText {
  bodyText: string;
  ownValue: boolean;
  componentType: string;
  position: number;
}

interface TemplateMedia {
  id: number;
}

interface Template {
  id: string;
  media: TemplateMedia;
  templateText: TemplateText[];
}

interface Bot {
  uid: string;
}

interface Team {
  uid: string;
}

interface ModifyTicketConfigData {
  id: number;
  bot: Bot;
  team: Team;
  template: Template;
}

export const getTicketFields = async (channelUid: string) => {
  try {
    const response = await axios.get(`ticket/${channelUid}/ticket_fields`);
    return response;
  } catch (error: any) {
    toast("error", error.message);
   }
};

export const getTicketBotbyBusinessUid = async (businessUid: string) => {
  try {
    const response = await axios.get(`/bot/${businessUid}/getTicketBasedBots`);
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
   }
};

export const getTicketConfig = async (channelUid: string) => {
  try {
    const response = await axios.get(`ticket/${channelUid}/getTicketConfig`);
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
   }
};

export const postTicketConfig = async (uid: string, data: ModifyTicketConfigData): Promise<any> => {
  try {
    const response = await axios.post(`ticket/${uid}/modifyTicketConfig`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    toast("success", "Configuration updated successfully");
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const ticketFilter = async(data: any) => {
  try {
    const response = await axios.post(`ticket/getTicketBasedFilter`, data);
    return response.data;
  } catch (error: any) {
    // toast("error", "Tickets ends here");
    console.log(error);
  }
}

export const createTicket = async (payload: {
  contactUid: string;
  channelUid: string;
  fields: { ticketField: { id: string }; value: string }[];
}) => {
  try {
    const ticket = await axios.post(`ticket/create`, payload);
    return ticket.data;
  } catch (err: any) {
    toast("error", err.response.data.message);
    throw err;
  }
};

export const patchTicketField = async (fieldId: string, data: any) => {
  try {
    const response = await axios.patch(
      `/ticket/${fieldId}/ticket_fields`,
      data
    );
    toast(
      "success",
      "Your ticket field configurations have been saved successfully!"
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const deleteTicketField = async (Uid: string, data: any) => {
  try {
    const response = await axios.delete(`/ticket/${Uid}/ticket_field`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
    toast(
      "success",
      "Your ticket field configuration has been deleted successfully!"
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const deleteTicketOption = async (Uid: string, data: any) => {
  try {
    const response = await axios.delete(`/ticket/${Uid}/ticket_field_option`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
    // toast("success", "Your ticket option configuration has been deleted successfully!");
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const getAllTickets = async (payload: {
  channelUid: string;
  page: number;
  limit: number;
}) => {
  try {
    const tickets = await axios.post("ticket/getAll", payload);
    return tickets.data;
  } catch (err) {
    return err;
  }
};

export const getAllTicketBasedOnChannel = async (payload: {
  channelUid: string;
  page: number;
  limit: number;
  search?: string;
  memberUid?: string[];
}) => {
  let url: string = payload.search ? 'ticket/getTicketBasedFilter' : 'ticket/getAllTicketBasedChannel';
  try {
    const response = await axios.post(
      url,
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const patchTicketPropertiesUpdate = async (
  ticketUid: string,
  payload: any,
  type?: string
) => {
  try {
    const response = await axios.patch(`/ticket/${ticketUid}/update`, payload);
    toast("success", type === 'memberAssign' ? "You are assigned to this ticket" :"Ticket properties have been updated successfully!");
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const getSLA = async (channelUid: any) => {
  try {
    const response = await axios.get(`/ticket/${channelUid}/sla`);
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const updateSLA = async (channelUid: any, payload: any) => {
  try {
    const response = await axios.patch(`/ticket/${channelUid}/sla`, payload);
    toast("success", "Your SLA Policy has been updated successfully!");
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
}

//---------------------------- Ticket Dashboard --------------------------// 

export const ticketsDashBoard =async(channelUid:any,dashbaordPayload:any)=>{
  try{
    const response = await axios.post(`ticket/ticketsDashBoard?channelUid=${channelUid}`,dashbaordPayload)
    return response.data;
  }
  catch(error:any){
    toast('error',error.message)
  }
}


export const getTicketLogs = async(businessUid:any, ticketId:any)=>{
  try{
    const response = await axios.get(`activityLog/${businessUid}/${ticketId}/getTicketLogs`)
    return response.data;
  }
  catch(error:any){
    toast('error',error.message)
  }
}