import { useEffect, useMemo, useState } from 'react';
import { Button, FormControl, Spinner } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import styles from './fileInput.module.css';
import { toast } from "../../../../common/alert";
import removeVariables from '../../../utils/removeVariables';
import scanForVariables from '../../../utils/scanForVariables';
import { useAppSelector } from '../../../hooks';
import AxiosInstance from '../../../../utils/axios';
import { Label } from 'reactstrap';
import AddVariable from './addVariable';
import axios from 'axios';

export interface IFile {
    channelDialogId: string,
    fileName: string,
    fileSize: number,
    mediaId: string,
    id: number,
    mediaType: string,
    provider: any,
    url: string
};


interface FileInputProps {
    setFile: (data: IFile) => void;
    acceptType?: string;
    mediaUrlName?: string;
    mediaUrl: string;
    dynamic?: boolean;
    mediaType: 'image' | 'audio' | 'video' | 'document';
    media: any;
    type: string;
    setDisabled?: (bool: boolean) => void;
}

export const isValidLink = (mediaType: 'image' | 'audio' | 'video' | 'document', mediaUrl: string): boolean => {
    const foundVariables = scanForVariables(mediaUrl).length > 0;
    const parsedUrl = removeVariables(mediaUrl).trim();
    if (foundVariables) {
        return true;
    }

    switch (mediaType) {
        case 'video':
            const videoExtensions = /\.(mp4|avi|mkv|mov|flv|wmv)$/i;
            return videoExtensions.test(parsedUrl);
        case 'image':
            const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)(\?.*)?$/i;
            return imageExtensions.test(parsedUrl);
        case 'audio':
            const audioExtensions = /\.(mp3|wav|ogg|flac)$/i;
            return audioExtensions.test(parsedUrl);
        case 'document':
            const documentExtensions = /\.(pdf|docx|txt|pptx|xlsx)$/i;
            return documentExtensions.test(parsedUrl);
        default:
            break;
    }
    return false;
}

const FileInput: React.FC<FileInputProps> = props => {
    const [inputMode, setMode] = useState(0);
    const [uploading, setUploading] = useState(false);
    const formik = useFormikContext();
    const currentChannelUId = useAppSelector((state) => state.meta.channelUid);
    useEffect(() => {
        if (props.mediaUrl.trim() !== '' && !props?.dynamic) {
            setMode(1);
        }
        else if(props.mediaUrl.trim() !== '' && props?.dynamic) {
            setMode(2);
        }
    }, [props.mediaUrl]);
    const invalidLink = useMemo(() => {
        if (props.mediaUrl.trim() !== '' && (props.mediaType === 'image' || props.mediaType === 'video' || props.mediaType === 'document') && (!isValidLink(props.mediaType, props.mediaUrl) && !props?.dynamic)) {
            return true;
        }
        return false;
    }, [props.mediaUrl]);

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            await uploadFile(file);
        }
    };

    const uploadFile = async (file: File) => {
        const allowedSize: number = props.mediaType === 'image' ? 2 : (props.mediaType ==='audio' || props?.mediaType === "video") 
                                    ? 16 : props.mediaType === "document" ? 100 : 0.5;
        if (file.size > allowedSize * 1024 * 1024) {
            toast("error", "File size too large!, Please select a file smaller than " + allowedSize + "MB." )
            return;
        }
        setUploading(true);
        const formData = new FormData();
        formData.append('content', file);

        try {
            const response = await AxiosInstance.post('media/saveMessageMedia?uid=' + currentChannelUId, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                }
            });
            props.setFile(response.data);

        } catch (error) {
            console.error(error);
            toast("error", "Failed to upload!");
        } finally {
            setUploading(false);
        }
    };

    const uploadFileFromLink = async (url: string) => {
        if (url.trim() !== '' && isValidLink(props.mediaType, url)) {

            const allowedSize: number = props.mediaType === 'image' ? 2 : (props.mediaType === 'audio' || props?.mediaType === "video")
            ? 16 : props.mediaType === "document" ? 100 : 0.5;

            try {
                const encodedUrl = encodeURIComponent(url);
                const response = await axios.get('https://biz.vamosys.com/data/getFileDetails?fileUrl='+encodedUrl);
                if (response && response.data) {
                    const fileSize = response.data['Content-Length'];
                    if (Number(fileSize) > allowedSize * 1024 * 1024) {
                        toast("error", "File size too large!, Please select a file smaller than " + allowedSize + "MB.");
                        props.setDisabled && props?.setDisabled(true);
                        return true; 
                    }
                } else {
                    console.error('Failed to fetch file size');
                    return false; 
                }
            } catch (error) {
                console.error('Error fetching file size:', error);
                return false; 
            }

            props.setDisabled && props?.setDisabled(false);

            try {
                setUploading(true);
                const response = await AxiosInstance.post('media/saveMediaFromUrl', {
                    url,
                    channelId: currentChannelUId
                });
                setUploading(false);
                props.setFile(response.data);
            } catch (error) {
                toast("error", "Failed to upload!");
            }
        }
    }

    if (uploading || !currentChannelUId) {
        return <Spinner />
    }

    return (
        <>
            {props.acceptType ? (
                <p style={{ margin: '12px 0' }}>File type allowed : {props.acceptType}</p>
            ) : null}
            {props.type === "sendPayments" && (
                <p>Max file size: 5 MB</p>
            )}

            <div className={styles.row}>
                <input
                    type="file"
                    id="fileInput"
                    onChange={onFileChange}
                    style={{ display: 'none' }}
                    accept={props.acceptType}
                />
                <Button
                    onClick={() => {
                        setMode(0);
                        document.getElementById('fileInput')?.click();
                        formik.setFieldValue(props.mediaUrlName || 'mediaUrl', '');
                        formik.setFieldValue('dynamic',false);
                        props.setDisabled && props?.setDisabled(false);
                    }}
                    className="sendButton"
                    id={inputMode === 0 ?  `${styles.highLight}` : `${styles.linkButton}`}
                >
                    Upload
                </Button>
                
                {props.type !== "sendPayments" && (
                    <Button
                        onClick={() => {setMode(1)
                            formik.setFieldValue('dynamic',false);
                            formik.setFieldValue('mediaUrl', '');
                            }
                        }
                        className="sendButton"
                        id={inputMode === 1 ?  `${styles.highLight}` : `${styles.linkButton}`}
                    >
                        Link
                    </Button>
                )
                }

                {props.type === "sendMedia" && (
                    <Button
                        onClick={() => {setMode(2)
                            formik.setFieldValue('dynamic',true);
                            props.setDisabled && props?.setDisabled(false);
                            formik.setFieldValue('mediaUrl', '');
                        }}
                        className="sendButton"
                        id={inputMode === 2 ?  `${styles.highLight}` : `${styles.linkButton}`}
                    >
                        Dynamic Link
                    </Button>
                )
                }

            </div>

                        {(() => {
                switch (inputMode) {
                    case 0:
                        return null;
                    case 1:
                        return uploading ? <Spinner /> : (
                            <div className='mt-3'>
                                <Label>Media Link <span className='required'></span></Label>
                                <FormControl
                                    name={props.mediaUrlName}
                                    value={props.mediaUrl}
                                    as='input'
                                    isInvalid={invalidLink}
                                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                                        formik.setFieldValue(props.mediaUrlName || 'mediaUrl', event.target.value);
                                        if (props.mediaUrl?.length < event.target.value?.length) {
                                            uploadFileFromLink(event.target.value);
                                        }
                                    }}
                                    isValid={false}
                                />
                                {invalidLink ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        Invalid {props.mediaType}
                                    </div>
                                ) : null}
                            </div>
                        );
                    case 2:
                        return (
                                <div className='mt-3'>
                                <Label>Dynamic Media Link <span className='required'></span></Label>
                                <FormControl
                                    name={props.mediaUrlName}
                                    value={props.mediaUrl}
                                    as='input'
                                    isInvalid={invalidLink}
                                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                                        formik.setFieldValue(props.mediaUrlName || 'mediaUrl', event.target.value);
                                       
                                        // No upload logic here, just storing the link
                                    }}
                                    isValid={false}
                                />
                                {invalidLink ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        Invalid {props.mediaType}
                                    </div>
                                ) : null}
                                <AddVariable static product fieldName='mediaUrl' fieldValue={props?.mediaUrl} limit={1024}/>
                            </div>
                        );
                    default:
                        return null;
                }
            })()}
        </>
    )
}

FileInput.defaultProps = {
    acceptType: '*',
    mediaUrlName: 'mediaUrl',
};

export default FileInput;
