import { useEffect, useRef, useState } from "react";
import * as appointmentService from "../../../services/appointmentService";
import { extendMoment } from "moment-range";
import * as originalMoment from "moment";
import getAdjustedDateRange from "../../../utils/getAdjustedDateRange";
import { useSelector } from "react-redux";
import { Container, Input } from "reactstrap";
import styles from "./Dashboard.module.scss";
import {
  faCancel,
  faCheck,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { BsCalendar } from "react-icons/bs";
import { Overlay, Popover } from "react-bootstrap";
import DateRangePicker from "react-daterange-picker";
import downloadExcelforDashboard from "./ExcelforDashboard";
import downloadExcel from "../downloadExcel";

enum DateFilterType {
  Past7Days = "Past7Days",
  Past30Days = "Past30Days",
  Past90Days = "Past90Days",
  ThisWeek = "ThisWeek",
  PreviousWeek = "PreviousWeek",
  ThisMonth = "ThisMonth",
  PreviousMonth = "PreviousMonth",
  CustomRange = "CustomRange",
}
const moment = extendMoment(originalMoment);

const AppointmentDashboardTwo = () => {
  const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const today = moment().clone().startOf("day");
  const [dateRangeValue, setDateRangeValue] = useState(
    moment.range(
      today.clone().subtract(7, "days").startOf("day"),
      today.endOf("day")
    )
  );
  const [isValueSelected, setIsValueSelected] = useState(false);
  const { start: adjustedStart, end: adjustedEnd } = getAdjustedDateRange(
    dateRangeValue.start,
    dateRangeValue.end
  );
  const piChartColours = ["#FB8C00", "#dc3545", "#28a745"];
  const datepickerTarget = useRef<HTMLDivElement | null>(null);
  const userProfile = useSelector((state: any) => state.cartreducer.business);
  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const businessName = useSelector(
    (state: any) => state?.cartreducer?.channelUid.label
  );
  const datepickertarget = useRef(null);
  const memid = useRef(userProfile.uid);
  const [member, setmember] = useState("all");
  const [memberName, setmemberName] = useState(userProfile?.name);
  const [allMember, setAllMember] = useState([]);
  const [appointmentsTotal, setAppointmentsTotal] = useState([
    {
      id: 1,
      label: "Total",
      icon: "",
      value: 0,
    },
    {
      id: 2,
      label: "Pending",
      icon: faWarning,
      value: 0,
    },
    {
      id: 3,
      label: "Completed",
      icon: faCheck,
      value: 0,
    },
    {
      id: 4,
      label: "Cancelled",
      icon: faCancel,
      value: 0,
    },
  ]);

  console.log(appointmentsTotal, "appointmentsTotal");

  const changeDateFilterHandler = (event: any) => {
    const getStartDate = (
      subtractDays: number,
      unit: moment.unitOfTime.DurationConstructor = "days"
    ) => {
      return today.clone().subtract(subtractDays, unit).startOf(unit);
    };

    const filter = event.target.value as DateFilterType;

    switch (filter) {
      case DateFilterType.Past7Days:
        setDateRangeValue(
          moment.range(
            today.clone().subtract(7, "days").startOf("day"),
            today.endOf("day")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.Past30Days:
        setDateRangeValue(moment.range(getStartDate(30), today.endOf("day")));
        setShowDatePicker(false);
        break;

      case DateFilterType.Past90Days:
        setDateRangeValue(moment.range(getStartDate(90), today.endOf("day")));
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisWeek:
        setDateRangeValue(
          moment.range(today.clone().startOf("week"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousWeek:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "week"),
            getStartDate(1, "week").endOf("week")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisMonth:
        setDateRangeValue(
          moment.range(today.clone().startOf("month"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousMonth:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "month"),
            getStartDate(1, "month").endOf("month")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.CustomRange:
        setShowDatePicker(true);
        break;

      default:
        break;
    }
    setDateFilter(filter);
    setIsValueSelected(true);
  };

  const getDataBasedOnMember = (memUid: string) => {
    appointmentService
      .getAppointmentAnalytics(
        memUid,
        adjustedStart.format("YYYY-MM-DD"),
        adjustedEnd.format("YYYY-MM-DD")
      )
      .then((res) => console.log(res, 'getDataBasedOnMember'));
  };

  const changeMemberFilter = (event: any) => {
    console.log(event.target.value, "event");

    const target = event.target.value;
    if (target === "all") {
      setmember("all");
      setIsValueSelected(true);
    } else {
      setmember(target);
      setmemberName(event.target.options[event.target.selectedIndex].text);
      getDataBasedOnMember(target);
      //   memid.current = target;
      //   analyticsQuery.refetch();
    }
  };

//   const handleDownload=()=>{
//     downloadExcelforDashboard(appointmentQueryBasedonBusiness.data,userProfile.name);
//   }
//   const dataQuery=appointmentQueryBasedonBusiness?.data?.filter((data:any)=>data.memberUid===member)

//   const handleMemberDowload=()=>{
//     downloadExcel(dataQuery,memberName)
//   }

  useEffect(() => {
    appointmentService.getAllMemberConfiguration(businessUid).then((res) => {
      setAllMember(res);
    });
  }, [businessUid]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datepickerTarget.current &&
        !datepickerTarget.current.contains(event.target as Node)
      ) {
        setShowDatePicker(false);
      }
    };
    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDatePicker]);

  useEffect(() => {
    if (isValueSelected) {
      appointmentService
        .getAppointmentAnalyticsBasedonBusiness(
          businessUid,
          adjustedStart.format("YYYY-MM-DD"),
          adjustedEnd.format("YYYY-MM-DD"),
          "business"
        )
        .then((res: any) => {
          const updatedAppointmentsTotal = appointmentsTotal.map(
            (appointment) => {
              switch (appointment.label) {
                case "Total":
                  return { ...appointment, value: res.appointmentCount };
                case "Pending":
                  return {
                    ...appointment,
                    value: res.totalPendingAppointments,
                  };
                case "Completed":
                  return {
                    ...appointment,
                    value: res.totalCompletedAppointments,
                  };
                case "Cancelled":
                  return {
                    ...appointment,
                    value: res.totalCancelledAppointments,
                  };
                default:
                  return appointment;
              }
            }
          );

          setAppointmentsTotal(updatedAppointmentsTotal);
        });
    }
  }, [isValueSelected]);

  useEffect(() => {
    if (isValueSelected) {
      setTimeout(() => {
        setIsValueSelected(false);
      }, 2000);
    }
  }, [isValueSelected]);

  return (
    <Container>
      <h3 className={styles.caption}>{businessName}-Appointments</h3>

      <div className={styles.analyticsPanel}>
        <div className={styles.numbers}>
          {appointmentsTotal.map((el) => {
            return (
              <div className={styles.numberItem} key={el.label}>
                <div className={styles.numberCount}>{el.value}</div>
                <div className={styles.numberLabel}>
                  {<FontAwesomeIcon icon={el.icon as IconProp} />}
                  {el.label}
                </div>
              </div>
            );
          })}
        </div>

        <div className={styles.daterange}>
          <Input
            className={styles.dateFilter}
            type="select"
            size={1}
            onChange={changeMemberFilter}
          >
            <option value="all">All</option>
            {allMember?.map((item: any, index: any) => (
              <option key={index} value={item.memberUid}>
                {item?.memberName.substring(0, 17)}
              </option>
            ))}
          </Input>

          <Input
            className={styles.dateFilter}
            type="select"
            size={1}
            value={dateFilter}
            onChange={changeDateFilterHandler}
          >
            <option value={DateFilterType.Past7Days}>Past 7 days</option>
            <option value={DateFilterType.Past30Days}>Past 30 days</option>
            <option value={DateFilterType.Past90Days}>Past 90 days</option>
            <option value={DateFilterType.ThisWeek}>This week</option>
            <option value={DateFilterType.PreviousWeek}>Previous week</option>
            <option value={DateFilterType.ThisMonth}>This month</option>
            <option value={DateFilterType.PreviousMonth}>Previous month</option>
            <option value={DateFilterType.CustomRange}>Custom range</option>
          </Input>
        </div>

        <div
          className={"form-control " + styles.calenderInput}
          ref={datepickertarget}
          onClick={() => setShowDatePicker(!showDatePicker)}
        >
          <BsCalendar />
          <span className="value ml-2">
            {dateRangeValue.start.format("DD/MM/YYYY")}
            {" - "}
            {dateRangeValue.end.format("DD/MM/YYYY")}
          </span>
        </div>
        <Overlay
          target={datepickertarget.current}
          show={showDatePicker}
          placement="bottom"
          ref={datepickerTarget}
        >
          <Popover id="popover-basic" style={{ maxWidth: "350px" }}>
            <DateRangePicker
              value={dateRangeValue}
              onSelect={(value: any) => {
                const startDate = moment(value.start).isAfter(moment())
                  ? moment()
                  : moment(value.start);
                const endDate = moment(value.end).isAfter(moment())
                  ? moment()
                  : moment(value.end);
                setDateRangeValue(
                  moment.range(startDate.startOf("day"), endDate.endOf("day"))
                );
                setDateFilter(DateFilterType.CustomRange);
                setShowDatePicker(false);
              }}
              singleDateRange={true}
            />
          </Popover>
        </Overlay>
      </div>
    </Container>
  );
};

export default AppointmentDashboardTwo;
