import React, { useEffect, useRef, useState, useContext } from "react";
import initialContext from './useContext';


let autoComplete;

const loadScript = (url, callback) => {
  // Check if the script has already been loaded
  if (window.google && window.google.maps && window.google.maps.places) {
    callback();
    return;
  }

  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};


const SearchLocationInput = ({ setSelectedLocation, setSelectedName, initialValue, setField }) => {
  const [query, setQuery] = useState(initialValue || "");
  const autoCompleteRef = useRef(null);
  const initial = useContext(initialContext);

  useEffect(() => { 
    if(query === "" && initialValue) {
      setSelectedName(null);
      setField(null);
      initial.edit = false;
    }
  }, [query]);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {

    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error("Google Maps Places library not loaded");
      return;
    }

    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        componentRestrictions: { country: "IN" },
      }
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });

    
    const customStyles = document.createElement("style");
    customStyles.innerHTML = `
      .pac-container {
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 8px;
        font-family: Arial, sans-serif;
        position: relative;
        z-index: 1500 !important; 
      }
    `;
    document.head.appendChild(customStyles);
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);

    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };

    setSelectedLocation(latLng);
    setSelectedName(query);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCtZ3fMsIXu8KNTEkRpD7M39AX-VRhqi9Q&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
    <div style={{ position: "relative", margin: "0"}}>
      <input
        ref={autoCompleteRef}
        className="form-control"
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Search Places ..."
        id="autocomplete"
        value={query}
      />
    </div>
  );
};

export default SearchLocationInput;
