import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { ErrorMessage, Formik, useFormikContext } from 'formik';
import { Alert, Button, Col, Form, FormCheck, Offcanvas, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import '.././editor.module.scss'
import { Label } from 'reactstrap';
import classes from '.././catalogue/sendProducts.module.scss';
import tags from '.././actions/updateConversationTag.module.scss';
import { useQuery } from '@tanstack/react-query';
import ReactSelect from 'react-select';
import { sliceText } from '../../../../../common/sliceText';
import { CONTACT_SUPPORT_TEAM, FLOW_NOT_FOUND, SOMETHING_WENT_WRONG } from '../../../../../constants/errorMessage';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { jumpToIcon } from '../../../../icons';
import { uuidv4 } from '../../../../utils/uuid';
import AdvancedSettings from '../askType/advancedSettings';
import { SelectMember } from '.././assignConversation';
import BodyInput from '../bodyInput';
import EditorCaption from '../editorCaption';
import { useElmentEditor } from '.././hooks';
import JSONEditorComponent from '../jsonEditor';
import { IEditorProps } from '../types';
import * as flowServices from '../../../../services/flows';
import { SelectVariable } from '../setVariable';
import { IStorageVariable } from '../../../../entity/Variable';
import { BsInfoCircleFill } from 'react-icons/bs';
import infoCss from '../catalogue/sendOrderDetails.module.scss';
import { createVariable } from '../../../../store/graphSlice';


type MemberUid = {
    id: string,
    uid: string
}
type Session = {
    id: string,
    title: string
}

interface FormData {
    bodyText: string,
    flowData: {
        type: null | string,
        flowId: string,
        buttonName: string,
        memberUids: MemberUid[] | null // for type === 'caly_appoinment'
        sessions: Session[]  //for type==='caly_appoinment'
        dynamicMember: boolean,
        bookingWaited: boolean,
        confirmationMsg: string
    },
    attempt: number,
    errorMessage: string,
    endFlow: boolean,
    isSameFlow: boolean,
    storageVariable: IStorageVariable<'OBJECT'>
};

type SelectMembersProps = {
    existingSelectedMemberIds: string[];
}
function SelectMembers({ existingSelectedMemberIds }: SelectMembersProps) {
    const formik = useFormikContext<FormData>();
    const businessUid = useAppSelector((state) => state.meta.businessUid);

    function handleSetValue(value: string) {
        const memberUids = formik.values.flowData?.memberUids || [];
        if (!memberUids?.some((uid: any) => uid.uid === value)) {
            formik.setFieldValue('flowData.memberUids', [...memberUids, { id: uuidv4(), uid: value }]);
        }
    }

    function handleRemoveValue(value: string) {
        const memberUids = formik.values.flowData?.memberUids || [];
        const updatedMemberUids = memberUids?.filter((uid: any) => uid.uid !== value);
        formik.setFieldValue('flowData.memberUids', updatedMemberUids);
    }
    useEffect(() => {
        if (formik.values.flowData?.memberUids) {
            formik.values.flowData?.memberUids?.forEach((uid: any) => {
                handleSetValue(uid.uid);
            });
        }
    }, []);

    const query = useQuery({
        queryKey: ['members'],
        queryFn: async () => {
            try {
                if (!businessUid) {
                    return new Promise(() => { });
                }
                const response = await axios.post('/member/getMembers', {
                    businessUid,
                    page: 0,
                    limit: 1000,
                    status: ["ACTIVE"]
                });
                return response.data;

            } catch (error) {
                console.error(error);
                return [];
            }
        }
    });

    return (
        <div>
            <div className={`${tags.tagsContainer} ${classes.tagsContainer}`}>

                {formik.values.flowData?.memberUids?.map((uidObj: any, index: number) => {
                    if (query.data?.dataDTO) {
                        const selectedMember = query.data?.dataDTO?.find((member: any) => member.uid === uidObj.uid);
                        const displayText = selectedMember?.name;

                        return (
                            <>
                                {displayText && (
                                    <div key={index} className={`${tags.contactTag} ${classes.contactTag}`}>
                                        {sliceText(displayText, 20)}
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            onClick={() => handleRemoveValue(uidObj.uid)}
                                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                                        />
                                    </div>
                                )}
                            </>
                        );
                    }
                })}

            </div>
            <SelectMember
                name={existingSelectedMemberIds[0]}
                isValid={false}
                isInvalid={false}
                setUid={(memberUid: string) => handleSetValue(memberUid)}
                memberUids={formik.values.flowData?.memberUids}
            />
            {formik.touched.flowData
                && formik.touched.flowData.memberUids
                && formik.errors.flowData
                && formik.errors.flowData.memberUids
                && typeof formik.errors.flowData.memberUids === 'string' ? (
                <div className='invalid-feedback d-block'>
                    {formik.errors.flowData.memberUids}
                </div>
            ) : null}
        </div>
    )
}

function AppointmentFlowEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        bodyText: '',
        flowData: {
            type: null,
            flowId: '',
            buttonName: 'Submit',
            memberUids: [],
            sessions: [
                {
                    id: '1',
                    title: 'Please select the Date first'
                }
            ],
            dynamicMember: false,
            bookingWaited: false,
            confirmationMsg: "Your upcoming appointment is booked with {{variable.appointment.memberName}} on 🗓️ {{variable.appointment.date}}, at 🕥 {{variable.appointment.fromTime}} - {{variable.appointment.toTime}} ."
        },
        attempt: 1,
        endFlow: true,
        errorMessage: '',
        isSameFlow: false,
        storageVariable: {
            name: '',
            type: "OBJECT"
        }
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'appointment_Flow',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);
    const channelUid = useAppSelector(state => state.meta.channelUid);
    const [staticMember, setStaticMember] = useState(true);
    const [error, setError] = useState(false);
    const dispatch = useAppDispatch();
    const flowsQuery = useQuery({
        queryKey: ['appointment-flows'],
        queryFn: () => flowServices.getAppointmentFlowsOfChannel(channelUid || ''),
        enabled: false
    })
    useEffect(() => {
        if (channelUid && !flowsQuery.isFetchedAfterMount) {
            flowsQuery.refetch();
        }
    }, [channelUid])

    const schema = yup.object().shape({
        bodyText: yup.string().required('Body is required').test('is-double-space', 'Body is required', value => value !== '  '),
        flowData: yup.object().shape({
            flowId: yup.string().required('Flow is required'),
            buttonName: yup.string().required('Button is a required'),
            dynamicMember: yup.boolean().required(),
            bookingWaited: yup.boolean(),
            memberUids: yup.array().of(
                yup.object().shape({
                    uid: yup.string().nullable(),
                })
            ).when('$flowData.dynamicMember', {
                is: false,
                then: yup.array().required('Member UIDs are required for caly_appointment').min(1, 'At least one member is required'),
                otherwise: yup.array().of(
                    yup.object().shape({
                        uid: yup.string().nullable(),
                    })
                ).nullable(),
            }).nullable(),
        }),
        errorMessage: yup.string().notRequired(),
        attempt: yup.number().min(1, 'Attempt should be minimum 1').required('Attempt is a required'),
        storageVariable: yup.object().shape({
            name: yup.string().when('$flowData.bookingWaited', {
                is: true,
                then: yup.string().required('Variable is required'),
                otherwise: yup.string().nullable(),
            }),
        }),
    });



    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);

                useEffect(() => {
                    if(!values?.flowData?.bookingWaited) {
                        setFieldValue('storageVariable.name', "appointment");
                    }
                },[values?.flowData?.bookingWaited])

                useEffect(() => {

                    if ((flowsQuery?.data?.find(f => f.id === values?.flowData?.flowId)?.firstScreen) !== null) {
                        setFieldValue('flowData.screenName', flowsQuery?.data?.find(f => f.id === values?.flowData?.flowId)?.firstScreen || '');
                    }
                }, [values.flowData.flowId, flowsQuery?.data?.find(f => f.id === values?.flowData?.flowId)?.firstScreen])

                useEffect(() => {
                    if (flowsQuery?.data?.length === 1) {
                        setFieldValue('flowData.flowId', flowsQuery?.data[0]?.id);
                        setFieldValue('flowData.type', flowsQuery?.data[0]?.templateName);
                    }
                }, [flowsQuery?.data])

                const handleSave = () => {
                    if(values?.flowData?.bookingWaited) {
                        setFieldValue("flowData.confirmationMsg", null);
                    }
                    if (!staticMember) {
                        setFieldValue("flowData.memberUids", null);
                        setFieldValue("flowData.dynamicMember", true);
                    } else {
                        setFieldValue("flowData.dynamicMember", false);
                    }
                    !values?.flowData?.bookingWaited && setFieldValue("flowData.storageVariable", null)
                }

                useEffect(() => {
                    if (formData?.flowData?.dynamicMember) {
                        setStaticMember(false);
                        setFieldValue("flowData.memberUids", []);
                    }
                }, [setValues, formData])

                useEffect(() => {
                    if(!values?.storageVariable?.name) {
                        const variable = {
                            name: "appointment",
                            value: "appointment",
                            type: "OBJECT",
                            id: uuidv4()
                        };
                    dispatch(createVariable(variable));
                    setFieldValue('storageVariable.name', 'appointment')
                    }
                },[])

                let formContent = <Spinner />
                const flowOptions: any = []

                if (!flowsQuery.isLoading && !flowsQuery.isError && flowsQuery?.data?.length === 0) {
                    formContent = (
                        <Alert color='danger'>{FLOW_NOT_FOUND}</Alert>
                    )
                }

                else if(!flowsQuery.isLoading && !flowsQuery.isError && flowsQuery?.data?.length > 1) {
                    formContent = (
                        <Alert color='danger'>{CONTACT_SUPPORT_TEAM}</Alert>
                    )
                }

                else if (!flowsQuery.isLoading && !flowsQuery.isError) {
                    flowsQuery.data?.forEach(flow => {
                        flowOptions.push({ label: flow.name, value: flow.id });
                    });
                    formContent = (
                        <>
                            <Form.Group className='mb-3'>
                                <BodyInput
                                    name='bodyText'
                                    value={values.bodyText}
                                    isInvalid={(touched.bodyText && errors.bodyText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.bodyText && !errors.bodyText}
                                    error={errors.bodyText}
                                    label='Body'
                                    required={true}
                                    limit={1024}
                                    placeholder="Enter text message"
                                />
                                {(touched.bodyText && errors.bodyText) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.bodyText}
                                    </div>
                                ) : null}
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>Button Text <span className='required'></span></Form.Label>
                                <Form.Control
                                    name='flowData.buttonName'
                                    value={values.flowData?.buttonName}
                                    onChange={handleChange}
                                />
                                {touched.flowData
                                    && touched.flowData.buttonName
                                    && errors.flowData
                                    && errors.flowData.buttonName
                                    && typeof errors.flowData.buttonName === 'string' ? (
                                    <div className='invalid-feedback d-block'>
                                        {errors.flowData.buttonName}
                                    </div>
                                ) : null}
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <div className='d-flex align-items-center'>
                                    <FormCheck
                                        name='flowData.bookingWaited'
                                        type='switch'
                                        id='current-location-switch'
                                        label="Awaiting Confirmation"
                                        checked={values.flowData?.bookingWaited}
                                        onChange={event => {
                                            setFieldValue('flowData.bookingWaited', event.target.checked);
                                        }}
                                    />
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={<Tooltip>Once this feature is enabled, appointment slots will be confirmed later according to the settings in the appointment configuration node.</Tooltip>}
                                    >
                                        <Button size='sm' id={infoCss.round} variant='default' className='ms-1 mb-2'>
                                            <BsInfoCircleFill />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </Form.Group>


                            <div className={classes.background}>
                                <Form.Group className='mb-3'>
                                    <div className={classes.radio}>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            checked={staticMember}
                                            onChange={() => {
                                                setStaticMember(true);
                                            }}
                                        />
                                        <Label className={classes.productLabel}>Static Member</Label>

                                        <Form.Check
                                            inline
                                            name='dynamic'
                                            type='radio'
                                            checked={!staticMember}
                                            onChange={() => {
                                                setStaticMember(false);
                                            }}
                                        />
                                        <Label className={classes.productLabel} >Dynamic Member</Label>
                                    </div>
                                </Form.Group>
                                {staticMember && (
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Members <span className='required'></span></Form.Label>
                                        <SelectMembers existingSelectedMemberIds={formData?.flowData?.memberUids?.map(member => member?.id) ?? []} />
                                    </Form.Group>
                                )}
                            </div>

                            {!values?.flowData?.bookingWaited && (
                                <Form.Group className='mb-3'>
                                    <BodyInput
                                        name='flowData.confirmationMsg'
                                        value={values?.flowData?.confirmationMsg}
                                        isInvalid={(touched?.flowData?.confirmationMsg && errors?.flowData?.confirmationMsg) ? true : false}
                                        onChange={handleChange}
                                        isValid={touched?.flowData?.confirmationMsg && !errors?.flowData?.confirmationMsg}
                                        error={errors?.flowData?.confirmationMsg}
                                        label='Confirmation Message'
                                        required={false}
                                        limit={1024}
                                        placeholder="Enter confirmation message"
                                    />
                                </Form.Group>
                            )}

                            <AdvancedSettings />

                            <>
                                <Form.Group className='mb-8'>
                                    <Form.Label>
                                        Variable {values?.flowData?.bookingWaited && <span className='required'></span>}
                                    </Form.Label>
                                    <SelectVariable
                                        placeholder='Create or Select variable'
                                        name='storageVariable'
                                        value={values?.storageVariable?.name ? String(values?.storageVariable?.name) : ''}
                                        onChange={handleChange}
                                        type='OBJECT'
                                        varType="appointment_Flow"
                                        isDisabled={values?.flowData?.bookingWaited === false}
                                    />
                                    <ErrorMessage
                                        name="storageVariable.name"
                                        component="div"
                                        className="invalid-feedback d-block"
                                    />
                                </Form.Group>

                                <small>NOTE: This variable is an object that stores the following details of an appointment: from time, to time, date, and name.</small>
                                <div></div>
                                <small>
                                    <div>Sample Usage:</div>
                                    <div>{`fromTime: {{variable.${values?.storageVariable?.name}.fromTime}}`}</div>
                                    <div>{`toTime: {{variable.${values?.storageVariable?.name}.toTime}}`}</div>
                                    <div>{`name: {{variable.${values?.storageVariable?.name}.name}}`}</div>
                                    <div>{`date: {{variable.${values?.storageVariable?.name}.date}}`}</div>
                                    <div>{`memberName: {{variable.${values?.storageVariable?.name}.memberName}}`}</div>
                                </small>
                            </>
                        </>
                    )
                } else if (flowsQuery.isError) {
                    formContent = (
                        <Alert color='danger'>{FLOW_NOT_FOUND}</Alert>
                    )
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Appointment Flow' icon={<img style={{ width: 20, marginBottom: "5px" }} alt='' src={jumpToIcon} />} />
                        <Offcanvas.Body>
                            {formContent}
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' onClick={handleSave} disabled={error || props.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default AppointmentFlowEditor;