import React, { useState, useCallback, useEffect }from 'react';
import { getAllHistory } from '../../services/paymentService';
import { useSelector } from 'react-redux';
import { Col, Row, Button, Input, Label } from 'reactstrap';
import { BsCalendar, BsFunnel, BsFunnelFill} from 'react-icons/bs';
import DateRangePicker from "react-daterange-picker";
import CommonPopover from '../../common/commonPopover';
import * as originalMoment from 'moment';
import { extendMoment } from "moment-range";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck, faCheckDouble, faExternalLinkAlt, faPlus, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import Avatar from 'react-avatar';
import { Link } from "react-router-dom";
import { Formik, Form } from 'formik';
import RightSidebar from "../../common/rightSidebar";
import SearchBox from '../../botFlows/components/ui/searchBox';
import BizTable from '../../common/BizTable';
import { dateSplit } from '../../common/commonfns';
import { ErrorImage } from '../../common/ErrorMessage';
import SearchInput from "../../common/searchComponent";


const moment = extendMoment(originalMoment);

enum DateFilterType {
    Past7Days = 'Past7Days',
    Past30Days = 'Past30Days',
    Past90Days = 'Past90Days',
    ThisWeek = 'ThisWeek',
    PreviousWeek = 'PreviousWeek',
    ThisMonth = 'ThisMonth',
    PreviousMonth = 'PreviousMonth',
    CustomRange = 'CustomRange',
  };
  

export const PaymentHistory = (activeTab: any, isInvalidAccess: boolean) => {
  const [allData, setAllData] = useState<any>([]);
  const today = moment().clone().startOf('day');
  const [showPop, setpop] = useState<boolean>(false);
  const [dateRangeValue, setDateRangeValue] = useState(
    moment.range(today.clone().subtract(6, "days").startOf('day'), today.endOf('day'))
  ); 
  const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days); 
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [filterPayment, setFilterPay] = useState<any>([]);
  const [afterFilter, setAfterFilter] = useState<any>([]);
  const [showContent, setContent] = useState<boolean>(false);
  const [selectedRow, setRow] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [counts,setCount] = useState<number>(0);
  const [totalValue, setTotal] = useState<any>([]);
  // console.log('fkkkdk',filterPayment)
  const channel: any = useSelector((state: any) => state.cartreducer.channelUid);

  const paymentStatus = [
    {value: "new_payment", label: "new"},
    {value: "payment_initiated", label: "payment initiated"},
    {value: "paid", label: "paid"},
    {value: "cancelled", label: "cancelled"},
    {value: "failed", label: "failed"},
    ];


    const fetchData = useCallback(async (pageIndex: number, pageSize: number, searchText: string, currentPage: number): Promise<{ data: any; totalPages: number }> => {
      const payload = {
          startDate: moment(dateRangeValue.start).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          endDate: moment(dateRangeValue.end).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          paymentStatusS: afterFilter? afterFilter : null,
          mobileNumber: search ? search : null,
          page:isChangeFilter || search ? 0 : currentPage,
          limit:isChangeFilter ? 20 : pageSize,
      }
      if(channel?.channelAllData?.uid){
      const response = await getAllHistory(channel?.channelAllData?.uid, payload);
      if (response) {
       setIsChangeFilter(false);
        if(filterPayment?.length > 0){
        closePopover();
        }
        const {orders,count} = response;
        const data = orders.orders;
      // console.log('fetchdata after',data,search)

        setTotal(orders);
        setAllData(data);
        setCount(count)
        setIsLoading(false);
        return {data:data,totalPages:count}
      }else {
        return {data:[],totalPages:0}
      }
      }
      else{
        return {data:[],totalPages:0}
      }
    }, [dateRangeValue, channel, search, afterFilter]);
   

    const handleSubmit = () => {
      setIsChangeFilter(true);
      setAfterFilter(filterPayment);
      closePopover();
      // console.log('submits filter', filterPayment)
      // console.log('submit after', afterFilter)
      // fetchData(0, 20, '', 0);
    }

  

    const  columns = [
      {
        Header: (
          <input
            type="checkbox"
            hidden={true}
            // checked={this.state.allChecked}
            // onChange={this.toggleAllCheckboxes}
          />
        ),
        accessor: "checkbox",
        // Cell: this.checkboxCellRenderer,
        sticky: "left",
      },
      {
        Header: "Contact Name",
        accessor: "contact",
        Cell:(cell:any,row:any)=> contactFunc(cell,row),
        sticky: "left",
      },
      {
        Header: "Mobile Number",
        accessor: "contact.mobileNumber",
        Cell: (cell:any, row:any) => {
         const {mobileNumber,countryCode  } = cell?.row?.original?.contact
       const numberMask = localStorage.getItem('numberMask') === 'true';

          return (
            <p className={`${numberMask ? "numberMasking_Blur" :''} mt-0`}>{mobileNumber && countryCode? '+' + countryCode + ' ' + mobileNumber.slice(countryCode?.length) : "-"}</p>
            )
          }
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: (cell:any) => <p title={moment(cell.value).format("MMM YYYY h:mm A")}>{dateSplit(cell.value)}</p>,
      },
      {
        Header: "Amount",
        accessor: "totalAmount",
        Cell: (cell:any, row:any) => {
          return (
              <p className='nofont'>₹{(cell?.value/100).toFixed(2)}</p>
              )
          }
      },
      {
        Header: "Message Status",
        accessor: "messageStatus",
        Cell: (cell:any, row:any) => statusColor(cell?.value, row),
      },
      {
        Header: "Payment Status",
        accessor: "paymentStatus",
        Cell: (cell:any, row:any) => paymentColor(cell.value, row),
      },
    ] as any;

      
    // const getAllData = async () => {
    //   setfilter(filterPayment)
    //     const payload = {
    //         startDate: moment(dateRangeValue.start).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    //         endDate: moment(dateRangeValue.end).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    //         paymentStatusS: filterPayment,
    //         mobileNumber:search? search : null,
    //     }
    //     setIsLoading(true);
    //     try {
    //         const response = await getAllHistory(channel?.channelAllData?.uid, payload);
    //         const data = response.data?.orders;
    //         setAllData(data);
    //         closePopover();
    //         setIsLoading(false);
    //       } catch (error) {
    //         closePopover();
    //         console.error('Error:', error); 
    //         setIsLoading(false);
    //       }
    //   };
    
      // useEffect(() => { 
      //     getAllData();
      // }, [dateRangeValue, channel]);

    //   useEffect(() => {
    //     let timer = setTimeout(() => {
    //       // getAllData();
    //     }, 600);
    
    //     return () => clearTimeout(timer);
    // }, [search]);

      const getStartDate = (subtractDays: number, unit: moment.unitOfTime.DurationConstructor = 'days') => {
        return moment().subtract(subtractDays, unit).startOf(unit);
      };
      const [isChangeFilter,setIsChangeFilter] = useState(false);
      const changeDateFilterHandler = (event: any) => {
        const filter = event.target.value as DateFilterType;
        setIsChangeFilter(true);
        switch (filter) {
            case DateFilterType.Past7Days:
                setDateRangeValue(moment.range(today.clone().subtract(6, "days").startOf('day'), today.endOf('day')));
                setShowDatePicker(false);
                break;
      
            case DateFilterType.Past30Days:
                setDateRangeValue(moment.range(getStartDate(30), today.endOf('day')));
                setShowDatePicker(false);
                break;
      
            case DateFilterType.Past90Days:
                setDateRangeValue(moment.range(getStartDate(90), today.endOf('day')));
                setShowDatePicker(false);
                break;
      
            case DateFilterType.ThisWeek:
                setDateRangeValue(moment.range(today.clone().startOf('week'), today.endOf('day')));
                setShowDatePicker(false);
                break;
      
            case DateFilterType.PreviousWeek:
                setDateRangeValue(moment.range(getStartDate(1, 'week'), getStartDate(1, 'week').endOf('week')));
                setShowDatePicker(false);
                break;
      
            case DateFilterType.ThisMonth:
                setDateRangeValue(moment.range(today.clone().startOf('month'), today.endOf('day')));
                setShowDatePicker(false);
                break;
      
            case DateFilterType.PreviousMonth:
                setDateRangeValue(moment.range(getStartDate(1, 'month'), getStartDate(1, 'month').endOf('month')));
                setShowDatePicker(false);
                break;
      
            case DateFilterType.CustomRange:
                setShowDatePicker(true);
                break;
      
            default:
                break;
        }
        
        setDateFilter(filter);
      }

      const closeDate = () => {
        setShowDatePicker(false);
      }
      const openDate = () => {
        setShowDatePicker(true);
      }

    //   const dateSplit = (date: Date): string => {
    //     const today = moment();
    //     const targetDate = moment(date);
        
    //     if (targetDate.isSame(today, "day")) {
    //         return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>Today</span>";
    //     } else if (targetDate.isSame(today.clone().subtract(1, "days"), "day")) {
    //         return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>Yesterday</span>";
    //     } else if (targetDate.isSameOrAfter(today.clone().subtract(7, "days"), "day")) {
    //         return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>" + targetDate.format("dddd") + "</span>";
    //     } else {
    //         return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>" + targetDate.format("DD MMM") + "</span>";
    //     }
    // };
    
    const contactFunc = (cell: any,row: any) => {
      const {name,uid,contactType} = cell?.value;
        if(name){
         return(
              <div className='d-flex'>
                <span><Avatar fgColor="#fff" name={name} round size='25' textSizeRatio={3} /></span>
              <div className='ml-1'>
                <div className='d-flex'>
                <p title={name} className='mt-1 mb-0'>{name?.length > 12? name?.slice(0,12) : name }</p>
                {contactType === 'LEAD' ?
                <span className={`cursor-pointer ml-2 mt-1 ${isInvalidAccess ? "notAllowed" : ""} `}><Link to={`${process.env.PUBLIC_URL}/conversations?contactUid=${uid}`}
                  target="_blank" className={`icon-hover ${isInvalidAccess ? "disabledState" : ""} `}>
                  <FontAwesomeIcon icon={faExternalLinkAlt} /></Link></span> :
                <span className={`cursor-pointer ml-2 mt-1 ${isInvalidAccess ? "notAllowed" : ""} `} ><Link to={`${process.env.PUBLIC_URL}/supportInbox?contactUid=${uid}`}
                  target="_blank" className={`icon-hover ${isInvalidAccess ? "disabledState" : ""} `} > 
                  <FontAwesomeIcon icon={faExternalLinkAlt} /></Link></span>}
                </div>
                </div>
              </div>
         )
        }else{
          return '-';
        }
        }        

        const paymentColor =(cell:any, row:any)=>{
            return (
              <b className={`paywidth ${cell === 'new_payment' || cell ==="order_request"? 'newcolor' : cell === 'pending'? 'pending' :
              cell === 'payment_initiated' ? 'partial' : cell === 'paid'? 'paid' : 'cancel'} rounded p-1 m-0`}>
                {cell === 'new_payment'?  'New' : cell === "payment_initiated" ? "Payment Initiated" : cell ==="order_request"? "Order Request" : cell?.charAt(0).toUpperCase() + cell?.slice(1)}</b>
           )
          }
          const closePopover = () => {
            setpop(false);
          }
          const openPopover = () => {
            // console.log('after filter', afterFilter)
            setFilterPay(afterFilter);
            // console.log('open filter', filterPayment)
            setpop(true);
          }
        

          const CheckboxGroup = ({ options, selectedValues, onChange }: any) => (
            <div >
              {options.map((option :any)=> (
                <div key={option.value} className='m-0 check'>
                  <input
                    type="checkbox"
                    className='mr-1 mb-1'
                    value={option.value}
                    checked={selectedValues.includes(option.value)}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (selectedValues.includes(value)) {
                        onChange(selectedValues.filter((val:any) => val !== value));
                      } else {
                        onChange([...selectedValues, value]);
                      }
                    }}
                  />
                  <label>{option.label.charAt(0).toUpperCase() + option.label.slice(1)}</label>
                </div>
              ))}
            </div>
          );

        const filter = () => {
            return(
              <Formik 
              initialValues={{ payment: '',
              }} 
               onSubmit={()=>{
                // getAllData();
                handleSubmit()
               }}> 
               {({ values}) => (
              <Form className='p-1 filterform'>
                <div className='d-flex m-0'>
                <p>Filter</p> {filterPayment?.length? <Button type='button' className='resetbtn' onClick={()=>{
                  setFilterPay([]);
                }}>Reset</Button> : ''}
                </div>
                <hr />
                <label>Payment Status</label>
                <CheckboxGroup
                options={paymentStatus}
                selectedValues={filterPayment}
                onChange={setFilterPay}
              />
              <div className='d-flex'>
                <Button type='button' className='cancelButton p-0' onClick={closePopover}>cancel</Button>
                <Button type='submit' className='sendButton ml-1 p-1'>Apply</Button>
              </div>
              </Form>
              )}
              </Formik>
            )
          }
        
      const handleRowClick = (row: any) => {
            setRow(row);
            setContent(true);
       };

      const handleCancel = () => {
            setContent(false);
      }

      const statusColor =(cell:any, row:any)=>{
        return (
          <>
          {cell?
          <div>{cell === 1 && (
            <>
            <span className="pl-1 statusDesign">&#x23F0;</span>
            <span className='ml-1'>Not Sent</span>
            </>
          )}
          {cell === 2 && (
            <>
            <span className="pl-1 statusDesign">&#x2713; </span>
            <span className='ml-1'>Sent</span>
            </>
          )}
          {cell === 3 && (
            <>
            <span className="pl-1 doubleTick statusDesign">
              &#x2713;&#x2713; 
            </span>
            <span className='ml-2'>Delivered</span>
            </>
          )}
          {cell === 4 && (
            <>
            <span className="pl-1 deliveredTick doubleTick statusDesign">
              &#x2713;&#x2713;
            </span> <span className='ml-1'>Read</span>
            </>
          )}</div>
          : <p>-</p>}
          </>
           )
      }

      const onSearchChange = (searchTerm: any) => {
        const searchTerms = searchTerm.replace(/\D/g, ''); 
        const searchTermCheck = searchTerms.length > 0 ? searchTerm : null;
        setSearch(searchTermCheck);
      };
      const numberMask = localStorage.getItem('numberMask') === 'true';
    return (
        <div>
          <div className='Cards d-flex'>
            <div className='divcards text-secondary text-center'>
              <Label><FontAwesomeIcon icon={faPlus} className='text-secondary' /> Created</Label><br/>
              <b className='nofont'>₹{(totalValue?.createdAmount? totalValue?.createdAmount/100 : 0).toFixed(2)}</b>
            </div>
            <div className='divcards text-success text-center'>
              <Label><FontAwesomeIcon icon={faCheckDouble} className='text-success' /> Paid</Label><br/>
              <b className='nofont'>₹{(totalValue?.paidAmount? totalValue?.paidAmount/100 : 0).toFixed(2)}</b>
            </div>
            <div className='divcards text-warning text-center'>
              <Label><FontAwesomeIcon icon={faCheck} className='text-warning' /> Unpaid</Label><br/>
              <b className='nofont'>₹{(totalValue?.unPaidAmount? totalValue?.unPaidAmount/100 : 0).toFixed(2)}</b>
            </div>
            <div className='divcards text-danger text-center'>
              <Label><FontAwesomeIcon icon={faTimesCircle} className='text-danger' /> Cancelled</Label><br/>
              <b className='nofont'>₹{(totalValue?.cancelledAmount? totalValue?.cancelledAmount/100 : 0).toFixed(2)}</b>
            </div>
          {/* </div> */}

        <div className='filters mb-1 p-1'>
          <div className='d-flex mb-1'>
        <Input
                            className='datepick dateFilter mr-1 pt-1'
                            type="select"
                            size={1}
                            value={dateFilter}
                            style={{ fontFamily: "\"Public Sans\", sans-serif" }}
                            onChange={changeDateFilterHandler}
                        >
                            <option value={DateFilterType.Past7Days}>Past 7 days</option>
                            <option value={DateFilterType.Past30Days}>Past 30 days</option>
                            <option value={DateFilterType.Past90Days}>Past 90 days</option>
                            <option value={DateFilterType.ThisWeek}>This week</option>
                            <option value={DateFilterType.PreviousWeek}>Previous week</option>
                            <option value={DateFilterType.ThisMonth}>This month</option>
                            <option value={DateFilterType.PreviousMonth}>Previous month</option>
                            <option value={DateFilterType.CustomRange}>Custom range</option>
                        </Input>
                        <div className='datepick mr-1' id="datepickertarget" onClick={() => openDate()}>
                            <BsCalendar />
                            <span className='ml-2'>
                                {dateRangeValue.start.format("DD/MM/YYYY")}
                                {" - "}
                                {dateRangeValue.end.format("DD/MM/YYYY")}
                            </span>
                        </div>
                        <CommonPopover isOpen={showDatePicker} target="datepickertarget" toggle={closeDate} placement='bottom' component='order1'>
                        <div><DateRangePicker
                                value={dateRangeValue}
                                onSelect={(value: any) => {
                                    setDateRangeValue(moment.range(moment(value.start).startOf('day'), moment(value.end).endOf('day')));
                                    setDateFilter(DateFilterType.CustomRange);
                                    setShowDatePicker(false);
                                    setIsChangeFilter(true);
                                }}
                                singleDateRange={true}
                                className="dateRangePicker"
                                maximumDate={today.endOf('day')}
                            /></div>
                        </CommonPopover>
        {/* <Button
        id='filterpop'
        onClick={() => openPopover()}
        className="datepick filterBox mr-1 pt-1"
        variant=""
        >
        {filterPayment.length > 1 ? (
        <BsFunnelFill size={20} />
          ) : (
        <BsFunnel size={20} />
        )}
        </Button>       
      <CommonPopover isOpen={showPop}  target="filterpop" toggle={closePopover} placement='bottom'
     component='order'>
      {filter()}
     </CommonPopover> */}
     </div>
      <div className='d-flex'>
        {/* <SearchBox
        placeholder="Search By Mobile Number" 
        value={search}
        onChange={onSearchChange}
         /> */}
    <SearchInput component="paymentHistory" onSearchChange={onSearchChange} 
          placeHolder="Search By Mobile Number"
          />
          <Button
        id='filterpop'
        onClick={() => openPopover()}
        className="datepick filterBox"
        variant=""
        >
        {filterPayment?.length > 1 ? (
        <BsFunnelFill size={20} />
          ) : (
        <BsFunnel size={20} />
        )}
        </Button>       
      <CommonPopover isOpen={showPop}  target="filterpop" toggle={closePopover} placement='bottom'
     component='order'>
      {filter()}
     </CommonPopover>
        </div>
        </div>

        </div>
        {isLoading ? (
          <div className="centerItemsWithWidth">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
        <div>
         {/* <BootstrapTable data={ allData.orders } pagination={allData?.orders?.length > 10 ? true : false} version="4" striped hover
          containerStyle={{ textAlign: 'left', cursor: 'pointer' }}
          options={{
            headerAlign: "center",
            noDataText: "No records found",
            sortIndicator: true,
            hidePageListOnlyOnePage: true,
            clearSearch: false,
            alwaysShowAllBtns: true,
            sizePerPageList: [10, 25, 50, 100, { text: "All", value: allData?.orders?.length }],
            withFirstAndLast: true,
            sizePerPage: 10,
            paginationPosition: "bottom",
            onRowClick: handleRowClick,
          } as any}>
             <TableHeaderColumn dataField='id' isKey hidden>#</TableHeaderColumn>
            <TableHeaderColumn dataField='name' columnClassName="text-wrap" dataAlign='left' width="170" 
            dataFormat={contactFunc}><b>Contact Name</b></TableHeaderColumn>
            <TableHeaderColumn dataField='name' columnClassName="text-wrap" dataAlign='left' width="120" 
             dataFormat={(cell, row, rowIndex) => {
                return (
                    <p className='mt-0'>{row.contact?.mobileNumber && row.contact?.countryCode? '+' + row.contact?.countryCode + ' ' + row.contact?.mobileNumber.slice(row.contact?.countryCode?.length) : "-"}</p>
                    )
            }}><b>Mobile Number</b></TableHeaderColumn>
            <TableHeaderColumn dataField='createdAt' dataAlign="center" width="100" dataFormat={dateSplit}>
              <b>Created At</b>
            </TableHeaderColumn>
            <TableHeaderColumn dataField='totalAmount' dataAlign="center" width="150" 
            dataFormat={(cell, row, rowIndex) => {
                return (
                    <p className='mt-0 nofont'>₹{(cell/100).toFixed(2)}</p>
                    )
            }}>
              <b>Amount</b>
            </TableHeaderColumn>
            <TableHeaderColumn dataField='messageStatus' dataAlign="center" width="140" dataFormat={statusColor}>
              <b>Message Status</b>
            </TableHeaderColumn>
            <TableHeaderColumn dataField='paymentStatus' dataAlign="left" width="140" dataFormat={paymentColor}>
              <b>Payment Status</b>
            </TableHeaderColumn>
          </BootstrapTable> */}
            <BizTable
              columns={columns}
              fetchData={fetchData}
              counts={counts}
              refetchData={allData.orders}
              handleRowClick={handleRowClick}
              tableName='PAYMENT_HISTORY'
            /> 
            {counts && counts > 0 ? '' : (!isLoading &&<ErrorImage/>)}
          </div>
        )}

          
      {showContent && (
        
                <RightSidebar handleCancel={handleCancel} title='Payment Details' width="425px">
                  <div className="pl-3 pr-3 mt-3 ml-1">
                  <Row>
                     <Col md='5'><p>Contact Details</p></Col>
                     <Col md='7' className='d-flex'>{selectedRow.contact?.name? <Avatar fgColor="#fff" name={selectedRow.contact?.name} round size='27' textSizeRatio={3} /> : '' }
                      <div className='ml-1 contact'>
                      <p className='m-0 text-dark'>{selectedRow.contact?.name? selectedRow.contact?.name : '-'}</p>                          
                      <p className={`${numberMask ? "numberMasking_Blur" :''} mt-0`}>{selectedRow.contact?.mobileNumber? '+' + selectedRow.contact?.countryCode + ' ' + selectedRow.contact?.mobileNumber.slice(selectedRow.contact?.countryCode?.length) : ''}</p>
                      </div></Col>
                      </Row>
                      <Row>
                            <Col md='5'><p>Amount</p></Col>
                            <Col md='7'><p className='nofont'>₹{(selectedRow.totalAmount/100).toFixed(2)}</p></Col>
                      </Row>
                      <hr/>
                      <Row className='mt-2'>
                            <Col md='5'><p>Payment Provider</p></Col>
                            <Col md='7'><p>{selectedRow?.paymentConfig?.method? selectedRow?.paymentConfig?.method === 'upi'? "UPI VPA ID" : selectedRow?.paymentConfig?.method ===  "payu"?  "PayU" : selectedRow?.paymentConfig?.method === "razorpay"? "Razorpay": '-' : "-"}</p></Col>
                      </Row>
                      <Row>
                            <Col md='5'><p>Payment Status</p></Col>
                            <Col md='7'><p>{selectedRow?.paymentStatus === 'new_payment'?  'New' : selectedRow?.paymentStatus === "payment_initiated" ? "Payment Initiated" : selectedRow?.paymentStatus ==="order_request"? "Order Request" : selectedRow?.paymentStatus?.charAt(0).toUpperCase() + selectedRow?.paymentStatus?.slice(1)}</p></Col>
                      </Row>
                      <hr/>
                      <Row className='mt-2'>
                            <Col md='5'><p>Created At</p></Col>
                            <Col md='7'><p>{moment(selectedRow?.createdAt)?.format("DD MMM, YY hh:mm A")}</p></Col>
                      </Row>
                      <Row>
                      <Col md='5'><p className='mt-1'>Channel Name</p></Col>
                            <Col md='7' className='d-flex'>{channel?.label? <Avatar fgColor="#fff" name={channel?.label} round size='27' textSizeRatio={3} /> : '' }
                              <div className='ml-1'>
                              <p className='m-0 text-dark mt-1'>{channel?.label? channel?.label : '-' }</p>
                              </div></Col>
                      </Row>
                  </div>
                </RightSidebar>
          )}
        </div>
    )
}