import React, { Fragment, useEffect, useState, useRef } from "react";
import { faArrowLeft, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Label, Button } from "reactstrap";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import "./profile.scss";
import {
  showBusinessProfile,
  profilePicturePath,
  memberUpdate,
  setBusinessProfileData,
} from "../../services/profileService";
import { useDispatch, useSelector } from "react-redux";
import { employList, industryList } from "../../common/selectOptions";
import FlagInput from "../../common/flagInput";
import { toast, confirmAlert } from "../../common/alert";
import { auth } from "../../Firebase/firebase";
import axios from "../../utils/axios";
import { useNavigate } from 'react-router-dom';
import SwitchButton from "../Contacts/SwitchButton";
import TooltipReference from "../../common/tooltip";
import { currencies } from "../../Authentication/currencies";
import { timeZoneWithGMT } from "../../services/auth/authenticationService";
import { sliceText } from "../../common/sliceText";
import { timezones } from "../../Authentication/timezones";
interface BusinessProfileState {
  name: string;
  webSite: string;
  email: string;
  ccEmail: any;
  mobileNumber: string;
  metaData: {
    mobileNumber2: string;
    maskingEnabled: boolean;

  },
  timeZone: string;
  industry: string;
  employees: string;
  currency: string;
  profilePictureLink: string;
  country: {
    name: string;
  };
  country_code: string;
}

const Profile = () => {
  const dispatch = useDispatch();
  const [chooseProfile, setProfile] = useState(false);
  const [filePathProfile, getFilePath_profile] = useState();
  const [filePathBusiness, getFilePath_business] = useState();
  const [shouldResetFilePath, setShouldResetFilePath] = useState(false);
  const [profileMedia, setProfileMedia] = useState([]);
  const [businessProFile, getBusinessProfile] = useState<BusinessProfileState>({
    name: "",
    webSite: "",
    email: "",
    ccEmail: '',
    mobileNumber: "",
    metaData: {
      mobileNumber2: '',
      maskingEnabled: false,
    },
    timeZone: "",
    industry: "",
    employees: "",
    currency: "",
    profilePictureLink: "",
    country: { name: "" },
    country_code: "",
  });

  const [isNumberMask, setIsnumberMask] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const [businessPic, setBusinessPicture] = useState("");
  const businessData = useSelector((state: any) => state.cartreducer.business);
  // const timeZoneList = useSelector((state: any) => state.cartreducer.timeZone);
  const flagData = useSelector((state: any) => state.cartreducer.flagData);
  const [profileUrl, setProfilePath] = useState(businessData?.media?.url);
  // const [timezones, setTimezones] = useState<{ value: string; label: string; }[]>([]);

  const uniqueCurrencies = flagData && flagData.length > 0
    ? flagData
      .map((flag: any) => flag.currency)
      .filter((currency: any, index: number, self: any[]) => currency && self.indexOf(currency) === index)
      .sort((a: string, b: string) => a.localeCompare(b))
    : [];


  const [phoneCode, setFlagCode] = useState('+91');
  const [countryData, setCountryBusiness] = useState([]);
  const [showProfileSub, showProSubmit] = useState(false)
  const [showBusinessSub, showBisSubmit] = useState(false)
  // const [maskingEnabled,setmaskingEnabled] = useState(false);

  const profileApi = async () => {
    const business = businessData?.business;
    if (business) {
      const businessProfiles = await showBusinessProfile(business);
      if (businessProFile) {
        getBusinessProfile(businessProfiles);
        setFlagCode(businessProfiles.country_code);
        setCountryBusiness(businessProfiles.country);
        const { profilePictureLink } = businessProfiles;
        setBusinessPicture(profilePictureLink);
      }
    }

    // if (businessData) {
    //   const url = businessData?.media?.url
    //   console.log('businessData',businessData)
    //   setProfilePath(url);
    // }
  };

  // const Time = async () => {
  //   const res = await timeZoneWithGMT();
  //   if (res) {
  //     const formattedTimezones = Object.entries(res).map(([key, value]) => ({
  //       label: `${key}.${value}`,
  //       value: `${key}.${value}`,
  //     }));
  //     setTimezones(formattedTimezones)
  //   }
  // };
  // useEffect(() => {
  //   Time();
  // }, []);

  const validationSchema = Yup.object({
    profilename: Yup.string().when([], {
      is: () => chooseProfile === false,
      then: Yup.string().required("Name is required"),
      otherwise: Yup.string(),
    }),
    businessProfileName: Yup.string().when([], {
      is: () => chooseProfile === true,
      then: Yup.string().required("Name  is required"),
      otherwise: Yup.string(),
    }),
    businessMobileNumber: Yup.string().when([], {
      is: () => chooseProfile === true,
      then: Yup.string()
        .required("Mobile number is required")
        .min(7, "Mobile number must be at least 7 characters")
        .max(13, "Mobile number cannot exceed 13 characters"),
      otherwise: Yup.string(),
    }),
    mobileNumber2: Yup.string().when('chooseProfile', {
      is: () => chooseProfile === true,
      then: Yup.string().nullable()
        .test(
          'mobileNumber2', // Name of the test
          'Invalid mobile number', // Error message
          (value: any) => {
            if (!value) return true;
            // Split the string into an array of numbers
            const numbers = value?.split(',');
            // Validate each number individually
            return numbers?.every((number: any) => {
              const trimmedNumber = number.trim();
              // Check if the number meets length requirements and contains only digits
              return /^\d+$/.test(trimmedNumber) && trimmedNumber.length >= 7 && trimmedNumber.length <= 17;
            });
          }
        ),
      otherwise: Yup.string().nullable(),
    }),
    website: Yup.string().when([], {
      is: () => chooseProfile === true,
      then: Yup.string().required("Website is required")
        .matches(
          /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[^\s@]+\.[^\s@]+|(?:\w+@\w+\.\w+)/,
          'Please enter a valid website, domain, or email'
        ),
      otherwise: Yup.string(),
    }),
    ccEmail: Yup.string().when('chooseProfile', {
      is: () => chooseProfile === true,
      then: Yup.string().nullable()
        .test('email-list', 'Invalid email', (value) => {
          if (!value || value.trim() === '') return true; // skip validation if value is empty
          const emails = value.split(',');
          for (const email of emails) {
            const [localPart, domainPart] = email.trim().split('@');
            if (!/^[a-zA-Z0-9._%+-]+$/.test(localPart)) return false;
            if (!/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(domainPart)) return false;
          }
          return true;
        }),
      otherwise: Yup.string().nullable(),
    }),

  });


  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );

  const fetchData = async (uid: string) => {
    try {
      const response = await axios.get('profile', {
        headers: {
          uid: uid
        }
      });
      const business = response?.data;
      dispatch({ type: 'BUSINESS_ID', payload: business });
    } catch (error: any) {
      toast('error', error.response.data.message);
    }
  }

  const handleSubmit = async (values: any) => {

    if (channelData) {
      if (chooseProfile === false) {
        if (shouldResetFilePath === true) {
          const responseMedia = await profilePicturePath(
            profileMedia,
            filePathProfile
          );
          if (responseMedia) {
            const updatedProfileData = {
              ...businessData,
              media: responseMedia,
              mediaId: responseMedia.id,
              name: values.profilename,
            };
            const member_data = await memberUpdate(updatedProfileData);
            if (member_data) {
              toast("success", "Profile updated successfully");
              setShouldResetFilePath(false);
              const unsubscribe = auth.onAuthStateChanged((user: any) => {
                if (user) {
                  const uid = user.uid;
                  fetchData(uid);
                }
              })
            }
          }
        } else {
          const updatedProfileData = {
            ...businessData,
            name: values.profilename,
          };
          const member_data = await memberUpdate(updatedProfileData);
          if (member_data) {
            toast("success", "Profile updated successfully");
            const unsubscribe = auth.onAuthStateChanged((user: any) => {
              if (user) {
                const uid = user.uid;
                fetchData(uid);
              }
            })
          }
        }
      }
      if (chooseProfile === true) {
        if (shouldResetFilePath === true) {
          const responseMedia = await profilePicturePath(
            profileMedia,
            filePathBusiness
          );

          const updatedBusinessData = {
            ...businessProFile,
            uid: businessData.business.uid,
            name: values.businessProfileName,
            webSite: values.website,
            mobileNumber: values.businessMobileNumber,
            ccEmail: values.ccEmail,
            country_code: values.countryCode,
            timeZone: values.timeZone,
            industry: values.industry,
            currency: values.currency,
            country: countryData,
            employees: values.noOfEmploy,
            media: responseMedia,
            mediaId: responseMedia.id,
            metaData: {
              mobileNumber2: values?.mobileNumber2,
              maskingEnabled: businessProFile?.metaData?.maskingEnabled
            }
          };
          const response = await setBusinessProfileData(updatedBusinessData);
          if (response) {
            toast("success", "Business profile updated successfully");
            setShouldResetFilePath(false);
          }
          getBusinessProfile(response);
        } else {
          const updatedBusinessData = {
            ...businessProFile,
            uid: businessData.business.uid,
            name: values.businessProfileName,
            webSite: values.website,
            mobileNumber: values.businessMobileNumber,
            ccEmail: values.ccEmail,
            country_code: values.countryCode,
            timeZone: values.timeZone,
            industry: values.industry,
            currency: values.currency,
            employees: values.noOfEmploy,
            country: countryData,
            metaData: {
              mobileNumber2: values?.mobileNumber2,
              maskingEnabled: businessProFile?.metaData?.maskingEnabled
            }
          };
          const response = await setBusinessProfileData(updatedBusinessData);
          if (response) {
            toast("success", "Business profile updated successfully");
          }
          getBusinessProfile(response);
        }
      }
    }
    // setSubmitting(false)
  };

  const businessProfile = () => {
    setProfile(true);
  };

  const profileBtn = () => {
    setProfile(false);
  };

  useEffect(() => {
    if (businessData) {
      profileApi();
    }
  }, [businessData]);

  const handleFileChange2 = async (files: FileList | null) => {
    if (files && files.length > 0) {
      showBisSubmit(true)
      const file = files[0];
      getFilePath_business(file as any);
      setShouldResetFilePath(true);

      const businessUid = businessData?.business?.uid;

      const roleData = chooseProfile === true ? "business" : "member";
      setProfileMedia([businessUid, roleData] as any);

      const updatedFiles: { imgUrl: string; imgName: string; image: File }[] =
        [];

      for (let i = 0; i < files.length; i++) {
        const newFile = files[i];
        const imgUrl = URL.createObjectURL(newFile);
        updatedFiles.push({
          imgUrl: imgUrl,
          imgName: newFile.name,
          image: newFile,
        });
        setBusinessPicture(imgUrl);
      }
    }
  };

  const handleFileChange1 = async (files: FileList | null) => {
    if (files && files.length > 0) {
      showProSubmit(true)
      const file = files[0];
      getFilePath_profile(file as any);
      setShouldResetFilePath(true);
      const businessUid = businessData?.business?.uid;
      const memberUid = businessData?.uid;
      const roleData = chooseProfile === true ? "business" : "member";
      setProfileMedia([businessUid, roleData, memberUid] as any);

      const updatedFiles: { imgUrl: string; imgName: string; image: File }[] =
        [];
      for (let i = 0; i < files.length; i++) {
        const newFile = files[i];
        const imgUrl = URL.createObjectURL(newFile);
        updatedFiles.push({
          imgUrl: imgUrl,
          imgName: newFile.name,
          image: newFile,
        });

        setProfilePath(imgUrl);
      }
    }
  };

  const handleOpenFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const flagInputRef = useRef<HTMLInputElement>(null);

  const codeChanges = (e: any, values: any) => {
    showBisSubmit(true);
    setFlagCode(e);
    getBusinessProfile(prev => ({
      ...prev,
      name: values?.businessProfileName,
      webSite: values?.website,
      email: values?.email,
      mobileNumber: values?.businessMobileNumber,
      ccEmail: values?.ccEmail,
      timeZone: values?.timeZone,
      industry: values?.industry,
      employees: values?.noOfEmploy,
      currency: values?.currency,
      profilePictureLink: values?.businessPicture,
      country: { name: values?.businessCountry },
      country_code: values?.countryCode,
      metaData: {
        mobileNumber2: values?.mobileNumber2,
        maskingEnabled: values?.maskingEnabled,
      }
    }));
  };


  const countryBusiness = (e: any) => {
    showBisSubmit(true)
    setCountryBusiness(e);
  };

  const handleKeyDown = (event: any) => {
    showProSubmit(true)
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault();
    }
  };

  const handleKeyDownBusiness = (event: any) => {
    showBisSubmit(true)
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault();
    }
  };

  const profileData = useSelector((state: any) => state.cartreducer.business);

  let navigate = useNavigate();
  const GoHome = () => {
    let path = profileData && profileData?.business?.freeTrial ? `${process.env.PUBLIC_URL}/welcome/` : 
    profileData?.role?.name?.toLowerCase() === "owner" ||  profileData?.role?.name?.toLowerCase() === "admin" ? 
      `${process.env.PUBLIC_URL}/dashboard/` :   `${process.env.PUBLIC_URL}/conversations/`
    navigate(path);
  }




  const formatMobileNumberWithSpace = (data: any) => {
    const { country_code, mobileNumber } = data;
    // Check if the country code is present and has a length greater than 0
    if (country_code && country_code.length > 0) {
      // const strippedMobileNumber = mobileNumber.substring(country_code.length-1).trim();
      // Use `${country_code} ${mobileNumber}` to format the number
      const formattedNumber = `${country_code?.includes('+') ? country_code : '+' + country_code} ${mobileNumber}`;
      return formattedNumber;
    }

    // If no country code, return the original number
    return mobileNumber;

  }
  const updateNumberMask = async (updateData: any) => {
    const response = await setBusinessProfileData(updateData)
    if (response) {
      toast("success", "Profile updated successfully");
    }
  }

  const numberMaskForMember = () => {
    const currentMaskingState = businessProFile?.metaData?.maskingEnabled;

    confirmAlert(
      "warning",
      `Are you sure you want to ${currentMaskingState ? 'disable' : 'enable'} mobile number masking?`,
      async () => {
        // Update state and perform asynchronous operation
        getBusinessProfile(prevState => {
          const newState = {
            ...prevState,
            metaData: {
              ...prevState.metaData,
              maskingEnabled: !currentMaskingState
            }
          };

          // Perform asynchronous operation after state is updated
          updateNumberMask(newState);
          return newState;
        });
      },
      () => {
      }
    );
  };

  return (
    <Container>
      <div className="mt-3 page-heading">
        <h4>
          <FontAwesomeIcon icon={faArrowLeft} onClick={GoHome} className="ml-3" /> Profile
        </h4>
      </div>
      <div className="d-flex justify-content-end ">
        <span
          className={!chooseProfile ? "me-3 profile-data" : "me-3 dataset"}
          onClick={profileBtn}
        >
          Profile
        </span>
        {businessData.role?.name?.toLowerCase() === 'owner' || businessData.role?.name?.toLowerCase() === 'admin' ? <span
          onClick={businessProfile}
          className={chooseProfile ? "profile-data me-3" : "me-3 dataset"}
        >
          Business Profile
        </span> : ''}
      </div>
      {channelData && businessData && (
        <Formik
          initialValues={{
            profilename: businessData?.name,
            mobileNumber: formatMobileNumberWithSpace(businessData),
            email: businessData?.email,
            role: businessData?.role,
            profilePicture: businessData?.profilePictureLink,

            businessProfileName: businessProFile?.name,
            businessMobileNumber: businessProFile?.mobileNumber,
            mobileNumber2: businessProFile?.metaData?.mobileNumber2,
            ccEmail: businessProFile?.ccEmail,
            timeZone: businessProFile?.timeZone,
            businessCountry: businessProFile.country?.name,
            industry: businessProFile?.industry,
            currency: businessProFile?.currency,
            countryCode: phoneCode,
            noOfEmploy: businessProFile?.employees,
            website: businessProFile?.webSite,
            businessPicture: businessProFile?.profilePictureLink,
            maskingEnabled: businessProFile?.metaData?.maskingEnabled
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className=" mt-2">
              <Row>
                {chooseProfile === false && (
                  <Fragment>
                    <Col sm='' md='2' lg='2' xl='2'>
                      <div className="profileImageContainer">
                        {profileUrl ?
                          <img
                            src={
                              profileUrl.startsWith('http') ? `${profileUrl}?${Date.now()}` : profileUrl
                            }
                            className={"profile-img"} onClick={() => {
                              if (businessData.role?.name !== 'MEMBER') {
                                handleOpenFileDialog()
                              }
                            }
                            } />
                          :
                          <span className="profile-sample" onClick={() => {
                            if (businessData.role?.name !== 'MEMBER') {
                              handleOpenFileDialog()
                            }
                          }
                          }>{businessData?.name?.slice(0, 2)}</span>
                        }
                        <input
                          type="file"
                          ref={fileInputRef}
                          className="hideFileInput"
                          accept=".jpeg,.jpg,.png"
                          onChange={(event) => {
                            if (businessData.role?.name !== 'MEMBER') {
                              handleFileChange1(event.target.files)
                            }
                          }}
                        />
                        <Label className={`${profileUrl ? "cameraImg" : "cameraImg-sample"}`}>
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => {
                              if (businessData.role?.name !== 'MEMBER') {
                                handleOpenFileDialog()
                              }
                            }}
                          >
                            <circle cx="14" cy="14" r="14" fill="#007EC3" />
                            <path
                              d="M14 12C12.916 12 12 12.916 12 14C12 15.084 12.916 16 14 16C15.084 16 16 15.084 16 14C16 12.916 15.084 12 14 12Z"
                              fill="white"
                            />
                            <path
                              d="M19.334 9.33325H17.61L15.8053 7.52859C15.7435 7.46656 15.6701 7.41737 15.5892 7.38384C15.5083 7.35032 15.4215 7.33313 15.334 7.33325H12.6673C12.5798 7.33313 12.493 7.35032 12.4121 7.38384C12.3312 7.41737 12.2578 7.46656 12.196 7.52859L10.3913 9.33325H8.66732C7.93198 9.33325 7.33398 9.93125 7.33398 10.6666V17.9999C7.33398 18.7353 7.93198 19.3333 8.66732 19.3333H19.334C20.0693 19.3333 20.6673 18.7353 20.6673 17.9999V10.6666C20.6673 9.93125 20.0693 9.33325 19.334 9.33325ZM14.0007 17.3333C12.194 17.3333 10.6673 15.8066 10.6673 13.9999C10.6673 12.1933 12.194 10.6666 14.0007 10.6666C15.8073 10.6666 17.334 12.1933 17.334 13.9999C17.334 15.8066 15.8073 17.3333 14.0007 17.3333Z"
                              fill="white"
                            />
                          </svg>
                          {/* <input
                            type="file"
                            className="hideFileInput"
                            accept=".jpeg,.jpg,.png"
                            onChange={(event) =>
                              handleFileChange1(event.target.files)
                            }
                          /> */}
                        </Label>
                      </div>
                    </Col>
                    {showProfileSub === true &&
                      <Col sm='' md='10' lg='10' xl='10' className="ps-0 profileBtnCon">
                        <Button
                          type="button"
                          className="cancelButton me-2 text-dark"
                          onClick={GoHome}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="sendButton"
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      </Col>
                    }
                  </Fragment>
                )}{" "}
                {chooseProfile === true && (
                  <Fragment>
                    <Col sm='' md='2' lg='2' xl='2'>
                      <div className="profileImageContainer">
                        {businessPic ?
                          <img src={businessPic} className="profile-img" onClick={() => handleOpenFileDialog()} />
                          :
                          <span className="profile-sample" onClick={() => handleOpenFileDialog()}>{businessProFile?.name.slice(0, 2)}</span>
                        }
                        <input
                          type="file"
                          className="hideFileInput"
                          ref={fileInputRef}
                          accept=".jpeg,.jpg,.png"
                          onChange={(event) =>
                            handleFileChange2(event.target.files)
                          }
                        />
                        <Label className={`${businessPic ? 'cameraImg' : 'cameraImg-sample'}`}  >
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => handleOpenFileDialog()}
                          >
                            <circle cx="14" cy="14" r="14" fill="#007EC3" />
                            <path
                              d="M14 12C12.916 12 12 12.916 12 14C12 15.084 12.916 16 14 16C15.084 16 16 15.084 16 14C16 12.916 15.084 12 14 12Z"
                              fill="white"
                            />
                            <path
                              d="M19.334 9.33325H17.61L15.8053 7.52859C15.7435 7.46656 15.6701 7.41737 15.5892 7.38384C15.5083 7.35032 15.4215 7.33313 15.334 7.33325H12.6673C12.5798 7.33313 12.493 7.35032 12.4121 7.38384C12.3312 7.41737 12.2578 7.46656 12.196 7.52859L10.3913 9.33325H8.66732C7.93198 9.33325 7.33398 9.93125 7.33398 10.6666V17.9999C7.33398 18.7353 7.93198 19.3333 8.66732 19.3333H19.334C20.0693 19.3333 20.6673 18.7353 20.6673 17.9999V10.6666C20.6673 9.93125 20.0693 9.33325 19.334 9.33325ZM14.0007 17.3333C12.194 17.3333 10.6673 15.8066 10.6673 13.9999C10.6673 12.1933 12.194 10.6666 14.0007 10.6666C15.8073 10.6666 17.334 12.1933 17.334 13.9999C17.334 15.8066 15.8073 17.3333 14.0007 17.3333Z"
                              fill="white"
                            />
                          </svg>
                          {/* <input
                            type="file"
                            className="hideFileInput"
                            accept=".jpeg,.jpg,.png"
                            onChange={(event) =>
                              handleFileChange2(event.target.files)
                            }
                          /> */}
                        </Label>
                      </div>
                    </Col>
                    <Col sm='' md='7' lg='7' xl='7' className="ps-0 profileBtnCon">
                      {showBusinessSub === true &&
                        <>
                          <Button
                            type="button"
                            className="cancelButton me-2 text-dark"
                            onClick={GoHome}
                          >
                            Cancel
                          </Button>
                          {values.role.type === "MEMBER" ? null : (
                            <Button
                              type="submit"
                              className="sendButton"
                              disabled={isSubmitting}
                            >
                              Submit
                            </Button>
                          )}
                        </>
                      }
                    </Col>
                    <div className="col d-flex align-items-center">
                      <Label className="d-flex align-items-center">Mobile Number Masking&nbsp;
                        <TooltipReference
                          placement="bottom"
                          icon={faInfoCircle}
                          content={"Show only part of a mobile number to keep details private. This feature hides some digits to ensure your information remains secure for all members under this account"}
                          tooltipId="numberMask"
                        ></TooltipReference>
                      </Label>
                      &nbsp; &nbsp;
                      <label className={`switch-button switch-buttons mb-2 ${businessProFile?.metaData?.maskingEnabled ? 'checked' : ''} `} onClick={numberMaskForMember}>
                        <div className={`switch-slider switch-sliders`}></div>
                      </label>
                    </div>
                  </Fragment>
                )}
              </Row>
              {chooseProfile === false && (
                <Fragment>
                  <Label className="profile-info">
                    Profile Informations
                  </Label>
                  <Row className="profile-container">
                    <Col md='6' lg='6' xl='6' className="">
                      <div className="formInput">
                        <Label>Name <span className="redstar_required"></span></Label>
                        <Field
                          placeholder="Enter profile name"
                          type="text"
                          name="profilename"
                          maxLength={60}
                          className={`form-control ${businessData.role?.name === 'MEMBER' ? 'disabled-data' : ''}`}
                          disabled={businessData.role?.name === 'MEMBER'}
                          onChange={(event: any) => {
                            const { name, value } = event.target;

                            setFieldValue(name, value);
                          }}
                          onKeyDown={handleKeyDown}
                        />
                        <ErrorMessage
                          className="text-danger"
                          name="profilename"
                          component="div"
                        />
                      </div>
                      <div className="formInput">
                        <Label>Mobile Number</Label>
                        <Field
                          type="text"
                          placeholder="Enter mobile number"
                          name="mobileNumber"
                          id="mobileNumber"
                          onChange={(event: any) => {
                            const { name, value } = event.target;
                            setFieldValue(name, value);
                          }}
                          className="form-control disabled-data"
                          disabled={true}
                        />
                      </div>
                      <div className="formInput">
                        <Label>Email</Label>
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          onChange={(event: any) => {
                            const { name, value } = event.target;
                            setFieldValue(name, value);
                          }}
                          className="form-control disabled-data"
                          placeholder="Enter  email address"
                          disabled={true}
                        />
                      </div>
                      <div className="formInput">
                        <Label>Role</Label>
                        <Field
                          type="text"
                          name="role"
                          onChange={(event: any) => {
                            const { name, value } = event.target;
                            setFieldValue(name, value);
                          }}
                          value={values.role?.name}
                          disabled={true}
                          className="form-control disabled-data"
                        />
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              )}
              {chooseProfile === true && (
                <Fragment>
                  <Label className="profile-info">
                    {" "}
                    Business Profile{" "}
                  </Label>
                  <Row className="profile-container">
                    <Col sm='' md='6' lg='6' xl='6'>
                      <div className="formInput">
                        <Label>Business Name<span className="redstar_required"></span></Label>
                        <Field
                          placeholder="Enter business profile name"
                          type="text"
                          maxLength={60}
                          id="businessProfileName"
                          name="businessProfileName"
                          className={`form-control ${values.role.type === "MEMBER" ? 'disabled-data' : ''}`}
                          onChange={(event: any) => {
                            const { name, value } = event.target;
                            setFieldValue(name, value);
                          }}
                          disabled={
                            values.role.type === "MEMBER" ? true : false
                          }
                          onKeyDown={handleKeyDownBusiness}
                        />
                        <ErrorMessage
                          className="text-danger"
                          name="businessProfileName"
                          component="div"
                        />
                      </div>

                      <div className="formInput">
                        <Label>Mobile Number<span className="redstar_required"></span></Label>
                        <div className="input-group inputBorder">
                          <div className="input-group-prepend  ms-2  col-md-2 ps-1">
                            <FlagInput
                              countryCode={phoneCode?.includes('+') ? phoneCode : `+${phoneCode}`}
                              codeChange={(e: any) => codeChanges(e, values)}
                            />

                          </div>
                          <Field
                            type="text"
                            placeholder="Enter mobile number"
                            id="businessMobileNumber"
                            name="businessMobileNumber"
                            className={`form-control ${values.role.type === "MEMBER" ? 'disabled-data' : ''}`}
                            // onChange={(
                            //   e: React.ChangeEvent<HTMLInputElement>
                            // ) => {
                            //   const value = e.target.value.replace(
                            //     /[^\d]/g,
                            //     ""
                            //   );
                            //   setFieldValue("businessMobileNumber", value);
                            // }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              let value = e.target.value.replace(/[^0-9]/g, "");

                              if (value.length <= 13) {
                                setFieldValue("businessMobileNumber", value);
                              }
                            }}
                            disabled={
                              values.role.type === "MEMBER" ? true : false
                            }
                            onKeyDown={handleKeyDownBusiness}
                          />
                        </div>
                        <ErrorMessage
                          className="text-danger"
                          name="businessMobileNumber"
                          component="div"
                        />
                      </div>
                      <div className="formInput">
                        <Label>Alternate Mobile Number </Label>

                        <Field
                          type="text"
                          placeholder="Enter mobile number"
                          id="mobileNumber2"
                          name="mobileNumber2"
                          className={`form-control ${values.role.type === "MEMBER" ? 'disabled-data' : ''}`}
                          // onChange={(
                          //   e: React.ChangeEvent<HTMLInputElement>
                          // ) => {
                          //   const value = e.target.value.replace(
                          //     /[^\d]/g,
                          //     ""
                          //   );
                          //   setFieldValue("businessMobileNumber", value);
                          // }}
                          // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          //   let value = e.target.value.replace(/[^0-9]/g, "");

                          //   if (value.length <= 100) {
                          //     setFieldValue("mobileNumber2", value);
                          //   }
                          // }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            // Allow only digits and commas
                            let value = e.target.value.replace(/[^0-9,]/g, "");

                            // Remove any leading commas that might be left after the replacement
                            value = value.replace(/^,+/g, "");
                            // Ensure the total length of the string does not exceed 100 characters
                            if (value.length <= 150) {
                              setFieldValue("mobileNumber2", value);
                            }
                          }}

                          disabled={
                            values.role.type === "MEMBER" ? true : false
                          }
                          onKeyDown={handleKeyDownBusiness}
                        />
                        <ErrorMessage
                          className="text-danger"
                          name="mobileNumber2"
                          component="div"
                        />
                      </div>
                      <div className="formInput">
                        <Label>Country</Label>
                        <Select
                          name="businessCountry"
                          options={
                            flagData?.map((country: any) => ({
                              value: country.name,
                              label: country.name,
                              id: country.id,
                              country: country,
                            })) ?? []
                          }
                          value={{
                            value: values.businessCountry,
                            label: values.businessCountry,
                          }}
                          className={`businessCountry ${values.role.type === "MEMBER" ? 'disabled-data' : ''}`}
                          onChange={(selectedOption: any) => {
                            countryBusiness(selectedOption.country);
                            setFieldValue(
                              "businessCountry",
                              selectedOption.value
                            );
                          }}
                          isDisabled={
                            values.role.type === "MEMBER" ? true : false
                          }
                        />
                      </div>

                    </Col>
                    <Col sm='' md='6' lg='6' xl='6'>
                      <div className="formInput">
                        <Label>Industry</Label>
                        <Select
                          name="industry"
                          options={
                            industryList &&
                            (industryList?.map((industry: any) => ({
                              value: industry.value,
                              label: industry.label,
                            })) as [])
                          }
                          className={`industry ${values.role.type === "MEMBER" ? 'disabled-data' : ''}`}
                          value={{
                            value: values.industry,
                            label: values.industry,
                          }}
                          onChange={(selectedOption: any) => {
                            setFieldValue("industry", selectedOption.value);
                            showBisSubmit(true);
                          }}
                          isDisabled={
                            values.role.type === "MEMBER" ? true : false
                          }
                        />
                      </div>
                      <div className="formInput">
                        <Label>Currency</Label>
                        <Select
                          name="currency"
                          // options={
                          //   flagData &&
                          //   (uniqueCurrencies?.map((currency: any) => ({
                          //     value: currency,
                          //     label: currency,
                          //     id: currency,
                          //   })) as [])
                          // }
                          options={currencies}
                          className={`currency ${values.role.type === "MEMBER" ? 'disabled-data' : ''}`}
                          value={{
                            value: values.currency,
                            label: values.currency,
                          }}
                          onChange={(selectedOption: any) => {
                            setFieldValue(
                              "currency",
                              selectedOption.value,
                              selectedOption.id
                            );
                            showBisSubmit(true);
                          }}
                          isDisabled={
                            values.role.type === "MEMBER" ? true : false
                          }
                        />
                      </div>
                      <div className="formInput">
                        <Label>Website<span className="redstar_required"></span></Label>
                        <Field
                          type="text"
                          placeholder="Enter website"
                          id="website"
                          name="website"
                          className={`form-control  ${values.role.type === "MEMBER" ? 'disabled-data' : ''}`}
                          disabled={
                            values.role.type === "MEMBER" ? true : false
                          }
                          onKeyDown={handleKeyDownBusiness}
                        />
                        <ErrorMessage
                          className="text-danger"
                          name="website"
                          component="div"
                        />
                      </div>
                      <div className="formInput">
                        <Label>Number of Employees</Label>
                        <Select
                          name="noOfEmploy"
                          options={
                            employList &&
                            (employList?.map((employ: any) => ({
                              value: employ?.value,
                              label: employ?.label,
                            })) as [])
                          }
                          className={`noOfEmploy  ${values.role.type === "MEMBER" ? 'disabled-data' : ''}`}
                          value={{
                            value: values.noOfEmploy,
                            label: values.noOfEmploy,
                          }}
                          onChange={(selectedOption: any) => {
                            setFieldValue("noOfEmploy", selectedOption.value);
                            showBisSubmit(true);
                          }}
                          isDisabled={
                            values.role.type === "MEMBER" ? true : false
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='' md='6' lg='6' xl='6'>
                      <Label>CC mail</Label>
                      <Field
                        placeholder="Enter Your mail"
                        type="text"
                        id="ccEmail"
                        name="ccEmail"
                        className={`form-control ${values.role.type === "MEMBER" ? 'disabled-data' : ''}`}
                        onChange={(event: any) => {
                          const { name, value } = event.target;
                          setFieldValue(name, value);
                        }}

                        disabled={
                          values.role.type === "MEMBER" ? true : false
                        }
                        onKeyDown={handleKeyDownBusiness}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="ccEmail"
                        component="div"
                      />
                    </Col>
                    <Col>
                      <div className="formInput">
                        <Label>Time Zone</Label>
                        <Select
                          name="timeZone"
                          // options={
                          //   timeZoneList &&
                          //   (timeZoneList?.map((timeZone: any) => ({
                          //     value: timeZone,
                          //     label: timeZone,
                          //   })) as [])
                          // }
                          options={timezones}
                          value={{
                            value: values.timeZone,
                            label: values.timeZone,
                          }}
                          className={`timeZone ${values.role.type === "MEMBER" ? 'disabled-data' : ''}`}
                          // value={}
                          onChange={(selectedOption: any) => {
                            setFieldValue("timeZone", selectedOption.value);
                            showBisSubmit(true);
                          }}
                          isDisabled={
                            values.role.type === "MEMBER" ? true : false
                          }
                          formatOptionLabel={({ label }) => (
                            <div title={label}>{sliceText(label, 45)}</div>
                          )}
                        />
                        {/* )}
                          </Field> */}
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              )}
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
}

export default Profile;
